<!-- inizio contenuto editoriale -->
<div class="popup_contenuto_editoriale">
    
    <div class="contenuto_header" aria-hidden="true">
        <div class="contenuto_editoriale_chiudi" [routerLink]="'/home'">
            <svg viewBox="0 0 24 24">
                <path d="M13.4,12l4.7-4.7C18.5,6.9,18.4,6.3,18,6s-1-0.4-1.3-0.1L12,10.6L7.3,5.9C6.9,5.5,6.3,5.6,6,6s-0.4,1-0.1,1.3l4.7,4.7l-4.7,4.7C5.5,17.1,5.6,17.7,6,18s1,0.4,1.3,0.1l4.7-4.7l4.7,4.7c0.3,0.3,0.9,0.3,1.3-0.1s0.4-1,0.1-1.3L13.4,12z" />
            </svg>
        </div>
        
        <div *ngIf="item.poweredBy" class="contenuto_editoriale_powered">
            {{item.poweredBy}}
        </div>
        
    </div>
    
    <div class="contenuto_editoriale_contenitore">
        
        <div class="contenuto_editoriale_foto">
            <img [src]="item.imageUrl" alt="">
        </div>
        
        <div class="contenuto_editoriale_titolo">{{item.title}}</div>
        
        <div class="contenuto_editoriale_descrizione" [innerHTML]="item.description | safeHtml">

        </div>
        
        <div class="azioni_pop">
            <div [routerLink]="'/home'" class="tasto_principale next-slide" role="button" aria-label="Buono a sapersi">BUONO A SAPERSI</div>        
        </div>
    </div>
    
</div>
<!-- fine ccontenuto editoriale -->
