import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { QuizQuestion } from 'src/app/interfaces/quiz';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-gioco-quiz',
  templateUrl: './gioco-quiz.component.html',
  styleUrls: ['./gioco-quiz.component.css'],
})
export class GiocoQuizComponent implements OnInit, OnDestroy {
  playId: string = '0'; // ID giocata

  // tempo per rispondere
  time: number = 60;

  // tempo rimanente
  remainingTime: number = 60;

  // domanda
  question: QuizQuestion | null = null;

  // flag per domanda ancora attiva
  isActive: boolean = true;

  // flag per flip grafico
  flip: boolean = false;

  // numero risposta scelta
  rispostaScelta: number = 0;
  // numero risposta esatta
  rispostaEsatta: number = 0;
  // numero risposta errata
  rispostaSbagliata: number = 0;

  // è il quiz di fine livello
  isFineLivello: boolean = false;
  // dati mondo e livello correnti
  mondoCorrente: number = 1;
  livelloCorrente: number = 1;

  // numero di tentativo
  tentativo: number = 1;

  // massimo numero di tentativi
  readonly limit_tentativi: number = 3;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService
  ) {}

  ngOnInit(): void {
    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log('playId: ' + this.playId);

    this.route.queryParams.forEach((element) => {
      if (element['isFineLivello']) {
        this.isFineLivello = true;
      }
      if (element['livello']) {
        this.livelloCorrente = element['livello'];
      }
      if (element['mondo']) {
        this.mondoCorrente = element['mondo'];
      }
    });

    this.retrieveQuestion();
  }

  ngOnDestroy(): void {
    // disattivo domanda
    this.isActive = false;
  }

  /**
   * Recupero la domanda da mostrare
   * @returns
   */
  async retrieveQuestion() {
    if (this.appService.authUser == null) return;

    this.loading.present();

    try {
      this.question = await lastValueFrom(
        this.appService.quizzesQuestion(this.appService.authUser.info.worldId)
      );

      // inizio conto rovescia
      this.startTimer();
    } catch (error) {
      this.alert.presentAlertError();
    } finally {
      this.loading.dismiss();
    }
  }

  async startTimer() {
    // sleep
    await this.delay(1000);

    // scalo tempo rimanente
    this.remainingTime--;

    // se arrivo a zero
    if (this.remainingTime == 0) {
      // mostro popup tempo scaduto
      const response = await this.alert.presentAlertOk('Tempo Scaduto');

      if (this.playId != null && this.playId != '0') {
        await this.setRewardPlayed(false);
      }

      // faccio flip
      this.flip = true;

      window.scrollTo(0, 0); // reset scroll
    } else {
      if (this.isActive) {
        // funzione ricorsiva
        this.startTimer();
      }
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // funzione per rispondere
  async clickAnswer(answer: number) {
    if (this.question == null) return;

    // se non attiva
    if (!this.isActive) {
      return;
    }

    // disattivo domanda
    this.isActive = false;

    // illumino risposta scelta
    this.rispostaScelta = answer;

    // sleep
    await this.delay(1000);

    // se risposta corretta
    if (answer == this.question.success) {
      // illumino risposta corretta
      this.rispostaEsatta = answer;
    } else {
      // resetto risposta scelta
      this.rispostaScelta = 0;

      // illumino risposta sbagliata
      this.rispostaSbagliata = answer;
    }

    // se non ho indovinato e ci sono ancora tentativi
    if (this.rispostaEsatta == 0 && this.tentativo < this.limit_tentativi) {

      this.tentativo++;

      // sleep
      await this.delay(1000);

      // riattivo la domanda
      this.isActive = true;

      // resetto risposta sbagliata
      this.rispostaSbagliata = 0;

      // riattiva il timer
      this.startTimer();

      return;
    }

    // memorizzo la risposta lato server
    await this.setAnswer(answer);
  }

  /**
   * Memorizza la risposta lato server prima di visualizzare il risultato
   * @param answer
   * @returns
   */
  async setAnswer(answer: number) {
    if (this.question == null) return;

    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.quizzesAddAnswer(this.question.questionId, answer)
      );

      this.loading.dismiss();

      this.onAftersetAnswer();
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setAnswer(answer);
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  /**
   * Funzione eseguita dopo il salvataggio della risposta finale lato server
   * @returns
   */
  private async onAftersetAnswer() {
    if (this.question == null) return;

    // controllo se è la risposta corretta
    if (this.rispostaEsatta != 0) {
      // risposta corretta
      await this.setRewardPlayed(true);
    } else {
      // illumino risposta esatta
      this.rispostaEsatta = this.question.success;

      // risposta errata
      await this.setRewardPlayed(false);
    }
  }

  /**
   * Setta la fine delle giocata e restituisce
   * @param success
   */
  async setRewardPlayed(success: boolean) {
    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.gamePlayed(this.playId, success)
      );

      this.loading.dismiss();

      // sleep
      await this.delay(1000);

      // flip
      this.flip = true;

      window.scrollTo(0, 0); // reset scroll
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setRewardPlayed(success);
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  concludi() {
    if (this.isFineLivello) {
      this.router.navigate(['/video-fine-livello/'], {
        queryParams: {
          livello: this.livelloCorrente,
          mondo: this.mondoCorrente,
        },
      });
    } else {
      this.router.navigate(['/home']);
    }
  }
}
