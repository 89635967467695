<ng-container *ngIf="showPopup">

	<!-- inizio pop esci dal gioco -->
	<div
		 class="pop_gioco"
		 id="popup_esci_gioco"
		 [ngClass]="{ pop_gioco_visibile: showPopup }"
		 role="alertdialog"
		 tabindex="-1"
		 aria-labelledby="popup_esci_gioco_titolo"
		 [attr.aria-hidden]="!showPopup ? 'true' : 'false'"
		 [attr.aria-modal]="showPopup ? 'true' : 'false'"
	>

	<div class="pop_gioco_container">
		
		<div id="popup_esci_gioco_titolo" class="pop_gioco_container_titolo">{{ message }}</div>
		
		<div *ngIf="!isPopupConfirm" class="pop_gioco_container_azioni singola">
      
			<div id="titolo_popup" class="tasto_principale" (click)="onClickAnnul()" role="link" aria-label="Conferma">OK</div>
    	</div>

    <div *ngIf="isPopupConfirm" class="pop_gioco_container_azioni">
      <div
        class="tasto_principale"
        (click)="onClickAnnul()"
        role="button"
        aria-label="Annulla"
        aria-controls="header_bottone_esci_gioco"
      >
        {{ text_button_annul }}
      </div>

      <div
        class="tasto_principale"
        (click)="onClickConfirm()"
        role="button"
        aria-label="Conferma"
      >
        {{ text_button_confirm }}
      </div>
    </div>
  </div>

  <div class="pop_gioco_bg"></div>
		
		
		
</div>
<!-- fine pop esci dal gioco -->
		
</ng-container>
