import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  // inizializzo emettitore evento
  changeStatusMenu: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  /**
   * Save data key/value
   * @param key 
   * @param value 
   */
  public saveData(key: string, value: string) {

    localStorage.setItem(key, value);
  }

  /**
   * Get data key
   * @param key 
   * @returns 
   */
  public getData(key: string) {

    return localStorage.getItem(key)
  }

  /**
   * Remove data key
   * @param key 
   */
  public removeData(key: string) {

    localStorage.removeItem(key);
  }

  /**
   * Clear all data
   */
  public clearData() {

    localStorage.clear();
  }
}
