 <!-- inizio header -->
<header *ngIf="!showPopupTutorial && !showPopupStart">

    <div class="base_brand" *ngIf="playId != '0'">
        <div class="contenitore_brand">
            <img [src]="brandLogoUrl" aria-hidden="true" alt="">
        </div>
        <svg viewBox="0 0 308 236.1">
            <path d="M0,131.5c0,3.8,0.4,7.6,1.4,11.3c5.1,19.8,26,79.4,95.2,80.8c1.6,0,3.2,0,4.8-0.2c18.8-1.6,80.2-3.9,160.9-6c25.4-0.6,45.7-21.4,45.7-46.8V46.8C308,21,287,0,261.1,0H0V131.5z"/>
        </svg>
    </div>

    <svg viewBox="0 0 1107.3 236.1" class="base_header">
        <path d="M555.4,0c0,0-3.4,0-3.5,0C545,0,0,0,0,0v136.5c0,0,13.8,87.2,99.1,87.2c31.2-3.1,254.6-10.1,454.5-10.2c199.9,0,423.3,7.1,454.5,10.2c85.3,0,99.1-87.2,99.1-87.2V0C1107.3,0,562.2,0,555.4,0z"/>
    </svg>

    <div class="header_menu" [ngClass]="{'header_menu_demo': playId == '0'}">

        <div class="contatori_gioco">
            <div class="contatori_pallini">
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 1}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 2}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 3}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 4}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 5}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 6}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 7}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 8}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 9}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 10}"></div>
            </div>
            <div class="contatori_gioco_risultato">Rifiuti raccolti: <strong>{{score}} / 10</strong></div>
        </div>

        <div class="tasto_apri_menu" role="button" aria-label="Chiudi il gioco" (click)="clickClose()">
            <svg viewBox="0 0 24 24" class="close_menu">
                <path class="st0" d="M13.4,12l4.7-4.7C18.5,6.9,18.4,6.3,18,6s-1-0.4-1.3-0.1L12,10.6L7.3,5.9C6.9,5.5,6.3,5.6,6,6s-0.4,1-0.1,1.3l4.7,4.7l-4.7,4.7C5.5,17.1,5.6,17.7,6,18s1,0.4,1.3,0.1l4.7-4.7l4.7,4.7c0.3,0.3,0.9,0.3,1.3-0.1s0.4-1,0.1-1.3L13.4,12z"/>
            </svg>
        </div>

    </div>

</header>
<!-- fine header -->


<div id="contenitore_gioco" class="contenitore_gioco" (swipe)="onSwipe($event)">

    <div class="tap">
        <div class="tap_sx" (click)="onTap('left')">
        </div>
        <div class="tap_dx" (click)="onTap('right')">
        </div>
    </div>
    
    <!-- inizio tutorial -->
    <div class="tutorial_right" style="display: none;">
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_1">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_2">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_3">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
    </div>
    
    <div class="tutorial_left" style="display: none;">
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_1">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_2">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
        <svg viewBox="0 0 177.4 196.7" class="indicazione indicazione_dx_3">
            <path class="freccia" d="M14.4,192.4c-2.6,0-5.1-1-7-2.9l-0.2-0.2c-3.8-3.8-3.9-10-0.2-13.9l62.6-77.1L7,21.3c-3.7-3.9-3.6-10,0.2-13.9l0.3-0.3c1.9-1.9,4.4-2.9,7-2.9h79.6c2.6,0,5.1,1,7,2.9l0.1,0.1l69.2,84.2c3.6,3.8,3.6,9.9,0,13.7l-69.1,84.2l-0.1,0.1c-1.9,1.9-4.4,2.9-7,2.9H14.4z"/>
            <path class="bordo_freccia" d="M94.1,8.5c1.5,0,2.9,0.6,4,1.7l69.2,84.2c2.2,2.2,2.2,5.8,0,8l-69.1,84.2c-1.1,1.1-2.5,1.7-4,1.7H14.4c-1.5,0-2.9-0.6-4-1.7l-0.2-0.2c-2.2-2.2-2.2-5.8,0-8L75,98.3L10.2,18.5c-2.2-2.2-2.2-5.8,0-8l0.3-0.3c1.1-1.1,2.5-1.7,4-1.7H94.1M94.1,0H14.4c-3.8,0-7.3,1.5-10,4.2L4.1,4.4c-5.4,5.4-5.5,14.1-0.4,19.7l60.3,74.2L3.8,172.6c-5.1,5.6-5,14.3,0.4,19.7l0.2,0.2c2.7,2.7,6.2,4.2,10,4.2h79.7c3.8,0,7.3-1.5,10-4.2l0.3-0.3l0.3-0.3l68.9-83.9c2.5-2.6,3.8-6,3.8-9.7c0-3.6-1.3-7-3.8-9.7l-69-83.9l-0.3-0.3l-0.3-0.3C101.4,1.5,97.8,0,94.1,0L94.1,0z"/>
        </svg>
        
    </div>
    <!-- fine tutorial -->

<!--    <p style="margin-top: 20vh;" *ngIf="!showError">GRANDE</p>-->
    
    <div class="risultato_positivo" [ngClass]="{'mostra_risultato_positivo': showSuccess}">
        <img *ngIf="successMessage == 1" src="/assets/img/gioco_rifiuti/risultato_super.svg" aria-hidden="true" alt="">
        <img *ngIf="successMessage == 2" src="/assets/img/gioco_rifiuti/risultato_ottimo.svg" aria-hidden="true" alt="">
        <img *ngIf="successMessage == 3" src="/assets/img/gioco_rifiuti/risultato_grande.svg" aria-hidden="true" alt="">
        <img *ngIf="successMessage == 4" src="/assets/img/gioco_rifiuti/risultato_perfetto.svg" aria-hidden="true" alt="">
    </div>
    

    <div class="colonna_1" [ngClass]="{'squoti': showError}">
        <div id="bidone_1" class="bidone" [ngClass]="{'bidone_aperto': currentRifiutoPosition == 1}">
            <img class="foto_bidone_aperto" src="/assets/img/gioco_rifiuti/bidone_carta_aperto.png" aria-hidden="true" alt="">
            <img class="foto_bidone_chiuso" src="/assets/img/gioco_rifiuti/bidone_carta.png" aria-hidden="true" alt="">
        </div>
    </div>

    <div class="colonna_2" [ngClass]="{'squoti': showError}">
        <div id="bidone_2" class="bidone" [ngClass]="{'bidone_aperto': currentRifiutoPosition == 2}">
            <img class="foto_bidone_aperto" src="/assets/img/gioco_rifiuti/bidone_plastica_aperto.png" aria-hidden="true" alt="">
            <img class="foto_bidone_chiuso" src="/assets/img/gioco_rifiuti/bidone_plastica.png" aria-hidden="true" alt="">
        </div>
    </div>

    <div class="colonna_3" [ngClass]="{'squoti': showError}">
        <div id="bidone_3" class="bidone" [ngClass]="{'bidone_aperto': currentRifiutoPosition == 3}">
            <img class="foto_bidone_aperto" src="/assets/img/gioco_rifiuti/bidone_umido_aperto.png" aria-hidden="true" alt="">
            <img class="foto_bidone_chiuso" src="/assets/img/gioco_rifiuti/bidone_umido.png" aria-hidden="true" alt="">
        </div>
    </div>
        

    
</div>

<div class="prato">
    <img src="/assets/img/gioco_rifiuti/prato.svg" aria-hidden="true" alt="">
</div>



<!-- inizio pop gioco tutorial -->
<div class="gioco_tutorial" *ngIf="showPopupTutorial">

    <div class="pop_default">

        <div class="contenuto_principare_pop">
            
            <!-- inizio pillola -->
            <div class="popup_gioco_pillola" *ngIf="minipillola != null">
                
                <div class="sponsorizzazione">
                    Sponsorizzato da:
                </div>

                <div class="popup_gioco_pillola_brand">
                    <img [src]="brandLogoUrl" aria-hidden="true" alt="">
                </div>

                <ng-container *ngIf="minipillola.testo != ''">
                    <div class="popup_gioco_pillola_titolo">Pillole di sostenibilità</div>
                
                    <div class="popup_gioco_pillola_descrizione" [innerHTML]="minipillola.testo | safeHtml"></div>
                </ng-container>
                
            </div>
            <!-- fine pillola -->

            <div class="pop_default_titolo">Come si gioca</div>
            
            <div class="pop_default_descrizione">Ricicla almeno 10 rifiuti per superare la prova.</div>
            
            <div class="pop_default_foto_descrizione" style="margin-top: 10px;">
                <img src="/assets/img/gioco_rifiuti/tutorial_rifiuti_1.jpg" width="160px" alt="esempio rifuti da riciclare">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Trascina il dito verso destra o verso sinistra per spostare i rifiuti e inserirli nel bidone corretto.</div>
            
            <div class="pop_default_foto_descrizione" style="margin-top: 10px;">
                <img src="/assets/img/gioco_rifiuti/tutorial_rifiuti_2.jpg" width="200px" alt="trascina il dito verso destra o sinistra">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Associa correttamente il rifiuto al suo bidone per riciclarlo nel modo corretto.</div>
            
            <div class="pop_default_foto_descrizione" style="margin-top: 15px; margin-bottom: 15px;">
                <img src="/assets/img/gioco_rifiuti/tutorial_rifiuti_3.jpg" width="260px" alt="scegli il bidone corretto">
            </div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;" *ngIf="playId != '0'">
            <div class="tasto_principale next-slide" role="button" aria-label="Inizia il gioco" (click)="startGame()">GIOCA</div>
        </div>
        
        <div class="azioni_pop azioni_pop_tutorial" style="margin-top: 0;" *ngIf="playId == '0'">
            <div [routerLink]="'/elenco-minigiochi'" role="button" aria-label="Annulla" class="tasto_principale tasto_principale_outline">ANNULLA</div>
            <div (click)="startGame()" role="button" aria-label="Inizia il gioco" class="tasto_principale">GIOCA</div>
        </div>

    </div>
    
</div>
<!-- fine pop gioco tutorial -->



<!-- inizio pop gioco start -->
<div class="gioco_tutorial" *ngIf="showPopupStart" style="display: flex; justify-content: center; align-items: flex-start;">

    <div class="pop_default">
        
        <!-- inizio pillola -->
        <div class="popup_gioco_pillola" *ngIf="minipillola != null">
            
            <div class="sponsorizzazione">
                Sponsorizzato da:
            </div>

            <div class="popup_gioco_pillola_brand">
                <img [src]="brandLogoUrl" aria-hidden="true" alt="">
            </div>

            <ng-container *ngIf="minipillola.testo != ''">
                <div class="popup_gioco_pillola_titolo">Pillole di sostenibilità</div>

                <div class="popup_gioco_pillola_descrizione" [innerHTML]="minipillola.testo | safeHtml"></div>
            </ng-container>
            
        </div>
        <!-- fine pillola -->
        
        <div class="pop_default_titolo" *ngIf="playId == '0'">Allenati per la sfida</div>
        
        <div class="pop_default_titolo" *ngIf="playId != '0'">Il gioco sta per iniziare</div>
        
        <div class="contenuto_principare_pop">
            <img src="/assets/img/anteprima_minigioco_differenziata.jpg" aria-hidden="true" alt="">
        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <div class="tasto_principale next-slide" role="button" aria-label="Inizia il gioco" (click)="startGame()">INIZIA LA PARTITA</div>
        </div>

    </div>
    
</div>
<!-- fine pop gioco start -->



<!-- inizio pop gioco finito -->
<div class="pop_gioco_finito" [ngClass]="{'pop_gioco_finito_visibile': showPopupEnd}">
    
    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Hai superato la prova</div>

            <div *ngIf="playId != '0'" class="pop_default_descrizione">
                Hai ottenuto una Margherita Verde e tiro di dado extra
            </div>

            <div class="pop_fine_gioco_immagine" style="margin-top: 20px;">
                <img [src]="appService.authUser?.avatar?.greatings" aria-label="Fantastico, gioco completato" aria-hidden="true" alt="" >
            </div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <a class="tasto_principale" [routerLink]="'/home'" role="link" aria-label="Continua">CONTINUA</a>
        </div>

    </div>
    
    <div class="pop_gioco_finito_bg"></div>
    
</div>
<!-- fine pop gioco finito -->