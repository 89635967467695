import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { Avatar } from 'src/app/interfaces/game';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-scegli-avatar',
  templateUrl: './scegli-avatar.component.html',
  styleUrls: ['./scegli-avatar.component.css']
})
export class ScegliAvatarComponent implements OnInit {

  // configurazione swiper
  config: SwiperOptions | null;
  // slides
  slides: any = [];

  // avatar scelto
  avatar: Avatar | null = null;

  constructor(
    public router: Router,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService
  ) { 

    // slide iniziale
    var initialSlide = 0;

    // configurazione swiper
    this.config = {
      autoHeight: true,
      allowTouchMove: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      loop: false,
      initialSlide: initialSlide
    };
  }

  ngOnInit(): void {

    this.retrieveAvatars();
  }

  /**
   * Recupero la lista
   * @returns 
   */
  async retrieveAvatars()
  {

    if (!this.appService.authUser) return;

    this.slides = [];

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.avatarsGet(this.appService.authUser.info.worldId));

      for(let i in data) {

        this.slides.push({
          "source": data[i].video,
          "name": data[i].code,
          "aria_label": "Scegli " + data[i].name + "",
          "tasto": data[i].name
        });
      }

      
    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }

  }

  /**
   * Imposta il nuovo avatar
   * @param code 
   * @returns 
   */
  async onSelectAvatar(code: string) {

    if (!this.appService.authUser) return;

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.avatarsSet(this.appService.authUser.info.worldId, code));

      // vado al gioco
      this.router.navigate(['/home']);

    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

}
