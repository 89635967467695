import { TypeMiniGame } from "./game";

export interface Minipillola {
    giocoId: string;
    logo: string;
    testo: string;
}

const MINIPILLOLE_2: Minipillola[] = [
    {
        "giocoId":"2",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Lo sapevi: con Ecogreen, la linea di Acqua Minerale “CO₂ Impatto Zero”, San Benedetto ha ridotto di 13.063t la CO2eq emessa dal 2013 al 2021."
    },
    {
        "giocoId":"4",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Con Acqua Minerale Ecogreen 0,5L negli ultimi 8 anni San Benedetto ha risparmiato l’equivalente della CO₂ assorbita in un anno da 113.700 alberi."
    },
    {
        "giocoId":"3",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Le bottiglie in PET sono sicure, affidabili e riciclabili al 100%. Non abbandonarle, possono dare vita a tantissimi altri oggetti!"
    },
    {
        "giocoId":"4",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Lo sapevi: nella Foresta San Benedetto, San Benedetto ha piantato nel 2017 6.000 alberi per un polmone verde di 8 ettari."
    },
    {
        "giocoId":"3",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Il tappo Twist&Drink di San Benedetto attaccato alla bottiglia è un grande passo verso un futuro con meno plastica dispersa nell’ambiente!"
    },
    {
        "giocoId":"2",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Il “Progetto Network” di San Benedetto conta 7 fonti lungo tutta la penisola, per avvicinarsi ai consumatori e ridurre i trasporti."
    },
    {
        "giocoId":"4",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"San Benedetto ha firmato un accordo con il Ministero della Transizione Ecologica per calcolare l’impronta ambientale del ciclo di vita dei prodotti."
    },
    {
        "giocoId":"2",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/sanbenedetto_ecogreen.svg",
        "testo":"Dal 2013 al 2021 con Ecogreen 2L, San Benedetto ha avuto un risparmio pari a 114.930 lampade a LED da 11,5 W accese h24 per un anno."
    },
    {
        "giocoId":"2",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/riomare.svg",
        "testo":"Rio Mare è attenta alle sue emissioni e dal 2011 lo stabilimento di Cermenate (CO) acquista il 100% di energia elettrica da fonti rinnovabili."
    },
    {
        "giocoId":"4",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/riomare.svg",
        "testo":"Le confezioni di Rio Mare sono composte da cartone, banda stagnata o alluminio. Sapevi che l’alluminio è riciclabile al 100% e all’infinito?"
    },
    {
        "giocoId":"3",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/riomare.svg",
        "testo":"Quando sei in spiaggia, non lasciare in giro i rifiuti, ricordati di portarli con te a fine giornata."
    },
    {
        "giocoId":"2",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/conad_essentiae.svg",
        "testo":"I Solari Conad Essentiae contengono estratti di origine naturale e filtri solari che proteggono la tua pelle rispettando l'ecosistema marino."
    },
    {
        "giocoId":"4",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/conad_sapori.svg",
        "testo":"Le vaschette e i coperchi dei Gelati Sapori&Idee Conad sono riciclabili nella carta."
    },
    {
        "giocoId":"3",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo2/brand/conad_piacersi.svg",
        "testo":"I nostri affettati PiacerSi ti aiutano a ridurre il consumo di grassi e della plastica perché sono confezionati con il 40% di r-PET."
    }
];

const MINIPILLOLE_3: Minipillola[] = [
    {
        "giocoId": ""+TypeMiniGame.SPEGNI_LO_SPRECO+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":"Lo sapevi che su App Piacere Lavazza puoi formarti su temi di sostenibilità grazie alle missioni mensili in collaborazione con AWorld?"
    },
    {
        "giocoId": ""+TypeMiniGame.SPEGNI_LO_SPRECO+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":"Lo sapevi che la Fondazione Lavazza ha al suo attivo 33 progetti in 20 paesi, di  cui beneficiano oltre 180.000 coltivatori e coltivatrici di caffè?"
    },
    {
        "giocoId": ""+TypeMiniGame.SPEGNI_LO_SPRECO+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":"Sapevi che ¡Tierra! è la gamma di prodotti Lavazza che si ispira ai progetti della Fondazione?"
    },
    {
        "giocoId": ""+TypeMiniGame.RACCOLTA_DIFFERENZIATA+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":"Lo sapevi che nel 2022 il <strong>97%</strong> degli scarti vegetali dalla produzione del caffè in Italia è stato trasformato in fertilizzante organico?"
    },
    {
        "giocoId": ""+TypeMiniGame.RACCOLTA_DIFFERENZIATA+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":"Lo sapevi che nel 2022 il <strong>98%</strong> del nostro caffè è stato prodotto con energia elettrica rinnovabile?"
    },
    {
        "giocoId": ""+TypeMiniGame.SQUALO_IN_MARE+"",
        "logo":"https://conad.s3.eu-south-1.amazonaws.com/assets/img/mondo3/brand/lavazza.svg",
        "testo":""
    }
];

/**
 * Restituisce un elemento randomico dalla lista
 * @param worldId 
 * @param gameId 
 * @returns 
 */
export function getRandomMinipillola(worldId: number, gameId: number): Minipillola {

    let items: Minipillola[] = [];

    switch (worldId) {
        case 2:

            items = MINIPILLOLE_2.filter((el) => el.giocoId == ""+gameId+"");
            
            break;

        case 3:

            items = MINIPILLOLE_3.filter((el) => el.giocoId == ""+gameId+"");
            
            break;
    
        default:
            break;
    }

    return items[Math.floor(Math.random() * items.length)];
}