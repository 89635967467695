import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { JwtAccessToken } from "../interfaces/user";
import { AppService } from "../services/app.service";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public appService: AppService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // aggiunge custom header
        request = this.addCustomHeaders(request);

        return next.handle(request).pipe(catchError(error => {

            console.error(`Backend returned code ${error.status}`);

            if (error instanceof HttpErrorResponse) {

                if (error.status === 403) {

                    // richiede nuovo token di autenticazione
                    return this.handleRefreshTokenError(request, next);
                
                } else {

                    return throwError(() => error);
                }

            } else {

                return throwError(() => error);
            }
        }));
    }

    /**
     * Configurazione header per la chiamata
     * @param request 
     * @returns 
     */
    private addCustomHeaders(request: HttpRequest<any>) {

        let headers = {
            'Content-Type': 'application/json'
        };

        if (this.appService.authUser) {

            // aggiunge token di autenticazione
            request = this.addJwtTokenHeader(request, this.appService.authUser.auth.jwtToken);
        }
        
        return request.clone({
            setHeaders: headers
        });
    }

    /**
     * Aggiorno la chiamata con il nuovo token di accesso
     * @param request 
     * @param token 
     * @returns 
     */
    private addJwtTokenHeader(request: HttpRequest<any>, token: string) {
    
        return request.clone({
            setHeaders: {
                'authorization': `${token}`
            }
        });
    }

    /**
     * Gestione risposta non autorizzata e richiesta nuovo token
     * @param request 
     * @param next 
     * @returns 
     */
    private handleRefreshTokenError(request: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshing) {
        
            this.isRefreshing = true;

            this.refreshTokenSubject.next(null);
      
            return this.appService.userAuthToken().pipe(
                switchMap((data: JwtAccessToken) => {

                    this.isRefreshing = false;
                    
                    this.refreshTokenSubject.next(data.jwtToken);

                    // rieseguo la chiamata iniziale
                    return next.handle(this.addJwtTokenHeader(request, data.jwtToken));
                }));
      
        } else {

            return this.refreshTokenSubject.pipe(
                filter(jwtToken => jwtToken != null),
                take(1),
                switchMap(jwtToken => {

                    // rieseguo la chiamata iniziale
                    return next.handle(this.addJwtTokenHeader(request, jwtToken));
                }));
        }
    }
}
