import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { UserPlayed } from 'src/app/interfaces/game';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-elenco-minigiochi',
  templateUrl: './elenco-minigiochi.component.html',
  styleUrls: ['./elenco-minigiochi.component.css']
})
export class ElencoMinigiochiComponent implements OnInit {

  // QUIZ
  items_1: UserPlayed[] = [];

  // SPEGNI LO SPRECO
  items_2: UserPlayed[] = [];

  // SQUALO IN MARE
  items_3: UserPlayed[] = [];

  // RACCOLTA DIFFERENZIATA
  items_4: UserPlayed[] = [];

  // MEMORY
  items_5: UserPlayed[] = [];

  // BRICK BREAKER
  items_6: UserPlayed[] = [];

  // CLICCA PRODOTTI
  items_7: UserPlayed[] = [];

  constructor(
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    this.retrieveUserPlayed();
  }

  async retrieveUserPlayed() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.gameList("playing"));

      this.items_1 = data.filter((el) => el.gameId == 1);
      this.items_2 = data.filter((el) => el.gameId == 2);
      this.items_3 = data.filter((el) => el.gameId == 3);
      this.items_4 = data.filter((el) => el.gameId == 4);
      this.items_5 = data.filter((el) => el.gameId == 5);
      this.items_6 = data.filter((el) => el.gameId == 6);
      this.items_7 = data.filter((el) => el.gameId == 7);
      
    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

}
