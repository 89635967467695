export const KEY_TUTORIAL_RACCOLTA = 'tutorial_raccolta';

export interface Rifiuto {

    id: number;
    type: number;
    image: string;
    bidone: number;
    position: number;
}

export interface RifiutoType {

    id: number;
    image: string;
    bidone: number;
}

export const RifiutoTypes: Array<RifiutoType> = [
    {
        id: 1,
        image: '/assets/img/gioco_rifiuti/carta_giornale.svg',
        bidone: 1,
    },
    {
        id: 2,
        image: '/assets/img/gioco_rifiuti/carta_scatola.svg',
        bidone: 1,
    },
    {
        id: 3,
        image: '/assets/img/gioco_rifiuti/carta_stropicciata.svg',
        bidone: 1,
    },
    {
        id: 4,
        image: '/assets/img/gioco_rifiuti/carta_tetrapack.svg',
        bidone: 1,
    },
    {
        id: 5,
        image: '/assets/img/gioco_rifiuti/plastica_bottiglia.svg',
        bidone: 2,
    },
    {
        id: 6,
        image: '/assets/img/gioco_rifiuti/plastica_flacone.svg',
        bidone: 2,
    },
    {
        id: 7,
        image: '/assets/img/gioco_rifiuti/plastica_latta.svg',
        bidone: 2,
    },
    {
        id: 8,
        image: '/assets/img/gioco_rifiuti/plastica_sporta.svg',
        bidone: 2,
    },
    {
        id: 9,
        image: '/assets/img/gioco_rifiuti/umido_banana.svg',
        bidone: 3,
    },
    {
        id: 10,
        image: '/assets/img/gioco_rifiuti/umido_mela.svg',
        bidone: 3,
    },
    {
        id: 11,
        image: '/assets/img/gioco_rifiuti/umido_pizza.svg',
        bidone: 3,
    },
    {
        id: 12,
        image: '/assets/img/gioco_rifiuti/umido_pollo.svg',
        bidone: 3,
    },
]