import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessibilitaComponent } from './pages/accessibilita/accessibilita.component';
import { AiutoFaqComponent } from './pages/aiuto-faq/aiuto-faq.component';
import { ElencoMinigiochiComponent } from './pages/elenco-minigiochi/elenco-minigiochi.component';
import { FineGiocoComponent } from './pages/fine-gioco/fine-gioco.component';
import { GiocoQuizComponent } from './pages/mini-giochi/gioco-quiz/gioco-quiz.component';
import { GiocoRaccoltaDifferenziataComponent } from './pages/mini-giochi/gioco-raccolta-differenziata/gioco-raccolta-differenziata.component';
import { GiocoSpegniLoSprecoComponent } from './pages/mini-giochi/gioco-spegni-lo-spreco/gioco-spegni-lo-spreco.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { InitPageComponent } from './pages/init-page/init-page.component';
import { IntroNuovoMondoComponent } from './pages/intro-nuovo-mondo/intro-nuovo-mondo.component';
import { LeTueGiocateComponent } from './pages/le-tue-giocate/le-tue-giocate.component';
import { GiocoSqualoPageComponent } from './pages/mini-giochi/gioco-squalo-page/gioco-squalo-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { OnBoardingComponent } from './pages/on-boarding/on-boarding.component';
import { ScegliAvatarComponent } from './pages/scegli-avatar/scegli-avatar.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { VideoFineLivelloComponent } from './pages/video-fine-livello/video-fine-livello.component';
import { ContenutoEditorialeComponent } from './pages/contenuto-editoriale/contenuto-editoriale.component';
import { ElencoEditorialiComponent } from './pages/elenco-editoriali/elenco-editoriali.component';
import { TutorialExternalComponent } from './pages/tutorial-external/tutorial-external.component';
import { PuntiSostenibilitaComponent } from './pages/punti-sostenibilita/punti-sostenibilita.component';
import { ExitPageComponent } from './pages/exit-page/exit-page.component';
import { CouponPreviewComponent } from './pages/coupon-preview/coupon-preview.component';
import { GiocoMemoryComponent } from './pages/mini-giochi/gioco-memory/gioco-memory.component';
import { GiocoCliccaProdottiComponent } from './pages/mini-giochi/gioco-clicca-prodotti/gioco-clicca-prodotti.component';
import { GiocoBrickBreakerComponent } from './pages/mini-giochi/gioco-brick-breaker/gioco-brick-breaker.component';

const appRoutes: Routes = [
  { path: 'init/:token', component: InitPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'accessibilita', component: AccessibilitaComponent },
  { path: 'aiuto-faq', component: AiutoFaqComponent },
  { path: 'elenco-minigiochi', component: ElencoMinigiochiComponent },
  { path: 'fine-gioco', component: FineGiocoComponent },
  { path: 'gioco-quiz/:playId', component: GiocoQuizComponent },
  { path: 'gioco-raccolta-differenziata/:playId', component: GiocoRaccoltaDifferenziataComponent },
  { path: 'gioco-spegni-lo-spreco/:playId', component: GiocoSpegniLoSprecoComponent },
  { path: 'gioco-squalo/:playId', component: GiocoSqualoPageComponent },
  { path: 'gioco-memory/:playId', component: GiocoMemoryComponent },
  { path: 'gioco-clicca-prodotti/:playId', component: GiocoCliccaProdottiComponent },
  { path: 'gioco-brick-breaker/:playId', component: GiocoBrickBreakerComponent },
  // { path: 'intro-nuovo-mondo', component: IntroNuovoMondoComponent }, -- DEPRECATED
  { path: 'le-tue-giocate', component: LeTueGiocateComponent },
  { path: 'on-boarding', component: OnBoardingComponent },
  { path: 'scegli-avatar', component: ScegliAvatarComponent },
  { path: 'tutorial', component: TutorialComponent },
  { path: 'tutorial-external', component: TutorialExternalComponent },
  { path: 'video-fine-livello', component: VideoFineLivelloComponent },
  { path: 'contenuto-editoriale', component: ContenutoEditorialeComponent },
  { path: 'elenco-editoriali', component: ElencoEditorialiComponent },
  { path: 'punti-sostenibilita', component: PuntiSostenibilitaComponent },
  { path: 'coupon-preview/:couponId', component: CouponPreviewComponent },
  { path: 'exit', component: ExitPageComponent },
  { path: '', redirectTo: '/init', pathMatch: 'full' },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes, {
    scrollPositionRestoration: "enabled"
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
