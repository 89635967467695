import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-punti-sostenibilita',
  templateUrl: './punti-sostenibilita.component.html',
  styleUrls: ['./punti-sostenibilita.component.css']
})
export class PuntiSostenibilitaComponent implements OnInit {

  points: number = 0;

  constructor(
    private loading: LoadingService,
    private alert: AlertService,
    public appService: AppService,
  ) { }

  ngOnInit(): void {

    // punti totale Mondi
    // this.getPoints();

    // punti del Mondo corrente - edizione 4
    this.points = this.appService.authUser?.user_world?.pointSustainability || 0;
  }

  async getPoints() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.sostenibilitaTotalPointsGet());

      this.loading.dismiss();

      this.points = data.sumPointSustainability;

    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }
}
