import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, AfterViewInit {

  alertSubscription: Subscription | undefined;

  alertOkSubscription: Subscription | undefined;

  confirmSubscription: Subscription | undefined;

  response: Subject<boolean> | null = null;

  showPopup: boolean = false;

  isPopupConfirm: boolean = false;

  message: string = "";

  text_button_confirm: string = "";

  text_button_annul: string = "";

  constructor(private alert: AlertService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.alertSubscription = this.alert.alert$.pipe().subscribe((data) => {

      this.message = data.message;

      this.response = null;

      this.isPopupConfirm = false;

      this.showPopup = true;
    });

    this.alertOkSubscription = this.alert.alertOk$.pipe().subscribe((data) => {

      this.message = data.message;

      this.response = data.response;

      this.isPopupConfirm = false;

      this.showPopup = true;
    });

    this.confirmSubscription = this.alert.confirm$.pipe().subscribe((data) => {

      this.message = data.message;

      this.text_button_confirm = data.text_button_confirm;

      this.text_button_annul = data.text_button_annul;

      this.response = data.response;

      this.isPopupConfirm = true;

      this.showPopup = true;
    });
  }

  onClickAnnul() {

    if (this.response != null) {

      this.response.next(false);
    }

    this.showPopup = false;
  }

  onClickConfirm() {

    if (this.response != null) {

      this.response.next(true);
    }

    this.showPopup = false;
  }

}
