import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { UserAccessibility } from 'src/app/interfaces/user';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-accessibilita',
  templateUrl: './accessibilita.component.html',
  styleUrls: ['./accessibilita.component.css']
})
export class AccessibilitaComponent implements OnInit {

  params: UserAccessibility = {
    twoEnabled:   1,
    threeEnabled: 1,
    fourEnabled:  1
  }

  constructor(
    public appService: AppService,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    this.retrieveParams();
  }

  /**
   * Recupera le preferenze dell'utente
   */
  async retrieveParams() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.accessibilitiesGet());

      this.params.twoEnabled = data.twoEnabled;
      
      this.params.threeEnabled = data.threeEnabled;

      this.params.fourEnabled = data.fourEnabled;
      
    } catch (error) {

      this.alert.presentAlertError();
      
    } finally {

      this.loading.dismiss();
    }
  }

  /**
   * Check singolo parametro
   * @param param
   */
  checkParam(param: number) {

    switch (param) {

      case 2:
        this.params.twoEnabled = (this.params.twoEnabled == 1) ? 0 : 1;  
        break;

      case 3:
        this.params.threeEnabled = (this.params.threeEnabled == 1) ? 0 : 1;
        break;
      
      case 4:
        this.params.fourEnabled = (this.params.fourEnabled == 1) ? 0 : 1;
        break;
    
      default:
        break;
    }
  }

  /**
   * Salva le preferenze dell'utente
   */
  async onClickSave() {

    this.loading.present();

    try {

      await lastValueFrom(this.appService.accessibilitiesSet(this.params));

      this.router.navigateByUrl('/home');
      
    } catch (error) {

      this.alert.presentAlertError();
      
    } finally {

      this.loading.dismiss();
    }
  }

}
