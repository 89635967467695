<app-header [showHeader]="true" [showBack]="true"></app-header>

<!-- inizio punti sostenibilita -->
<div class="punti_sostenibilita">
  <div class="pop_default">
    <div class="contenuto_principare_pop">
      <div class="pop_default_titolo colore_sostenibilita">
        Punti Margherite verdi
      </div>

      <div class="mondo_sostenibilita">
        <video
          playsinline
          muted
          autoplay
          poster="https://conad.s3.eu-south-1.amazonaws.com/assets/video/poster_mondo_sostenibilita.jpg"
        >
          <source
            src="https://conad.s3.eu-south-1.amazonaws.com/assets/video/mondo_sostenibilita.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div class="quiz_punti_sostenibilita">
        <div class="punti_margherita">
          <div class="valore_punti">{{ points }}</div>
          <img
            class="logo_punto_sostenibilita"
            src="/assets/img/punto_margherita.svg"
            aria-label="Punti Margherite verdi"
            alt=""
          />
        </div>
        <div class="label_punti_margherita">
          Punti Margherite verdi ottenuti con il gioco
        </div>
      </div>
    </div>

    <!-- inizio azioni -->
    <div class="azioni_pop">
      <div
        role="button"
        aria-label="Chiudi"
        class="tasto_principale"
        [routerLink]="'/home'"
      >
        CHIUDI
      </div>
    </div>
    <!-- fine azioni -->
  </div>
</div>
<!-- fine punti sostenibilita -->
