import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.css']
})
export class OnBoardingComponent implements OnInit {

  worldId: number = 0;

  constructor(
    public appService: AppService,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    if (this.appService.authUser != null) {

      this.worldId = this.appService.authUser.info.worldId;
    }
  }

  /**
   * Conferma on-boarding
   * @param url 
   */
  async onClickConfirm(url: string) {

    if (this.appService.authUser == null) return;

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.authsOnboarding(this.worldId));

      this.appService.authUser.user.signature = this.worldId;

      this.appService.saveAuthUser();

      this.onAfterOnboarding(url);
      
    } catch (error) {
      
      console.log(error);

      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

  /**
   * Redirect da eseguire dopo l'onboarding
   * @param url
   */
  private onAfterOnboarding(url: string) {

    // 1. redirect su pagina intro
    this.router.navigateByUrl(url);
  }
}
