import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, raceWith } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { BRAND_LOGO_MULINO_BIANCO } from 'src/app/interfaces/brand';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-gioco-spegni-lo-spreco',
  templateUrl: './gioco-spegni-lo-spreco.component.html',
  styleUrls: ['./gioco-spegni-lo-spreco.component.css']
})
export class GiocoSpegniLoSprecoComponent implements OnInit, OnDestroy {

  KEY_TUTORIAL_SEQUENZA: string = "tutorial_sequenza";

  playId: string = "0"; // ID giocata

  brandLogoUrl: string = "";

  // sequenza lampadine corretta
  sequenzaLampadine: Array<number> = [];
  sequenzaLampadineDaAccendere: Array<number> = [];

  // sequenza inserita dal giocatore
  sequenzaGiocatore: Array<number> = [];

  // flag accensione lampadine/interruttori
  lampadinaUno: boolean = false;
  lampadinaDue: boolean = false;
  lampadinaTre: boolean = false;
  lampadinaQuattro: boolean = false;
  lampadinaCinque: boolean = false;
  lampadinaSei: boolean = false;

  // lunghezza sequenza
  countLampadine: number = 3;

  // numero sequenze indovinate
  countCorrette: number = 0;
  // numero vittorie necessario per fine gioco
  countVittoria: number = 4;

  // esito tentativo sequenza
  esitoSequenza: boolean = false;

  // flag per mostrare errore
  isErrore: boolean = false;

  // flag per mostrare popup risultato
  mostraPopup: boolean = false;

  // flag per prima sequenza
  isPrimaSequenza: boolean = true;

  // flag per capire se animazione conclusa
  animazioneConclusa: boolean = false;

  // velocità di accensione tra una e l'altra
  speedAccensione: number = 800;

  // audio interruttore
  suonoInterruttore: any = null;

  // flag per mostrare popup tutorial
  showPopupTutorial: boolean = true;

  // flag per mostrare popup inizio gioco
  showPopupStart: boolean = false;

  // flag per mostrare popup fine gioco
  showPopupEnd: boolean = false;

  minipillola: Minipillola | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
  ) { }

  ngOnInit(): void {

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log("playId: " + this.playId);

    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {

      this.brandLogoUrl = BRAND_LOGO_MULINO_BIANCO;
    } else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.SPEGNI_LO_SPRECO);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }

    var tutorialAlreadyShown = this.local.getData(this.KEY_TUTORIAL_SEQUENZA);

    if(tutorialAlreadyShown == "1" && this.playId != "0") {
      this.showPopupTutorial = false;
      this.showPopupStart = true;
    }
  }

  ngOnDestroy(): void {
    
    // unload del suono per liberare memoria
    this.suonoInterruttore?.pause();
  }

  // inizia il gioco
  startGame() {

    this.local.saveData(this.KEY_TUTORIAL_SEQUENZA, "1");

    // nascondo tutorial
    this.showPopupTutorial = false;
    this.showPopupStart = false;

    // inizializzo sequenza
    this.generateSequenza();
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  
  // funzione per generare sequenza
  async generateSequenza() {

    // se non è prima sequenza
    if(!this.isPrimaSequenza) {
      // sei lampadine
      this.countLampadine = 6;
    }

    // inizializzo sequenza
    let sequenza = [];
    
    // loop per numero di lampadine
    for (let index = 1; index <= this.countLampadine; index++) {

      // inserisco valori in sequenza
      sequenza.push(index);
    }

    // shuffle casuale della sequenza
    this.sequenzaLampadineDaAccendere = sequenza.sort(() => Math.random() - 0.5);

    // svuoto soluzione della sequenza
    this.sequenzaLampadine = [];

    // funzione di animazione sequenza
    this.animateLampadina();
  }

  // funzione per accendere le lampadine in sequenza
  async animateLampadina() {

    // se non è presente alcuna lampadina da accendere
    if(!this.sequenzaLampadineDaAccendere.length) {
      // flag per conclusione animazione
      this.animazioneConclusa = true;
      return;
    }

    // estraggo lampadina da accendere
    var daAccendere = this.sequenzaLampadineDaAccendere.splice(0,1);

    // inserisco lampadina nella sequenza di soluzione
    this.sequenzaLampadine.push(daAccendere[0]);

    // sleep
    await this.delay(this.speedAccensione);

    // inizializzo suono casella
    this.suonoInterruttore = new Audio();
    this.suonoInterruttore.src = "assets/audio/interruttori.mp3";
    this.suonoInterruttore.load();

    // riproduco suono
    this.suonoInterruttore.play();

    // quale lampadina devo accendere
    switch (daAccendere[0]) {
      case 1:
        this.lampadinaUno = true;
        break;
      case 2:
        this.lampadinaDue = true;
        break;
      case 3:
        this.lampadinaTre = true;
        break;
      case 4:
        this.lampadinaQuattro = true;
        break;
      case 5:
        this.lampadinaCinque = true;
        break;
      case 6:
        this.lampadinaSei = true;
        break;
    }

    // chiata ricorsiva funzione
    this.animateLampadina();
  }

  // click sul singolo interruttore
  async clickInterruttore(numeroInterruttore: number) {

    // controllo se lampadina già spenta o animazione ancora in corso
    if(this.sequenzaGiocatore.indexOf(numeroInterruttore) != -1 || !this.animazioneConclusa) {
      return;
    }

    // inizializzo suono interruttore
    this.suonoInterruttore = new Audio();
    this.suonoInterruttore.src = "assets/audio/interruttori.mp3";
    this.suonoInterruttore.load();

    // riproduco suono
    this.suonoInterruttore.play();

    // inserisco lampadina spenta nella sequenza del giocatore
    this.sequenzaGiocatore.push(numeroInterruttore);

    // spengo la lampadina scelta
    switch (numeroInterruttore) {
      case 1:
        this.lampadinaUno = false;
        break;
      case 2:
        this.lampadinaDue = false;
        break;
      case 3:
        this.lampadinaTre = false;
        break;
      case 4:
        this.lampadinaQuattro = false;
        break;
      case 5:
        this.lampadinaCinque = false;
        break;
      case 6:
        this.lampadinaSei = false;
        break;
    }
    
    // controllo se la sequenza è completa
    if(this.sequenzaGiocatore.length == this.sequenzaLampadine.length) {

      // funzione per controllare il risultato
      this.checkResult();
    }
  }

  // funzione per controllare il risultato
  async checkResult() {

    // flag per sequenza corretta
    var isCorretta = true;

    // per ogni lampadina spenta dal giocatore
    this.sequenzaGiocatore.forEach((value, key) => {
      
      // controllo correttezza sequenza
      if (this.sequenzaGiocatore[key] != this.sequenzaLampadine[key]) {
        isCorretta = false;
      }
    });

    // aggiorno esito sequenza
    this.esitoSequenza = isCorretta;
  
    // mostro errore
    this.isErrore = true;
    
    // sleep
    await this.delay(300);

    let isFineGioco = false

    // se la sequenza è stata indovinata
    if (this.esitoSequenza) {

      // aumento punteggio
      this.countCorrette++;
      
      // se utente ha vinto
      if (this.countCorrette == this.countVittoria) {

        isFineGioco = true;
      }
    }

    // se non viene mostrata popup fine gioco
    if (!isFineGioco) {

      // mostro popup
      this.mostraPopup = true;
    
    } else {

      if (this.playId != null && this.playId != "0") {

        await this.setRewardPlayed();

      } else {

        // mostro subito popup fine gioco
        this.showPopupEnd = true;
      }
    }
  }

  // funzione per iniziare una nuova sequenza
  async continua() {

    // se la sequenza è stata indovinata
    if(this.esitoSequenza) {
      // non è più la prima sequenza
      this.isPrimaSequenza = false;

      if(this.countCorrette <= 7) {
        // aumento la velocità accensione
        this.speedAccensione = Math.floor((this.speedAccensione*5)/6);
      }
    }

    // flag per animazione in corso
    this.animazioneConclusa = false;
    // nascondo popup
    this.mostraPopup = false;
    // reset della sequenza giocatore
    this.sequenzaGiocatore = [];
    // reset del flag
    this.isErrore = false;

    // sleep
    await this.delay(500);

    // reset dell'esito
    this.esitoSequenza = false;

    // genero nuova sequenza
    this.generateSequenza();
  }

  // funzione per popup chiudi il gioco
  async clickClose() {

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm("Sicuro di voler uscire?", "CONFERMO", "ANNULLA");

    // se utente conferma
    if(response) {
      // ritorno home page
      this.router.navigate(['/home']);
    }
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.gamePlayed(this.playId, true));

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupEnd = true;
      
    } catch (error) {
      
      this.loading.dismiss();
      
      const res = await this.alert.presentConfirm("Controlla la tua connessione Internet", "RIPROVA", "ANNULLA");

      if (res) {

        this.setRewardPlayed();

      } else {

        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }
}
