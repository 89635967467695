import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { BRAND_LOGO_BARILLA } from 'src/app/interfaces/brand';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-gioco-clicca-prodotti',
  templateUrl: './gioco-clicca-prodotti.component.html',
  styleUrls: ['./gioco-clicca-prodotti.component.css']
})
export class GiocoCliccaProdottiComponent implements OnInit {

  KEY_TUTORIAL_CLICCA_PRODOTTI: string = "tutorial_clicca_prodotti";

  playId: string = '0'; // ID giocata
  brandLogoUrl: string = '';
  score: number = 0;
  gameActive: boolean = true;
  generateObjects: any;
  game: any;
  delaySpawn: number = 600;
  endGamePoints: number = 10;
  minipillola: Minipillola | null = null;
  showPopupConclusione: boolean = false;
  showError: boolean = false;

  popupTutorial: boolean = true;

  constructor(
    public appService: AppService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
  ) { }

  ngOnInit(): void {

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log('playId: ' + this.playId);
    
    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {
      this.brandLogoUrl = BRAND_LOGO_BARILLA;
    }else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.CLICCA_PRODOTTI);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }

    var tutorialAlreadyShown = this.local.getData(this.KEY_TUTORIAL_CLICCA_PRODOTTI);

    if(tutorialAlreadyShown == '1' && this.playId != "0") {
      this.popupTutorial = false;
      this.game = document.getElementById("game");
      this.startGame();
    }
  }

  async startGame() {
    
    this.dropObject();
  }

  random(min: number, max: number) {
    return Math.round(Math.random() * (max-min) + min);
  }

  async dropObject() {

    // attendo
    await this.delay(this.delaySpawn);

    var width = this.game?.offsetWidth;
    
    if(width) {
      
      var length = this.random(0, width - 100);
      var velocity = this.random(2000, 10000);
      var size = this.random(50, 150);

      var div = this.renderer.createElement("div");
      this.renderer.addClass(div, 'box');
      this.renderer.setStyle(div, 'width', size+'px');
      this.renderer.setStyle(div, 'height', size+'px');
      this.renderer.setStyle(div, 'left', length+'px');
      this.renderer.setStyle(div, 'transition', 'transform '+velocity+'ms linear');
      var random = this.random(1,22);
      if(random <= 11) {
        //this.renderer.setStyle(div, 'background', "url('http://icons.iconarchive.com/icons/hopstarter/halloween-avatars/128/Frankenstein-icon.png')");
        this.renderer.setStyle(div, 'background', "url('/assets/img/gioco_prodotti/prodotto_ok_"+random+".png')");
        this.renderer.addClass(div, 'punti');
      } else {
        this.renderer.setStyle(div, 'background', "url('/assets/img/gioco_prodotti/prodotto_fake_"+(random - 11)+".png')");
      }
      this.renderer.setStyle(div, 'background-size', "contain");
      this.renderer.appendChild(this.game, div);

      this.renderer.listen(div, 'webkitTransitionEnd', (evt) => {
        if(evt.target.className.indexOf('punti') != -1) {
          this.showErrorF();
          if(this.score > 0 && this.gameActive) {
            this.score --;
          }
        }
        this.renderer.removeChild(this.game, div);
      });

      this.renderer.listen(div, 'click', (evt) => {
        if(this.score < this.endGamePoints) {
          if(evt.target.className.indexOf('punti') != -1) {
            this.renderer.removeClass(div, 'punti');
            this.increaseScore();
            this.renderer.removeChild(this.game, div);
            if(this.score >= this.endGamePoints) {
              this.gameActive = false;
            }
          } else {
            this.showErrorF();
            if(this.score > 0) {
              this.score--;
            }
            this.renderer.removeChild(this.game, div);
          }
        }
      });
      
      this.startMove(div);
    }

    // se gioco ancora in corso
    if (this.gameActive) {
      // ricorsione funzione
      this.dropObject();
    }
  }

  async startMove(object: any) {

    await this.delay(this.delaySpawn);
    this.renderer.addClass(object, 'move');
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // funzione per aumentare punteggio
  async increaseScore() {
    // aumento punteggio
    this.score++;
    
    // se raggiungo obiettivo
    if (this.score == this.endGamePoints) {
      // gioco non più in corso
      this.gameActive = false;

      if (this.playId != null && this.playId != '0') {
        await this.setRewardPlayed();
      } else {
        // mostro subito popup fine gioco
        this.showPopupConclusione = true;
      }
    }
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {
    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.gamePlayed(this.playId, true)
      );

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupConclusione = true;
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setRewardPlayed();
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  // funzione per popup chiudi il gioco
  async clickClose() {
    // disattivo momentaneamente il gioco
    this.gameActive = false;

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm(
      'Sicuro di voler uscire?',
      'CONFERMO',
      'ANNULLA'
    );

    // se utente conferma
    if (response) {
      // ritorno home page
      this.router.navigate(['/home']);
    } else {
      // riattivo gioco
      this.gameActive = true;
      this.dropObject();
    }
  }

  // funzione per chiudere tutorial
  closeTutorial() {
    // nascondo popup
    this.popupTutorial = false;

    this.local.saveData(this.KEY_TUTORIAL_CLICCA_PRODOTTI, '1');
    
    this.game = document.getElementById("game");
    this.startGame();
  }

  async showErrorF() {
    this.showError = true;
    await this.delay(400);
    this.showError = false;
  }
}
