<app-header [showHeader]="true" [showBack]="true"></app-header>

<div class="bg_cielo"></div>

<!-- inizio elenco editoriali -->
<div class="elenco_editoriali">
    
    <div *ngIf="showEmptyMessage" class="pop_default" style="padding-top: 20px; text-align: center;">
        <div class="titolo_editoriale">Qui troverai i contenuti speciali raccolti durante il gioco</div>
    </div>

    <!-- inizio pop default -->
    <div *ngFor="let item of items" class="pop_default">

        <div class="contenuto_principare_pop">
            
            <div class="contenuto_editoriale_foto">
                <img [src]="item.imageUrl" alt="{{item.title}}">
            </div>
            
            <!--<div class="titolo_editoriale">{{item.title}}</div>-->
            
            <div class="azioni_pop" style="margin-top: 0;">
                <div class="tasto_principale" (click)="onClickContent(item)" role="button" aria-label="Scopri di più">SCOPRI DI PIÙ</div>
            </div>
            
        </div>

    </div>
    <!-- fine pop default -->
    
</div>
<!-- fine elenco editoriali -->
