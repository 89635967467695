import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";
import * as Hammer from 'hammerjs';

/**
 * @hidden
 * This class overrides the default Angular gesture config.
 */
 @Injectable()
export class AngularGestureConfig extends HammerGestureConfig {

    swipeOptions: RecognizerOptions = { direction: Hammer.DIRECTION_ALL };

    /**
     * Maps gesture event names to a set of configuration options
     * that specify overrides to the default values for specific properties.
     */
    override overrides = {
        'swipe': this.swipeOptions
    }

    override buildHammer(element: HTMLElement) {

        const mc = new Hammer(element);
    
        mc.get('swipe').set(this.swipeOptions);

        /*for (const eventName in this.overrides) {
            if (eventName && typeof eventName == "string") {
                mc.get(eventName).set(this.overrides[eventName]);
            }
        }*/
    
        return mc;
    }
}
