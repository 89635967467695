import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, AfterViewInit {

  loadingSubscription: Subscription | undefined;

  isActive: boolean = false;

  constructor(
    public loading: LoadingService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.loadingSubscription = this.loading.loading$.pipe().subscribe((data) => {

      this.isActive = data.isActive;
    });
  }

}
