import { Avatar } from "./game";

export const KEY_AUTH_USER = "auth_user";

export interface User {

    user: {

        userId: string;

        fidelityCard: string;

        userGameId: string;
        
        name: string;

        signature: number;
    }

    info: {

        worldId: number;

        accessibility: 0|1;
    }
    
    auth: JwtAccessToken;

    avatar?: Avatar;

    user_world?: {
        pointSustainability: number;
    }
}

export interface JwtAccessToken {

    jwtToken: string;
    
    refreshToken: string;
}

export interface UserAccessibility {

    twoEnabled: number;
    
    threeEnabled: number;
    
    fourEnabled: number;
}

export interface Sustainability {

    sumPointSustainability: number;
}