import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-video-fine-livello',
  templateUrl: './video-fine-livello.component.html',
  styleUrls: ['./video-fine-livello.component.css']
})
export class VideoFineLivelloComponent implements OnInit {

  @ViewChild('videoFineLivello') videoFineLivello!: ElementRef;

  livello: string = "1";
  mondo: string = "1";

  constructor(
    private router: Router,
    public appService: AppService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    
    this.route.queryParams.forEach((element) => {
      if (element['livello']) {
        this.livello = element['livello'];
      }
      if (element['mondo']) {
        this.mondo = element['mondo'];
      }
    });
  }

  // funzione da eseguire alla fine del video
  fineVideo() {

    // torna alla home page
    this.router.navigate(['/home']);
  }

}
