import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alert$: Subject<{message: string}>;

  confirm$: Subject<{
    message: string;
    text_button_confirm: string;
    text_button_annul: string;
    response: Subject<boolean>;
  }>;

  alertOk$: Subject<{
    message: string;
    response: Subject<boolean>;
  }>;

  constructor() {

    this.alert$ = new Subject();

    this.alertOk$ = new Subject();

    this.confirm$ = new Subject();
  }

  /**
   * Mostra un messaggio customizzato
   * @param message  
   */
  presentAlert(message: string) {

    this.alert$.next({message: message});
  }

  /**
   * Mostra un messaggio di errore generico
   */
  presentAlertError(message?: string) {

    this.presentAlert(message || "Controlla la tua connessione Internet");
  }

  /**
   * Mostra un alert di conferma
   * @param message 
   * @param text_button_confirm 
   * @param text_button_annul 
   */
  presentConfirm(message: string, text_button_confirm: string, text_button_annul: string): Promise<boolean> {

    const response = new Subject<boolean>();

    this.confirm$.next({
      message: message,
      text_button_confirm: text_button_confirm,
      text_button_annul: text_button_annul,
      response: response
    });

    return new Promise<boolean>((resolve, reject) => {

      response.subscribe((res) => resolve(res));
    });
  }

  /**
   * Mostra un alert di conferma con il solo pulsante OK
   * @param message 
   */
  presentAlertOk(message: string): Promise<boolean> {

    const response = new Subject<boolean>();

    this.alertOk$.next({message: message, response: response});

    return new Promise<boolean>((resolve, reject) => {

      response.subscribe((res) => resolve(res));
    });
  }
}
