<app-header [showHeader]="showFooter && !isMoving"></app-header>

<!-- -------------------------------------------------- INIZIO ELEMENTI PER NON VEDENTI ------------------------------------------------- -->

<!-- inizio non vedenti lancia il dado popup -->
<ng-container *ngIf="showPopupDado">
  <div
    id="nonvedenti_popup_dado"
    class="pop_gioco"
    role="alertdialog"
    tabindex="-1"
    [attr.aria-hidden]="!showPopupDado ? 'true' : 'false'"
    [attr.aria-modal]="showPopupDado ? 'true' : 'false'"
  >
    <div id="nonvedenti_popup_dado_titolo">
      Avanzi di <strong>{{ risultatoDado }}</strong> caselle
    </div>
    <div role="button" aria-label="Procedi" (click)="closeDado()">PROCEDI</div>
  </div>
</ng-container>
<!-- fine non vedenti lancia il dado popup -->

<!-- -------------------------------------------------- FINE ELEMENTI PER NON VEDENTI ------------------------------------------------- -->

<div class="video_intro_mondo_3" *ngIf="currentMondo == 3 && showVIdeoIntroMondo3">
  <video autoplay playsinline muted loop poster="https://conad.s3.eu-south-1.amazonaws.com/assets/video/anteprima_intro_mondo_3.jpg">
    <source src="https://conad.s3.eu-south-1.amazonaws.com/assets/video/intro_mondo_3.mp4" type="video/mp4" />
  </video>
</div>

<div class="video_intro_mondo_3" *ngIf="currentMondo == 4 && showVIdeoIntroMondo4">
  <video autoplay playsinline muted loop poster="https://conad.s3.eu-south-1.amazonaws.com/assets/video/anteprima_intro_mondo_4.jpg">
    <source src="https://conad.s3.eu-south-1.amazonaws.com/assets/video/intro_mondo_4.mp4" type="video/mp4" />
  </video>
</div>


<div
  aria-hidden="false"
  class="contenitore"
  [ngClass]="{ hide: !showAll }"
  (swipe)="onSwipe($event)"
>
  <div
    class="popup_dado"
    [ngClass]="{ mostra_pop_dado: showPopupDado }"
    aria-hidden="true"
  >
    <div class="popup_dado_contenitore">
      <div id="titolo_pop_lancia_dado" class="nonvedente">
        Avanzi di <strong>{{ risultatoDado }}</strong> caselle
      </div>
      <div
        aria-hidden="true"
        class="pop_dado_risultato"
        *ngIf="showProcediDado"
      >
        Avanzi di <strong>{{ risultatoDado }}</strong> caselle
      </div>
      <div
        class="contenitore_tasto"
        *ngIf="showProcediDado"
        (click)="closeDado()"
      >
        <div class="tasto_principale" role="button" aria-label="Procedi">
          PROCEDI
        </div>
      </div>
      <video
        playsinline
        muted
        #videoDado
        poster="https://conad.s3.eu-south-1.amazonaws.com/assets/video/dado/poster_dado.jpg"
      >
        <source *ngIf="risultatoDado" type="video/mp4" />
      </video>
    </div>
  </div>
  <!-- fine popup dado -->

  <!-- inizio contenitore poupup per accessibilità -->
  <div class="contenitore_pupup" role="none" aria-hidden="true">
    <!-- inizio coupon vinto -->
    <div
      class="popup_evento popup_coupon_vinto"
      [ngClass]="{ mostra_popup_evento: typePopup == 2 }"
    >
      <div *ngIf="coupon" class="popup_evento_contenitore">
        <div class="coupon_vinto_foto">
          <div class="coupon_vinto_foto_etichetta">{{ coupon.labelValue }}</div>
          <img [src]="coupon.imageUrl" alt="" />
        </div>

        <div class="popup_evento_titolo">
          Hai ottenuto un<br />Buono Sconto!
        </div>

        <div class="popup_evento_contenuto">
          <img class="popup_logo_brand" [src]="coupon.imageBrandUrl" alt="" />

          <div class="coupon_vinto_periodo"
            [innerHTML]="coupon.abstract | safeHtml"
          ></div>

          <div
            class="coupon_vinto_info"
            [innerHTML]="coupon.description | safeHtml"
          ></div>

          <div class="coupon_vinto_consiglio">
            Per utilizzare il coupon vai nella sezione offerte e buoni dell’app
            Conad.
          </div>
        </div>
        <div class="azioni_pop" style="border: none">
          <div
            (click)="closePremio()"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Continua"
          >
            CONTINUA
          </div>
        </div>
      </div>
    </div>
    <!-- fine coupon vinto -->

    <!-- inizio mini gioco -->
    <div
      class="popup_evento popup_mini_gioco"
      [ngClass]="{ mostra_popup_evento: typePopup == 3 }"
    >
      <div class="popup_evento_contenitore">
        <div *ngIf="brandLogoUrl != ''" class="popup_minigioco_brand">
          <img [src]="brandLogoUrl" alt="" />
        </div>
        <div class="popup_evento_titolo">
          Supera la prova per ottenere una Margherita verde
        </div>
        <div class="popup_evento_contenuto">
          <img
            *ngIf="typeMinigioco == 1"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_quiz.jpg"
            aria-label="Gioca al quiz"
            alt="Quiz"
          />

          <img
            *ngIf="typeMinigioco == 2"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_spegni_spreco.jpg"
            aria-label="Gioca a spegni lo spreco"
            alt="Spegni lo spreco"
          />

          <img
            *ngIf="typeMinigioco == 3"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_squalo.jpg"
            aria-label="Gioca a ripuliamo i mari"
            alt="Ripuliamo i mari"
          />

          <img
            *ngIf="typeMinigioco == 4"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_differenziata.jpg"
            aria-label="Gioca a fai la differenziata"
            alt="Fai la differenziata"
          />

          <img
            *ngIf="typeMinigioco == 5"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_memory.jpg"
            aria-label="Gioca a fai la differenziata"
            alt="Fai la differenziata"
          />

          <img
            *ngIf="typeMinigioco == 6"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_ortaggi.jpg"
            aria-label="Gioca a fai la differenziata"
            alt="Fai la differenziata"
          />

          <img
            *ngIf="typeMinigioco == 7"
            class="popup_anteprima_minigioco"
            src="/assets/img/anteprima_minigioco_afferra.jpg"
            aria-label="Gioca a fai la differenziata"
            alt="Fai la differenziata"
          />
        </div>

        <div class="azioni_pop">
          <div
            *ngIf="typeMinigioco == 1"
            [routerLink]="'/gioco-quiz/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 2"
            [routerLink]="'/gioco-spegni-lo-spreco/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 3"
            [routerLink]="'/gioco-squalo/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 4"
            [routerLink]="'/gioco-raccolta-differenziata/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 5"
            [routerLink]="'/gioco-memory/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 6"
            [routerLink]="'/gioco-brick-breaker/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <div
            *ngIf="typeMinigioco == 7"
            [routerLink]="'/gioco-clicca-prodotti/' + playId"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Sono pronto"
          >
            SONO PRONTO
          </div>

          <a
            (click)="closePremio()"
            class="tasto_secondario"
            [routerLink]="'/home'"
            role="button"
            aria-label="Non adesso"
            >Non adesso</a
          >
        </div>
      </div>
    </div>
    <!-- fine mini gioco -->

    <!-- inizio pop fine livello -->
    <div
      class="popup_evento popup_fine_livello"
      [ngClass]="{ mostra_popup_evento: typePopup == 9 }"
    >
      <div class="popup_evento_contenitore">
        <div class="popup_evento_titolo">
          Complimenti!<br />Hai superato il livello {{ currentLivello }}
        </div>
        <div class="popup_evento_descrizione">Hai guadagnato:</div>
        <div class="popup_evento_contenuto" style="justify-content: flex-start">
          <img
            class="popup_anteprima_minigioco"
            src="/assets/img/punto_margherita.svg"
            aria-label="Margherita verde"
            alt="Margherita verde"
            style="max-width: 150px"
          />
          <div class="punti_sosteniamo_futuro colore_sostenibilita">1</div>
          <!--
				<div class="crediti_sosteniamo_futuro colore_sostenibilita">
				  Credito
				</div>
				-->
          <div class="voce_sosteniamo_futuro colore_sostenibilita">
            Margherita verde
          </div>
        </div>
        <div class="azioni_pop">
          <div
            (click)="closePremio('fine')"
            class="tasto_principale next-slide"
            role="button"
            aria-label="Continua"
          >
            CONTINUA
          </div>
        </div>
      </div>
    </div>
    <!-- fine pop fine livello -->
  </div>
  <!-- fine contenitore popup per accessibilità -->

  <!-- tasto lancia dado -->
  <div
    class="contenitore_tasto_lancia"
    [ngClass]="{
      mostra_tasto_lancia_dado: !showPopupDado && !isMoving && tiriDado > 0
    }"
  >
    <div
      class="tasto_principale tasto_principale_ombra"
      (click)="tiraDado()"
      role="button"
      data-toggle="alertdialog"
      aria-controls="nonvedenti_popup_dado"
      tabindex="0"
      [attr.aria-hidden]="
        showPopupDado || isMoving || tiriDado <= 0 ? 'true' : 'false'
      "
      [attr.aria-label]="
        !showPopupDado && !isMoving && tiriDado > 0 ? 'Lancia il dado' : null
      "
    >
      LANCIA IL DADO
    </div>
  </div>
  <!-- fine tasto lancia dado -->

  <!-- inizio contenitore elementi -->
  <!-- NB. se sono nel mondo 3 aggiungere la classe "contenitore_elementi_no_intro" -->    
  <div class="contenitore_elementi" [ngClass]="{contenitore_elementi_no_intro: currentMondo == 3}">
    <div
      class="pin {{ avatar?.code }}"
      [ngClass]="{
        pin_1: currentOrientation == 0,
        pin_2: currentOrientation == 1,
        pin_3: currentOrientation == 2,
        pin_4: currentOrientation == 3
      }"
    >
      <img
        class="ombra"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/ombra.png"
        aria-hidden="true"
        role="none"
        alt=""
      />
    </div>

    <!-- inizio base percorso -->
    <div
      id="base_percorso"
      class="base"
      [@moveAvatar]="{
        value: isAnimated
          ? isAnimated == 'begin'
            ? 'begin'
            : 'start'
          : 'finish',
        params: { time: animationSpeed, x: x, y: y }
      }"
    >
      <div class="logo">
        <div class="logo_animazione"></div>
      </div>

      <!-- mappe mondo 1 -->
      <img
        class="percorso"
        *ngIf="currentMondo == 1 && currentLivello == 4"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_1_livello_4.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 1 && currentLivello == 3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_1_livello_3.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 1 && currentLivello == 2"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_1_livello_2_flat.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 1 && currentLivello == 1"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo1_livello1_prova.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />

      <!-- mappe mondo 2 -->
      <img
        class="percorso"
        *ngIf="currentMondo == 2 && currentLivello == 1"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_2_livello_1.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 2 && currentLivello == 2"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_2_livello_2.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 2 && currentLivello == 3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_2_livello_3.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 2 && currentLivello == 4"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_2_livello_4.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />

      <!-- mappe mondo 3 -->
      <img
        class="percorso"
        *ngIf="currentMondo == 3 && currentLivello == 1"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_3_livello_1.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 3 && currentLivello == 2"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_3_livello_2.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 3 && currentLivello == 3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_3_livello_3.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 3 && currentLivello == 4"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_3_livello_4.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
        
        
      <!-- mappe mondo 4 -->
      <img
        class="percorso"
        *ngIf="currentMondo == 4 && currentLivello == 1"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_4_livello_1.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 4 && currentLivello == 2"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_4_livello_2.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 4 && currentLivello == 3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_4_livello_3.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />
      <img
        class="percorso"
        *ngIf="currentMondo == 4 && currentLivello == 4"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo_4_livello_4.webp"
        aria-hidden="true"
        role="none"
        alt=""
      />

      <!-- inizio elementi grafici mondo 1 -->
      <div
        class="mulino_mondo_1_livello_2"
        *ngIf="currentMondo == 1 && currentLivello == 2"
      ></div>
      <div
        class="mulino_mondo_1_livello_4"
        *ngIf="currentMondo == 1 && currentLivello == 4"
      ></div>

      <div
        class="eliche_elicottero_mondo_1_livello_4"
        *ngIf="currentMondo == 1 && currentLivello == 4"
      ></div>

      <img
        class="api_1_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />
      <img
        class="api_2_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_2_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />
      <img
        class="api_3_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_3_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />

      <img
        class="api_4_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />
      <img
        class="api_5_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_2_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />
      <img
        class="api_6_livello_3"
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/api_3_livello_3.svg"
        *ngIf="currentMondo == 1 && currentLivello == 3"
      />
      <!-- fine elementi grafici mondo 1 -->

      <!-- inizio elementi grafici mondo 2 -->
      <div
        class="cavalluccio_1 cav_a"
        *ngIf="currentMondo == 2 && currentLivello == 1"
      ></div>
      <div
        class="cavalluccio_2 cav_b"
        *ngIf="currentMondo == 2 && currentLivello == 1"
      ></div>
      <div
        class="medusa_1 med_a"
        *ngIf="currentMondo == 2 && currentLivello == 1"
      ></div>
      <div
        class="tentacolo_1"
        *ngIf="currentMondo == 2 && currentLivello == 1"
      ></div>

      <div
        class="cavalluccio_1 cav_c"
        *ngIf="currentMondo == 2 && currentLivello == 2"
      ></div>
      <div
        class="cavalluccio_2 cav_d"
        *ngIf="currentMondo == 2 && currentLivello == 2"
      ></div>
      <div
        class="medusa_1 med_b"
        *ngIf="currentMondo == 2 && currentLivello == 2"
      ></div>
      <div
        class="medusa_1 med_c"
        *ngIf="currentMondo == 2 && currentLivello == 2"
      ></div>

      <div
        class="cavalluccio_1 cav_e"
        *ngIf="currentMondo == 2 && currentLivello == 3"
      ></div>
      <div
        class="cavalluccio_2 cav_f"
        *ngIf="currentMondo == 2 && currentLivello == 3"
      ></div>
      <div
        class="medusa_1 med_d"
        *ngIf="currentMondo == 2 && currentLivello == 3"
      ></div>
      <div
        class="medusa_1 med_e"
        *ngIf="currentMondo == 2 && currentLivello == 3"
      ></div>
      <div
        class="medusa_1 med_f"
        *ngIf="currentMondo == 2 && currentLivello == 3"
      ></div>

      <div
        class="cavalluccio_1 cav_g"
        *ngIf="currentMondo == 2 && currentLivello == 4"
      ></div>
      <div
        class="cavalluccio_2 cav_h"
        *ngIf="currentMondo == 2 && currentLivello == 4"
      ></div>
      <div
        class="medusa_1 med_g"
        *ngIf="currentMondo == 2 && currentLivello == 4"
      ></div>
      <!-- <div class="medusa_2 med_h" *ngIf="currentMondo == 2 && currentLivello == 4"></div> -->
      <!-- fine elementi grafici mondo 2 -->

      <div id="caselle_attive" class="caselle_attive"></div>
    </div>
  </div>
  <!-- fine contenitore elementi -->

  <!-- inizio elementi grafici mondo 2 -->
  <div class="ocean" *ngIf="currentMondo == 2" aria-hidden="true" role="none">
    <svg class="luce luce_1" viewBox="0 0 360 720">
      <polygon points="173.2,0 2.2,720 113.2,720 284.2,0" />
    </svg>

    <svg class="luce luce_2" viewBox="0 0 228.7 720">
      <polygon points="173.2,0 2.2,720 57.7,720 228.7,0" />
    </svg>

    <div class="bubble bubble--1">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--2">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--3">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--4">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--5">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--6">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--7">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--8">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--9">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--10">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--11">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
    <div class="bubble bubble--12">
      <img
        src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/bolla.png"
        alt=""
      />
    </div>
  </div>

  <div class="contenitore_pesci" *ngIf="currentMondo == 2">
    <img
      class="pesci"
      src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/mondo2/mondo2_branco_pesci.png"
      alt=""
    />
  </div>
  <!-- fine elementi grafici mondo 2 -->
</div>

<app-footer
  [showFooter]="showFooter"
  [casellaCorrente]="currentPosition"
  [tiriDado]="tiriDado"
  [puntiSostenibilita]="puntiSostenibilita"
  [avatar]="avatar"
></app-footer>

<!-- <div *ngIf="showPedina" id="pedina" class="avatar" [ngClass]="{'orientation_1': currentOrientation == 1, 'orientation_2': currentOrientation == 2, 'orientation_3': currentOrientation == 3}" [@moveAvatar]="{value: (isMoving ? ((isMoving == 'begin')?'begin':'start') : 'finish'), params:{time: animationSpeed+'ms ease-in-out', x: x, y: y}}"></div>
<button type="button" (click)="move(3)">Avanza</button> -->

<div class="precarica_elementi" aria-hidden="true" role="none">
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_1.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_2.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_3.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_4.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_1.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_2.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_3.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_4.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_1.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_2.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_3.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_4.png"
    alt=""
  />
  <img
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/casella_attiva.png"
    alt=""
  />
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_2.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_3.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/tritone_4.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_2.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_3.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/natura_4.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_2.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_3.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/aria_4.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/casella_attiva.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/elica_finale_min.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/logo_rotante.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/fotovoltaico_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/albero_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/albero_2.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/fiore_1.png);
    "
  ></div>
  <div
    style="
      background-image: url(https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/fiore_2.png);
    "
  ></div>
</div>

<audio>
  <source
    src="https://conad.s3.eu-south-1.amazonaws.com/assets/audio/tasto_illuminato.mp3"
  />
</audio>
