import { EditorialContent } from "./content";
import { Coupon } from "./coupon";

export const KEY_VIDEO_INTRO_MONDO_3 = "video_intro_mondo_3";
export const KEY_VIDEO_INTRO_MONDO_4 = "video_intro_mondo_4";

export interface Avatar {
    name: string;
    worldId: number;
    code: string;
    url: string;
    video: string;
    greatings: string;
    isDefault: boolean;
}

export interface World {
    worldId: number;
    sku: string;
    dateStart: string;
    dateEnd: string;
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
}

export interface UserWorld {
    userGameId: string;
    worldId: number;
    fidelityCard: string;
    gameLevel: number;
    completedLevels: number;
    positionCurrent: number;
    positionTotal: number;
    avatarCode: string;
    pointSustainability: number;
    createdAt: string;
    updatedAt: string;
}

export interface UserPlayed {
    playId: string;
    userGameId: string;
    sourceId:SourcePlay;
    resourceId:string;
    resourceTimestamp:string;
    worldId:number;
    status:StatusPlay; 
    numberResult:number;
    positionBefore:number;
    positionAfter:number;
    resultType:TypeResultPlay;
    gameId:number;
    couponId:string;
    brandId:string;
    contentId:number;
    resultTimestamp:string;
    createdAt:string;
    updatedAt:string;
}

export const enum SourcePlay {
    SOURCE_RECEIPT = "receipt",
    SOURCE_BACKEND = "backend",
    SOURCE_TRIAL = "trial",
    SOURCE_GIFT = "gift",
    SOURCE_EXTRA_ROLL = "extra-roll"
}

export const enum StatusPlay {
    STATUS_PENDING = "pending",
    STATUS_PLAYING = "playing",
    STATUS_PLAYED = "played"
}

export const enum TypeResultPlay {
    RESULT_NONE = "none",
    RESULT_GIOCO = "game",
    RESULT_COUPON = "coupon",
    RESULT_TIRO_EXTRA = "extra-roll",
    RESULT_CONTENUTO_EDITORIALE = "editorial-content",
    RESULT_PUNTI_SOSTENIBILITA = "sustainability-point"
}

export const ResultPopupHome = {
    LANCIO_DADO: 1,     // extra-roll
    COUPON: 2,          // coupon
    MINI_GIOCO: 3,      // game
    CONTENT: 4,         // editorial-content
    FINE_LIVELLO: 9
}

export const TypeMiniGame = {
    QUIZ: 1,
    SPEGNI_LO_SPRECO: 2,
    SQUALO_IN_MARE: 3,
    RACCOLTA_DIFFERENZIATA: 4,
    MEMORY: 5,
    BRICK_BREAKER: 6,
    CLICCA_PRODOTTI: 7,
}

export interface ResponseGameRoll {
    user_world: UserWorld;
    user_played: UserPlayed;
    isEndOfLevel: boolean;
    coupon?: Coupon;
    content?: EditorialContent;
}

export interface ResponseGamePlayed {
    user_world: UserWorld;
    user_played: UserPlayed;
}