import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EditorialContent } from '../interfaces/content';
import { Avatar, ResponseGamePlayed, ResponseGameRoll, UserPlayed, UserWorld, World } from '../interfaces/game';
import { QuizAnswer, QuizQuestion } from '../interfaces/quiz';
import { JwtAccessToken, KEY_AUTH_USER, Sustainability, User, UserAccessibility } from '../interfaces/user';
import { LocalService } from './local.service';
import { Coupon } from '../interfaces/coupon';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  authUser: User|null = null;

  constructor(
    public local: LocalService,
    private http: HttpClient
  ) { }

  /**
   * Save data User
   * @param user 
   */
  saveAuthUser() {

    if (this.authUser == null) {

      this.removeAuthUser();
    
    } else {

      this.local.saveData(KEY_AUTH_USER, JSON.stringify(this.authUser));
    }
  }

  /**
   * Get data User
   * @returns 
   */
  getAuthUser(): User|null {

    const data = this.local.getData(KEY_AUTH_USER);

    if (data == null) {

      this.authUser = null;

      return null;
    }

    const user: User = JSON.parse(data);

    this.authUser = user;

    return user;
  }

  /**
   * Remove data User
   */
  removeAuthUser() {

    this.authUser = null;

    this.local.removeData(KEY_AUTH_USER);
  }

  /**
   * Restituisce l'ID del Mondo in base alla data attuale
   * @returns 
   */
  getWorldIdByDate(): number {

    const dateNow = new Date();

    const dateWorld2 = new Date("2023-07-06T00:00:00"); // date start worldId 2

    const dateWorld3 = new Date("2023-09-28T00:00:00"); // date start worldId 3

    const dateWorld4 = new Date("2023-12-13T00:00:00"); // date start worldId 4

    let worldId = 1; // default

    if (dateNow.getTime() >= dateWorld2.getTime() && dateNow.getTime() < dateWorld3.getTime()) {

      worldId = 2;
    }

    if (dateNow.getTime() >= dateWorld3.getTime() && dateNow.getTime() < dateWorld4.getTime()) {

      worldId = 3;
    }

    if (dateNow.getTime() >= dateWorld4.getTime()) {

      worldId = 4;
    }

    return worldId;
  }

  /**
   * Invia rihiesta per aggiornare il token di autenticazione 
   */
  userAuthToken() {

    let url = API_URL + "/api/auth/auths/refresh";

    let params = {refreshToken: (this.authUser) ? this.authUser.auth.refreshToken : ""};

    return this.http.post<JwtAccessToken>(url, params).pipe(
      tap((data) => {

        if (this.authUser != null) {

          this.authUser.auth = {
            jwtToken: data.jwtToken,
            refreshToken: data.refreshToken
          };
  
          this.saveAuthUser();
        }
      })
    );
  }

  /**
   * Recupera i dati utente dal token di accesso passato dall'applicazione
   * @param token 
   * @returns 
   */
  authsAuth(token: string) {

    const body = { "authToken": token };

    return this.http.post<User>(API_URL + "/api/auth/auths/auth", body);
  }

  /**
   * Effettua l'on-boarding dell'utente
   * @param worldId 
   * @returns 
   */
  authsOnboarding(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<any>(API_URL + "/api/auth/auths/onboarding", body);
  }

  /**
   * Restituisce le preferenze sull'accessibilità
   * @returns 
   */
  accessibilitiesGet() {

    return this.http.post<UserAccessibility>(API_URL + "/api/auth/accessibilities/get", null);
  }

  /**
   * Salva le preferenze sull'accessibilità
   * @param body 
   * @returns 
   */
  accessibilitiesSet(body: UserAccessibility) {

    return this.http.post<UserAccessibility>(API_URL + "/api/auth/accessibilities/set", body);
  }

  /**
   * Restituisce la lista degli Avatar associati ad un mondo
   * @param worldId 
   * @returns 
   */
  avatarsGet(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<Avatar[]>(API_URL + "/api/game/avatars/get", body);
  }

  /**
   * Salva l'avatar scelto dall'utente
   * @param worldId 
   * @param code 
   * @returns 
   */
  avatarsSet(worldId: number, code: string) {

    const body = { "worldId": worldId, "code": code };

    return this.http.post<Avatar>(API_URL + "/api/game/avatars/set", body);
  }

  /**
   * Imposta ultima pagina visualizzata
   * 
   * @param lastPageTutorial 
   * @returns 
   */
  tutorialSet(lastPageTutorial: number) {

    const body = { "lastPageTutorial": lastPageTutorial };

    return this.http.post<any>(API_URL + "/api/game/games/setTutorial", body);
  }

  /**
   * Restituisce lo status della partita
   * @param worldId 
   * @returns 
   */
  gameStatus(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<{
      world: World;
      user_world: UserWorld;
      avatar: Avatar;
      count_pending: number;
    }>(API_URL + "/api/game/games/status", body);
  }

  /**
   * Effettua un tiro del dado
   * @param worldId 
   * @returns 
   */
  gameRoll(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<ResponseGameRoll>(API_URL + "/api/game/games/roll", body);
  }

  /**
   * Restituisce la lista delle giocate filtrate per status
   * @param status 
   * @returns 
   */
  gameList(status: string) {

    const body = { "status": status };

    return this.http.post<UserPlayed[]>(API_URL + "/api/game/games/list", body);
  }

  /**
   * Tiene traccia della fine di un minigioco
   * @param playId 
   * @param success
   * @returns 
   */
  gamePlayed(playId: string, success: boolean) {

    const body = { "playId": playId, "success": success };

    return this.http.post<ResponseGamePlayed>(API_URL + "/api/game/games/played", body);
  }

  /**
   * Restiuisce la lista dei contenuti editoriali
   * @param worldId 
   * @returns 
   */
  contentsList(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<EditorialContent[]>(API_URL + "/api/game/contents/list", body);
  }

  /**
   * Restiuisce la lista dei contenuti editoriali assegnati all'utente
   * @param worldId 
   * @returns 
   */
  contentsUserList(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<EditorialContent[]>(API_URL + "/api/game/contents/userList", body);
  }

  /**
   * Restituisce una domanda associata al mondo passato
   * @param worldId 
   * @returns 
   */
  quizzesQuestion(worldId: number) {

    const body = { "worldId": worldId };

    return this.http.post<QuizQuestion>(API_URL + "/api/game/quizzes/question", body);
  }

  /**
   * Registra la risposta di una domanda
   * @param questionId 
   * @param answer 
   * @returns 
   */
  quizzesAddAnswer(questionId: number, answer: number) {

    const body = { "questionId": questionId, "answer": answer };

    return this.http.post<QuizAnswer>(API_URL + "/api/game/quizzes/addAnswer", body);
  }
  
  /**
   * Restituisce il totale dei punti margherite verdi guadagnati
   * @returns 
   */
  sostenibilitaTotalPointsGet() {

    return this.http.post<Sustainability>(API_URL + "/api/game/games/user", null);
  }

  /**
   * Restituisce i dettagli di un singolo coupon
   * @param couponId 
   * @returns 
   */
  couponsView(couponId: string) {

    const body = { "couponId": couponId };

    return this.http.post<Coupon>(API_URL + "/api/game/coupons/view", body);
  }
}
