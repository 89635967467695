<!--
<div (click)="checkParam(2)">
    GIOCO 2: <strong *ngIf="params.twoEnabled == 1">SI</strong><strong *ngIf="params.twoEnabled == 0">NO</strong>
</div>

<div (click)="checkParam(3)">
    GIOCO 3: <strong *ngIf="params.threeEnabled == 1">SI</strong><strong *ngIf="params.threeEnabled == 0">NO</strong>
</div>

<div (click)="checkParam(4)">
    GIOCO 4: <strong *ngIf="params.fourEnabled == 1">SI</strong><strong *ngIf="params.fourEnabled == 0">NO</strong>
</div>

<div style="text-align: center;">

    <button (click)="onClickSave()">SALVA</button>

</div>
-->

<!-- inizio accessibilità -->
<div class="gioco_accessibilita">

    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Mini giochi</div>
            
            <div class="pop_default_descrizione">Lungo il percorso potresti trovare delle prove da superare.<br />Scegli le più adatte a te.</div>
            
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
                
                <div class="singolo_gioco_titolo">
                    <div class="singolo_gioco_toggle" (click)="checkParam(2)" [ngClass]="{'singolo_gioco_toggle_attivo': params.twoEnabled == 1}">
                        <div class="singolo_gioco_toggle_element"></div>
                    </div>
                    <h3 aria-label="Gioco 1 - Spegni lo spreco">Food Memory</h3>
                </div>
                
                <div class="singolo_gioco_descrizione">
                    Ci sono 16 caselline che nascondono 8 coppie di immagini.<br />
                    Il giocatore dovrà premere su ogni casella e ricordare la posizione per creare la coppia.
                    <br />
                    <a aria-hidden="true" [routerLink]="'/gioco-memory/0'" class="vedi_anteprima">Vedi anteprima</a>
                    <ng-container *ngIf="params.twoEnabled == 1">
                        <div class="nonvedente" (click)="checkParam(2)" role="button" >Vuoi disattivare il gioco?</div>
                    </ng-container>
                    <ng-container *ngIf="params.twoEnabled != 1">
                        <div class="nonvedente" (click)="checkParam(2)" role="button" >Vuoi attivare il gioco?</div>
                    </ng-container>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
                
                <div class="singolo_gioco_titolo">
                    <div class="singolo_gioco_toggle" (click)="checkParam(3)" [ngClass]="{'singolo_gioco_toggle_attivo': params.threeEnabled == 1}">
                        <div class="singolo_gioco_toggle_element"></div>
                    </div>
                    <h3 aria-label="Gioco 2 - Ripuliamo i mari">Piovono Ortaggi</h3>
                </div>
                
                <div class="singolo_gioco_descrizione">
                   L’obiettivo è quello di colpire gli ortaggi con una pallina, sfruttando il rimbalzo della pallina sulla barra che il giocatore potrà spostare verso destra o
                    sinistra con uno scorrimento del dito sullo schermo.<br />
                    Ad ogni collisione, gli ortaggi scompariranno.
                    <br />
                    <a aria-hidden="true" [routerLink]="'/gioco-brick-breaker/0'" class="vedi_anteprima">Vedi anteprima</a>
                    <ng-container *ngIf="params.threeEnabled == 1">
                        <div class="nonvedente" (click)="checkParam(3)" role="button" >Vuoi disattivare il gioco?</div>
                    </ng-container>
                    <ng-container *ngIf="params.threeEnabled != 1">
                        <div class="nonvedente" (click)="checkParam(3)" role="button" >Vuoi attivare il gioco?</div>
                    </ng-container>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
                
                <div class="singolo_gioco_titolo">
                    <div class="singolo_gioco_toggle" (click)="checkParam(4)" [ngClass]="{'singolo_gioco_toggle_attivo': params.fourEnabled == 1}">
                        <div class="singolo_gioco_toggle_element"></div>
                    </div>
                    <h3 aria-label="Gioco 3 - Insieme facciamo la differenza">Afferra la spesa</h3>
                </div>
                
                <div class="singolo_gioco_descrizione">
                    In questo gioco cadranno dall'alto una serie di prodotti con differenti velocità, l'obiettivo del giocatore sarà
                    quello di toccare 10 prodotti brandizzati evitando tutti gli altri.
                    <br />
                    <a aria-hidden="true" [routerLink]="'/gioco-clicca-prodotti/0'" class="vedi_anteprima">Vedi anteprima</a>
                    <ng-container *ngIf="params.fourEnabled == 1">
                        <div class="nonvedente" (click)="checkParam(4)" role="button" >Vuoi disattivare il gioco?</div>
                    </ng-container>
                    <ng-container *ngIf="params.fourEnabled != 1">
                        <div class="nonvedente" (click)="checkParam(4)" role="button" >Vuoi attivare il gioco?</div>
                    </ng-container>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <div class="tasto_principale next-slide" role="button" aria-label="Salva preferenze" (click)="onClickSave()">SALVA</div>
        </div>

    </div>
    
</div>
<!-- fine accessibilità -->