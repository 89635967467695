<app-header [showHeader]="true" [showBack]="true"></app-header>

<!-- inizio elenco giocate -->
<div class="elenco_giocate">
    
    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Elenco giocate</div>
            
            <div class="pop_default_descrizione">Tieni traccia dei tuoi progressi</div>
            
            <div class="contenitore_giocate">
            

                <!-- inizio singola giocata -->
                <div *ngFor="let item of items" class="singola_giocata">

                    <span>
                        {{item.resultTimestamp | slice:6:8}}/{{item.resultTimestamp | slice:4:6}}/{{item.resultTimestamp | slice:0:4}}
                    </span>

                    <div class="esito_singola_giocata">
                        <img *ngIf="item.resultType == 'coupon'" src="/assets/img/giocate_cupon.png" alt="giocata con coupon">
                        <img *ngIf="item.resultType == 'extra-roll'" src="/assets/img/giocate_dado.png" alt="giocata con tiro extra">
                        <!-- TODO: manca "editorial-content" e "sustainability-point" -->
                    </div>

                </div>
                <!-- fine singola giocata -->
                
            </div>
            
        </div>

    </div>
    
</div>
<!-- fine elenco giocate -->
