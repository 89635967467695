import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EditorialContent } from 'src/app/interfaces/content';

@Component({
  selector: 'app-contenuto-editoriale',
  templateUrl: './contenuto-editoriale.component.html',
  styleUrls: ['./contenuto-editoriale.component.css']
})
export class ContenutoEditorialeComponent implements OnInit {

  item: EditorialContent;

  constructor(
    private router: Router,
  ) { 

    this.item = this.router.getCurrentNavigation()?.extras.state?.['item'];

    if (typeof this.item == 'undefined') {

      this.router.navigate(['/elenco-editoriali']);
    }
  }

  ngOnInit(): void {
  }

}
