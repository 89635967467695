import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/alert/alert.service';
import { LocalService } from 'src/app/services/local.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Input() showBack!: boolean;
  @Input() showHeader!: boolean;

  // flag per stato menu
  menuOpen: boolean = false;

  constructor(
    public localService: LocalService,
    private alert: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // in ascolto per evento chiusura/apertura menu
    this.localService.changeStatusMenu.subscribe((data) => {
      this.menuOpen = data;
    });
  }

  // funzione per aprire menu
  openMenu() {
    // imposto menu aperto
    this.menuOpen = true;
    // invio evento menu aperto
    this.localService.changeStatusMenu.emit(true);
  }

  // funzione per chiudere menu
  closeMenu() {
    // imposto menu chiuso
    this.menuOpen = false;
    // invio evento menu chiuso
    this.localService.changeStatusMenu.emit(false);
  }

  // trigger allo swipe
  onSwipe(event: any) {
    // swipe destra
    if (event.direction == Hammer.DIRECTION_RIGHT) {
      // imposto menu chiuso
      this.menuOpen = false;
      // invio evento menu chiuso
      this.localService.changeStatusMenu.emit(false);
    }
  }

  // funzione per popup esci dal gioco
  async onClickExit() {
    // faccio comparire popup
    const res = await this.alert.presentConfirm(
      "Vuoi uscire dal gioco e tornare all'app Conad?",
      'ESCI',
      'ANNULLA'
    );

    if (res) {
      
      window.location.href = environment.gameUrl + '/exit';
    }
  }
}
