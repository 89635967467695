<app-header [showHeader]="true" [showBack]="true"></app-header>

<div class="bg_cielo"></div>

<!-- inizio mini giochi -->
<div class="mini_giochi">


    <!-- inizio singolo gioco -->
    <div class="pop_default pop_minigioco" *ngIf="items_1.length > 0">

        <div class="contenuto_principare_pop">
            
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_quiz.jpg" alt="Quiz">
                
                <div class="contatore">{{items_1.length}}</div>

                <div class="singolo_gioco_container_azioni tasto_solo_container">
                    <div *ngIf="items_1.length > 0" [routerLink]="'/gioco-quiz/' + items_1[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
        
        </div>

    </div>
    <!-- fine singolo gioco -->  

    <!-- inizio singolo gioco 
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_spegni_spreco.jpg" alt="Spegni lo spreco">
                
                <div class="contatore" *ngIf="items_2.length > 0">{{items_2.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_2.length == 0}">
                    <div [routerLink]="'/gioco-spegni-lo-spreco/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_2.length > 0" [routerLink]="'/gioco-spegni-lo-spreco/' + items_2[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
        
        </div>

    </div>-->
    <!-- fine singolo gioco -->  
    
    
    
    <!-- inizio singolo gioco 
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_mari.jpg" alt="Ripuliamo i mari">
                
                <div class="contatore" *ngIf="items_3.length > 0">{{items_3.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_3.length == 0}">
                    <div [routerLink]="'/gioco-squalo/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_3.length > 0" [routerLink]="'/gioco-squalo/' + items_3[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
            </div>
            
        </div>

    </div>-->
    <!-- fine singolo gioco -->
    
    
    
    <!-- inizio singolo gioco
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_differenziata.jpg" alt="Fare la differenziata">
                
                <div class="contatore" *ngIf="items_4.length > 0">{{items_4.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_4.length == 0}">
                    <div [routerLink]="'/gioco-raccolta-differenziata/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_4.length > 0" [routerLink]="'/gioco-raccolta-differenziata/' + items_4[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
            
        </div>

    </div>-->
    <!-- fine singolo gioco -->

    <!-- inizio singolo gioco -->
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_memory.jpg" alt="Memory">
                
                <div class="contatore" *ngIf="items_5.length > 0">{{items_5.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_5.length == 0}">
                    <div [routerLink]="'/gioco-memory/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_5.length > 0" [routerLink]="'/gioco-memory/' + items_5[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
        </div>

    </div>
    <!-- fine singolo gioco -->

    <!-- inizio singolo gioco -->
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_brick_breaker.jpg" alt="Brick Breaker">
                
                <div class="contatore" *ngIf="items_6.length > 0">{{items_6.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_6.length == 0}">
                    <div [routerLink]="'/gioco-brick-breaker/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_6.length > 0" [routerLink]="'/gioco-brick-breaker/' + items_6[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
        </div>

    </div>
    <!-- fine singolo gioco -->

    <!-- inizio singolo gioco -->
    <div class="pop_default pop_minigioco">

        <div class="contenuto_principare_pop">
            
            <!-- inizio singolo gioco -->
            <div class="singolo_gioco">
            
                <img class="anteprima_minigioco" src="/assets/img/mini_giochi/anteprima_clicca_prodotti.jpg" alt="Clicca Prodotti">
                
                <div class="contatore" *ngIf="items_7.length > 0">{{items_7.length}}</div>

                <div class="singolo_gioco_container_azioni" [ngClass]="{'tasto_solo_container': items_7.length == 0}">
                    <div [routerLink]="'/gioco-clicca-prodotti/0'" role="link" aria-label="Prova" class="tasto_principale tasto_principale_outline">PROVA</div>
                    <div *ngIf="items_7.length > 0" [routerLink]="'/gioco-clicca-prodotti/' + items_7[0].playId" role="link" aria-label="Gioca" class="tasto_principale">GIOCA</div>
                </div>
                
            </div>
            <!-- fine singolo gioco -->
            
        </div>

    </div>
    <!-- fine singolo gioco -->
    
</div>
<!-- fine mini giochi -->
