<header
  id="header_gioco"
  [ngClass]="{ nascondi_header: !showHeader }"
  aria-label="menu principale gioco"
  role="navigation"
>
  <svg viewBox="0 0 1107.3 236.1" class="base_header" aria-hidden="true">
    <path
      d="M555.4,0c0,0-3.4,0-3.5,0C545,0,0,0,0,0v136.5c0,0,13.8,87.2,99.1,87.2c31.2-3.1,254.6-10.1,454.5-10.2c199.9,0,423.3,7.1,454.5,10.2c85.3,0,99.1-87.2,99.1-87.2V0C1107.3,0,562.2,0,555.4,0z"
    />
  </svg>

  <div class="header_menu">
    <div
      class="tasto_vai_home"
      tabindex="0"
      id="header_bottone_esci_gioco"
      (click)="onClickExit()"
      role="button"
      aria-label="Chiudi il gioco e torna all'app Conad"
      data-toggle="modal"
      aria-controls="popup_esci_gioco"
    >
      <svg viewBox="0 0 27.9 31">
        <path
          d="M27.8,10.2c-0.1-0.2-0.2-0.4-0.5-0.5L14.9,0.2c-0.2-0.1-0.3-0.1-0.5-0.2C14.3,0,14.1,0,14,0c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.3,0.1-0.5,0.2L0.6,9.7C0.4,9.8,0.2,10,0.1,10.2C0,10.4,0,10.6,0,10.9v18.7c0,0.4,0.2,0.8,0.5,1s0.7,0.5,1,0.5h9.6v-6.6h5.7V31h9.6c0.4,0,0.8-0.2,1-0.5s0.5-0.6,0.5-1V10.9C27.9,10.6,27.9,10.4,27.8,10.2z"
        />
      </svg>
    </div>

    <div
      class="tasto_menu"
      tabindex="0"
      [routerLink]="'/scegli-avatar'"
      routerLinkActive="tasto_menu_attivo"
      role="button"
      aria-label="Scegli avatar"
    >
      SCEGLI AVATAR
    </div>

    <div
      class="tasto_menu"
      tabindex="0"
      [routerLink]="'/elenco-minigiochi'"
      routerLinkActive="tasto_menu_attivo"
      role="button"
      aria-label="Mini giochi"
    >
      MINI GIOCHI
    </div>

    <div
      class="tasto_apri_menu"
      role="button"
      aria-label="Apri il menu principale"
      (click)="openMenu()"
      *ngIf="!menuOpen && !showBack"
    >
      <svg viewBox="0 0 17.1 11" class="hamburger_menu">
        <path
          d="M16.1,2H1C0.4,2,0,1.5,0,1s0.4-1,1-1h15.1c0.5,0,1,0.4,1,1S16.7,2,16.1,2z"
        />
        <path
          d="M16.2,6.5H0.9C0.4,6.5,0,6.1,0,5.5s0.4-1,0.9-1h15.3c0.5,0,0.9,0.4,0.9,1S16.7,6.5,16.2,6.5z"
        />
        <path
          d="M16.2,11H0.9C0.4,11,0,10.6,0,10s0.4-1,0.9-1h15.3c0.5,0,0.9,0.4,0.9,1S16.7,11,16.2,11z"
        />
      </svg>
    </div>

    <div
      id="tasto_apri_menu_principale"
      class="tasto_apri_menu"
      tabindex="0"
      role="button"
      aria-label="Chiudi il menu principale"
      (click)="closeMenu()"
      *ngIf="menuOpen && !showBack"
    >
      <svg viewBox="0 0 24 24" class="close_menu">
        <path
          class="st0"
          d="M13.4,12l4.7-4.7C18.5,6.9,18.4,6.3,18,6s-1-0.4-1.3-0.1L12,10.6L7.3,5.9C6.9,5.5,6.3,5.6,6,6s-0.4,1-0.1,1.3l4.7,4.7l-4.7,4.7C5.5,17.1,5.6,17.7,6,18s1,0.4,1.3,0.1l4.7-4.7l4.7,4.7c0.3,0.3,0.9,0.3,1.3-0.1s0.4-1,0.1-1.3L13.4,12z"
        />
      </svg>
    </div>

    <div
      class="tasto_apri_menu"
      [routerLink]="'/home'"
      role="button"
      aria-label="Torna alla home del gioco"
      *ngIf="!menuOpen && showBack"
    >
      <svg viewBox="0 0 24 24" class="back_menu">
        <path
          d="M20.9,11.1c-0.2-0.3-0.5-0.4-0.9-0.4H7.1L11,6.8c0.3-0.3,0.4-0.6,0.4-0.9C11.5,5.5,11.3,5.2,11,5c-0.2-0.2-0.6-0.4-0.9-0.4C9.8,4.5,9.5,4.7,9.2,5l-6,6c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.3-0.4,0.5-0.4,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0.3,0.1,0.6,0.4,0.9L9.2,19c0.3,0.3,0.6,0.4,0.9,0.4c0.4,0.1,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9c0.1-0.4-0.1-0.6-0.4-0.9l-3.9-3.9H20c0.3,0,0.6-0.1,0.9-0.4c0.3-0.2,0.4-0.6,0.4-0.9C21.3,11.7,21.2,11.4,20.9,11.1z"
        />
      </svg>
    </div>
  </div>
</header>

<!-- inizio main menu -->
<ng-container *ngIf="menuOpen">
  <div
    class="main_menu"
    id="popup_esci_gioco"
    [ngClass]="{ main_menu_aperto: menuOpen }"
    role="dialog"
    tabindex="-1"
    [attr.aria-hidden]="!menuOpen ? 'true' : 'false'"
    [attr.aria-modal]="menuOpen ? 'true' : null"
    (swipe)="onSwipe($event)"
  >
    <!--<div class="nonvedente" (click)="closeMenu()" aria-label="chiudi menu principale" role="button" aria-controls="tasto_apri_menu_principale">Chiudi menu principale</div>-->

    <ul>
      <li class="nonvedente">
        <a>
          <div
            (click)="closeMenu()"
            aria-label="chiudi menu principale"
            role="button"
            aria-controls="tasto_apri_menu_principale"
          >
            Chiudi menu principale
          </div>
        </a>
      </li>
      <li>
        <a [routerLink]="'/tutorial'" aria-label="Apri il tutorial" role="link">
          <img
            src="/assets/img/menu_icona_info.svg"
            alt=""
            aria-hidden="true" />
          <span>Tutorial</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
      <li>
        <a
          aria-label="I tuoi punti margherite verdi"
          role="link"
          [routerLink]="'/punti-sostenibilita'"
        >
          <img
            src="/assets/img/menu_icona_sostenibilita.svg"
            alt=""
            aria-hidden="true" />
          <span>Punti Margherite verdi</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
      <li>
        <a
          aria-label="Le tue giocate"
          role="link"
          [routerLink]="'/le-tue-giocate'"
        >
          <img
            src="/assets/img/menu_icona_giocate.svg"
            alt=""
            aria-hidden="true" />
          <span>Le tue giocate</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
      <li>
        <a
          aria-label="Le tue giocate"
          role="link"
          [routerLink]="'/elenco-editoriali'"
        >
          <img
            src="/assets/img/menu_icona_contenuti.svg"
            alt=""
            aria-hidden="true" />
          <span>Contenuti speciali</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
      <li>
        <a
          aria-label="Hai delle domande?"
          role="link"
          [routerLink]="'/aiuto-faq'"
        >
          <img
            src="/assets/img/menu_icona_assistenza.svg"
            alt=""
            aria-hidden="true" />
          <span>Assistenza</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
      <li>
        <a
          aria-label="Scegli i giochi ai quali vorresti giocare"
          role="link"
          [routerLink]="'/accessibilita'"
        >
          <img
            src="/assets/img/menu_icona_accessibilita.svg"
            alt=""
            aria-hidden="true" />
          <span>Accessibilità</span>
          <img
            src="/assets/img/menu_icona_freccia.svg"
            alt=""
            aria-hidden="true"
        /></a>
      </li>
    </ul>

    <div
      class="tasto_esci"
      role="none"
      aria-hidden="true"
      (click)="onClickExit()"
    >
      <svg viewBox="0 0 48 48">
        <path
          d="M32.2,31.6c-0.3-0.3-0.5-0.7-0.5-1.1c0-0.4,0.1-0.8,0.5-1l4-4h-16c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1s0.1-0.8,0.4-1.1c0.3-0.3,0.6-0.4,1.1-0.4h15.9l-4-4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.8,0.4-1.1c0.3-0.3,0.7-0.4,1.1-0.4c0.4,0,0.8,0.2,1.1,0.4L41,23c0.2,0.2,0.3,0.3,0.3,0.5s0.1,0.4,0.1,0.5c0,0.2,0,0.4-0.1,0.5s-0.2,0.3-0.3,0.5l-6.6,6.6c-0.3,0.3-0.6,0.4-1,0.4C32.9,32.1,32.5,31.9,32.2,31.6z M9,42c-0.8,0-1.5-0.3-2.1-0.9C6.3,40.5,6,39.8,6,39V9c0-0.8,0.3-1.5,0.9-2.1S8.2,6,9,6h13c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1C22.8,8.9,22.5,9,22,9H9v30h13c0.4,0,0.8,0.1,1.1,0.4c0.3,0.3,0.4,0.6,0.4,1.1c0,0.4-0.1,0.8-0.4,1.1S22.5,42,22,42H9z"
        />
      </svg>
      <strong>Esci dal gioco</strong>
    </div>
  </div>
  <!-- fine main menu -->
</ng-container>
