import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading$: Subject<{isActive: boolean}>;

  private count: number = 0;

  constructor() {

    this.loading$ = new Subject();
  }

  /**
   * Visualizza il loading
   */
  present() {

    this.count++;

    this.loading$.next({isActive: true});
  }

  /**
   * Nasconde il loading
   */
  dismiss() {

    if (this.count > 0) {

      this.count--;
    
    } else {

      this.count = 0;
    }

    this.loading$.next({isActive: (this.count == 0) ? false : true});
  }
}
