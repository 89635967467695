import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { EditorialContent } from 'src/app/interfaces/content';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-elenco-editoriali',
  templateUrl: './elenco-editoriali.component.html',
  styleUrls: ['./elenco-editoriali.component.css']
})
export class ElencoEditorialiComponent implements OnInit {

  items: EditorialContent[] = [];

  showEmptyMessage: boolean = false;

  constructor(
    public appService: AppService,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    this.retrieveContents();
  }

  async retrieveContents()
  {
    if (!this.appService.authUser) return;

    this.loading.present();

    try {
      
      this.items = await lastValueFrom(this.appService.contentsUserList(this.appService.authUser.info.worldId));

      if (this.items.length == 0) {

        this.showEmptyMessage = true;
      }

    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

  onClickContent(item: EditorialContent) {

    this.router.navigateByUrl('/contenuto-editoriale', {
      state: {
        item: item
      }
    });

  }
}
