<!-- inizio contenitore intro -->
<div class="contenitore_intro">

    <div class="grafiche_intro">
    
        <img class="composizione_avatar" src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/intro_avatar.png">
        
    </div>


    <div class="azioni_pop">
        
        <div [routerLink]="'/tutorial'" class="tasto_principale next-slide" role="button" aria-label="Vai alla prossima slide">VAI</div>
        
        <a class="tasto_secondario" [routerLink]="'/accessibilita'" role="link" aria-label="Salta il tutorial e vai alla home del gioco">

            <svg viewBox="0 0 48 48">
                <path d="M24,12.6c-0.9,0-1.7-0.3-2.3-1c-0.6-0.6-1-1.4-1-2.3c0-0.9,0.3-1.7,1-2.3s1.4-1,2.3-1s1.7,0.3,2.3,1c0.6,0.6,1,1.4,1,2.3c0,0.9-0.3,1.7-1,2.3C25.7,12.2,24.9,12.6,24,12.6z M19.2,42V18c-2-0.2-4-0.4-5.9-0.6c-1.9-0.3-3.7-0.6-5.5-1l0.7-2.7c2.6,0.6,5.1,1,7.6,1.3s5.2,0.4,7.9,0.4c2.8,0,5.4-0.1,7.9-0.4c2.5-0.3,5.1-0.7,7.6-1.3l0.7,2.7c-1.7,0.4-3.6,0.8-5.5,1c-1.9,0.3-3.9,0.5-5.9,0.6v24h-2.7V30.3h-4.2V42H19.2z"/>
                <path d="M24,1.4c12.5,0,22.6,10.1,22.6,22.6S36.5,46.6,24,46.6S1.4,36.5,1.4,24S11.5,1.4,24,1.4 M24,0C10.7,0,0,10.7,0,24s10.7,24,24,24s24-10.7,24-24S37.3,0,24,0L24,0z"/>
            </svg>
            
            <span>Accesso facilitato</span>
            
        </a>
    </div>
     
</div>
<!-- fine contenitore intro -->
