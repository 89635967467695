import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { Coupon } from 'src/app/interfaces/coupon';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-coupon-preview',
  templateUrl: './coupon-preview.component.html',
  styleUrls: ['./coupon-preview.component.css']
})
export class CouponPreviewComponent implements OnInit {

  couponId: string = "";

  coupon: Coupon | null = null;

  constructor(
    private route: ActivatedRoute,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
  ) { }

  ngOnInit(): void {

    this.couponId = this.route.snapshot.paramMap.get('couponId')!;

    this.retrieveCoupon();
  }

  async retrieveCoupon() {

    this.loading.present();

    try {

      this.coupon = await lastValueFrom(this.appService.couponsView(this.couponId));
      
    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

}
