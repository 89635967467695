import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlertService } from 'src/app/alert/alert.service';
import { AppService } from 'src/app/services/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BRAND_LOGO_BARILLA } from 'src/app/interfaces/brand';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { LoadingService } from 'src/app/loading/loading.service';
import { lastValueFrom } from 'rxjs';
import { LocalService } from 'src/app/services/local.service';

export const IMG_BLOCCO_1 = "/assets/img/gioco_brick_breaker/frutto_prova.png";
export const IMG_BLOCCO_2 = "/assets/img/gioco_brick_breaker/frutto_2.png";
export const IMG_BLOCCO_3 = "/assets/img/gioco_brick_breaker/frutto_3.png";
export const IMG_BLOCCO_4 = "/assets/img/gioco_brick_breaker/frutto_4.png";
export const IMG_BLOCCO_5 = "/assets/img/gioco_brick_breaker/frutto_5.png";
export const IMG_BARRA = "/assets/img/gioco_brick_breaker/barra_gioco_brick.png";
export const IMG_PALLA = "/assets/img/gioco_brick_breaker/pallina_gioco_brick.png";
export const BACKGROUND = "/assets/img/gioco_memory/gioco_trasparenza_2.png";

@Component({
  selector: 'app-gioco-brick-breaker',
  templateUrl: './gioco-brick-breaker.component.html',
  styleUrls: ['./gioco-brick-breaker.component.css']
})
export class GiocoBrickBreakerComponent implements OnInit,AfterViewInit {

  @ViewChild('canvasEl') canvas: ElementRef | undefined;

  private context: CanvasRenderingContext2D | undefined | null;

  KEY_TUTORIAL_PIOVONO_ORTAGGI: string = "tutorial_piovono_ortaggi";

  brickW: number = Math.round(window.innerWidth/7);
  brickH: number = Math.round(window.innerHeight/21);
  brickGap: number = 7;
  columns: number = 7;
  rows: number = 6;
  brickCount: number = 0;

  ballRadius: number = 8;
  ballX: number = 0;
  ballY: number = 0;
  ballSpeedX: number = 0;
  ballSpeedY: number = 0;

  paddleThickness: number = 15;
  paddleWidth: number = 100;
  paddleX: number = window.innerWidth/2 - this.paddleWidth/2;
  paddleDistance: number = 30;

  mouseX: number = 0;
  mouseY: number = 0;

  playId: string = "0";
  brandLogoUrl: string = "";
  lives: number = 3;
  remainingLives: number = 3;

  gameActive: boolean = false;
  showPopupConclusione: boolean = false;
  minipillola: Minipillola | null = null;
  casual: number = 0;
  
  brickGrid = new Array(this.columns*this.rows);

  popupTutorial: boolean = true;

  constructor(
    private alert: AlertService,
    private loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private local: LocalService,
  ) { }

  ngOnInit(): void {

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log('playId: ' + this.playId);
    
    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {
      this.brandLogoUrl = BRAND_LOGO_BARILLA;
    }else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.BRICK_BREAKER);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }
    
    this.casual = Math.floor(Math.random() * (3 - 1 + 1) + 1);
    
    var tutorialAlreadyShown = this.local.getData(this.KEY_TUTORIAL_PIOVONO_ORTAGGI);

    if(tutorialAlreadyShown == '1' && this.playId != "0") {
      this.popupTutorial = false;
      this.gameActive = true;
    }
  }

  ngAfterViewInit() {

    this.context = (
      this.canvas?.nativeElement as HTMLCanvasElement
    ).getContext('2d');

    if(this.canvas) {
      (this.canvas.nativeElement as HTMLCanvasElement).width = window.innerWidth;
      (this.canvas.nativeElement as HTMLCanvasElement).height = window.innerHeight - 300;
    }

    this.brickReset();
    this.ballRest();
    this.updateAll();
  }

  updateMousePos(evt: any) {

    if(!this.ballSpeedX && !this.ballSpeedY) {
      this.ballSpeedX = 2;
      this.ballSpeedY = -4;
    }

    var rect = (this.canvas?.nativeElement as HTMLCanvasElement).getBoundingClientRect();
    var root = document.documentElement;
  
    if(typeof evt.center != 'undefined') {
      var x = evt.center.x;
      var y = evt.center.y;
    } else {
      var x = evt.x;
      var y = evt.y;
    }

    this.mouseX = x - rect.left - root.scrollLeft;
    this.mouseY = y - rect.top - root.scrollTop;
    
    if(this.mouseX - this.paddleWidth/2 >= - this.paddleWidth/3 && (this.mouseX + this.paddleWidth/2) <= (window.innerWidth + this.paddleWidth/3)) {
      this.paddleX = x - this.paddleWidth/2;
    } else if(this.mouseX + this.paddleWidth/2 > (window.innerWidth + this.paddleWidth/3)) {
      this.paddleX = window.innerWidth - this.paddleWidth + this.paddleWidth/3;
    } else if(this.mouseX - this.paddleWidth/2 < - this.paddleWidth/3) {
      this.paddleX = - this.paddleWidth/3;
    }
  
    //cheat to test ball in any position
    // ballX = mouseX;
    // ballY = mouseY;
    // ballSpeedY = 4;
    // ballSpeedY = -4;
  }

  async updateAll() {

    if(this.gameActive) {
      this.movement();
      this.playArea();
      await this.delay(20);
      this.updateAll();
    }
  }

  movement() {
    this.ballMove();
    if(this.ballY <= this.brickH*7) {
      this.ballBrickColl();
    }
    this.paddleMove();
  }

  ballMove(){
    // ballMovement
    this.ballX += this.ballSpeedX;
    this.ballY += this.ballSpeedY;
    // ballY
    
    if(this.ballY - this.ballRadius > (this.canvas?.nativeElement as HTMLCanvasElement).height){
      //this.ballSpeedY = -this.ballSpeedY;
      this.ballRest();
      this.remainingLives--;

      if(this.remainingLives == 0) {
        this.brickReset();
        this.remainingLives = 3;
      }
      //this.brickReset();
    } else if(this.ballY - this.ballRadius < 0 && this.ballSpeedY < 0.0){
      this.ballSpeedY = -this.ballSpeedY;
    }
    // ballx
    if(this.ballX > (this.canvas?.nativeElement as HTMLCanvasElement).width - this.ballRadius && this.ballSpeedX > 0.0){
      this.ballSpeedX = -this.ballSpeedX;
    } else if(this.ballX - this.ballRadius < 0 && this.ballSpeedX < 0.0){
      this.ballSpeedX = -this.ballSpeedX;
    }
  }

  async ballBrickColl(){

    if(this.ballSpeedY > 0) {
        if(this.ballSpeedX >= 0) {
          var ballBrickX = Math.floor((this.ballX + this.ballRadius) / this.brickW);
          var ballBrickX2 = Math.floor((this.ballX - this.ballRadius) / this.brickW);
          var ballBrickY = Math.floor((this.ballY + this.ballRadius) / this.brickH);
          var ballBrickY2 = Math.floor((this.ballY - this.ballRadius) / this.brickH);
  
          var brick = this.rowColToArrayIndex(ballBrickX, ballBrickY);
          var brick2 = this.rowColToArrayIndex(ballBrickX, ballBrickY2);
          var brick3 = this.rowColToArrayIndex(ballBrickX2, ballBrickY);
      
          if (ballBrickX >= 0 && ballBrickX < this.columns && ballBrickY >= 0 && (ballBrickY < this.rows || ballBrickY2 < this.rows)){
            if (this.isBrickAtColRow(ballBrickX, ballBrickY) && (((((this.ballY + this.ballRadius) / this.brickH) - Math.floor((this.ballY + this.ballRadius) / this.brickH)) >= 0.82 || (((this.ballX + this.ballRadius) / this.brickW) - Math.floor((this.ballX + this.ballRadius) / this.brickW)) >= 0.82 || !this.isBrickAtColRow(ballBrickX, ballBrickY2)))) {
              this.brickGrid[brick] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
              
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
      
            } else if(this.isBrickAtColRow(ballBrickX, ballBrickY2)) {
              this.brickGrid[brick2] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            } else if(this.isBrickAtColRow(ballBrickX2, ballBrickY)) {
              this.brickGrid[brick3] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX2, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX2){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY){
                if (this.isBrickAtColRow(ballBrickX2, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX2, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            }
          }
        } else if(this.ballSpeedX < 0) {
          var ballBrickX = Math.floor((this.ballX - this.ballRadius) / this.brickW);
          var ballBrickY = Math.floor((this.ballY + this.ballRadius) / this.brickH);
          var ballBrickY2 = Math.floor((this.ballY - this.ballRadius) / this.brickH);
  
          var brick = this.rowColToArrayIndex(ballBrickX, ballBrickY);
          var brick2 = this.rowColToArrayIndex(ballBrickX, ballBrickY2);
      
          if (ballBrickX >= 0 && ballBrickX < this.columns && ballBrickY >= 0 && (ballBrickY < this.rows || ballBrickY2 < this.rows)){
            if (this.isBrickAtColRow(ballBrickX, ballBrickY) && (((((this.ballY - this.ballRadius) / this.brickH) - Math.floor((this.ballY - this.ballRadius) / this.brickH)) >= 0.82 || (((this.ballX + this.ballRadius) / this.brickW) - Math.floor((this.ballX + this.ballRadius) / this.brickW)) >= 0.82 || !this.isBrickAtColRow(ballBrickX, ballBrickY2)))) {
              this.brickGrid[brick] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
              
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
      
            } else if(this.isBrickAtColRow(ballBrickX, ballBrickY2)) {
              this.brickGrid[brick2] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            }
          }
        }
    } else {
        if(this.ballSpeedX >= 0) {
          var ballBrickX = Math.floor((this.ballX + this.ballRadius) / this.brickW);
          var ballBrickX2 = Math.floor((this.ballX - this.ballRadius) / this.brickW);
          var ballBrickY = Math.floor((this.ballY + this.ballRadius) / this.brickH);
          var ballBrickY2 = Math.floor((this.ballY - this.ballRadius) / this.brickH);
  
          var brick = this.rowColToArrayIndex(ballBrickX, ballBrickY);
          var brick2 = this.rowColToArrayIndex(ballBrickX, ballBrickY2);
          var brick3 = this.rowColToArrayIndex(ballBrickX2, ballBrickY2);
      
          if (ballBrickX >= 0 && ballBrickX < this.columns && ballBrickY >= 0 && (ballBrickY < this.rows || ballBrickY2 < this.rows)){
            if (this.isBrickAtColRow(ballBrickX, ballBrickY) && (((((this.ballY - this.ballRadius) / this.brickH) - Math.floor((this.ballY - this.ballRadius) / this.brickH)) >= 0.82 || (((this.ballX + this.ballRadius) / this.brickW) - Math.floor((this.ballX + this.ballRadius) / this.brickW)) >= 0.82 || !this.isBrickAtColRow(ballBrickX, ballBrickY2)))) {
              this.brickGrid[brick] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
              
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }

              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
      
            } else if(this.isBrickAtColRow(ballBrickX, ballBrickY2) && (((((this.ballX - this.ballRadius) / this.brickW) - Math.floor((this.ballX - this.ballRadius) / this.brickW)) >= 0.82 || (((this.ballY + this.ballRadius) / this.brickH) - Math.floor((this.ballY + this.ballRadius) / this.brickH)) >= 0.82 || !this.isBrickAtColRow(ballBrickX2, ballBrickY2)))) {
              this.brickGrid[brick2] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            } else if(this.isBrickAtColRow(ballBrickX2, ballBrickY2)) {
              this.brickGrid[brick3] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX2, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX2){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX2, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX2, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            }
          }
        } else if(this.ballSpeedX < 0) {
          var ballBrickX = Math.floor((this.ballX + this.ballRadius) / this.brickW);
          var ballBrickX2 = Math.floor((this.ballX - this.ballRadius) / this.brickW);
          var ballBrickY = Math.floor((this.ballY + this.ballRadius) / this.brickH);
          var ballBrickY2 = Math.floor((this.ballY - this.ballRadius) / this.brickH);
  
          var brick = this.rowColToArrayIndex(ballBrickX, ballBrickY);
          var brick2 = this.rowColToArrayIndex(ballBrickX, ballBrickY2);
          var brick3 = this.rowColToArrayIndex(ballBrickX2, ballBrickY2);
          var brick4 = this.rowColToArrayIndex(ballBrickX2, ballBrickY);
      
          if (ballBrickX >= 0 && ballBrickX < this.columns && ballBrickY >= 0 && (ballBrickY < this.rows || ballBrickY2 < this.rows)){
            if (this.isBrickAtColRow(ballBrickX, ballBrickY) && (((((this.ballY - this.ballRadius) / this.brickH) - Math.floor((this.ballY - this.ballRadius) / this.brickH)) >= 0.82 || (((this.ballX + this.ballRadius) / this.brickW) - Math.floor((this.ballX + this.ballRadius) / this.brickW)) >= 0.82 || !this.isBrickAtColRow(ballBrickX, ballBrickY2)))) {
              this.brickGrid[brick] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
              
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
      
            } else if(this.isBrickAtColRow(ballBrickX, ballBrickY2) && (((((this.ballX - this.ballRadius) / this.brickW) - Math.floor((this.ballX - this.ballRadius) / this.brickW)) <= 0.82 || (((this.ballY + this.ballRadius) / this.brickH) - Math.floor((this.ballY + this.ballRadius) / this.brickH)) <= 0.82 || !this.isBrickAtColRow(ballBrickX2, ballBrickY2)))) {
              this.brickGrid[brick2] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2)  || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            } else if(this.isBrickAtColRow(ballBrickX2, ballBrickY2) && (((((this.ballX + this.ballRadius) / this.brickW) - Math.floor((this.ballX + this.ballRadius) / this.brickW)) >= 0.82 || (((this.ballY + this.ballRadius) / this.brickH) - Math.floor((this.ballY + this.ballRadius) / this.brickH)) <= 0.82 || !this.isBrickAtColRow(ballBrickX2, ballBrickY)))) {
              this.brickGrid[brick3] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX2, this.brickH*ballBrickY2, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
              
              if(prevBrickCol != ballBrickX2 && this.ballSpeedX != 0){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX2, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX2, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            } else if(this.isBrickAtColRow(ballBrickX2, ballBrickY)) {
              this.brickGrid[brick4] = false;
              this.brickCount--;
  
              this.context?.clearRect(this.brickW*ballBrickX2, this.brickH*ballBrickY, this.brickW-this.brickGap, this.brickH-this.brickGap);
      
              var prevBallX = this.ballX - this.ballSpeedX;
              var prevBallY = this.ballY - this.ballSpeedY;
              var prevBrickCol = Math.floor(prevBallX / this.brickW);
              var prevBrickRow = Math.floor(prevBallY / this.brickH);
      
              var bothTestFailed = true;
        
              if(prevBrickCol != ballBrickX2){
                if(this.isBrickAtColRow(prevBrickCol, ballBrickY2) == false || typeof this.isBrickAtColRow(prevBrickCol, ballBrickY2) === 'undefined'){
                  this.ballSpeedX = -this.ballSpeedX;
                  bothTestFailed = false;
                }
              }
        
              if(prevBrickRow != ballBrickY && prevBrickRow != ballBrickY2){
                if (this.isBrickAtColRow(ballBrickX2, prevBrickRow) == false || typeof this.isBrickAtColRow(ballBrickX2, prevBrickRow) === 'undefined'){
                  this.ballSpeedY = -this.ballSpeedY;
                  bothTestFailed = false;
                }
              }
        
              if(bothTestFailed){
                this.ballSpeedX = this.ballSpeedX;
                this.ballSpeedY = -this.ballSpeedY;
              }
            }
          }
        }
    }
    
    if(this.brickCount == 0) {
      this.gameActive = false;

      if (this.playId != null && this.playId != '0') {
        await this.setRewardPlayed();
      } else {
        // mostro subito popup fine gioco
        this.showPopupConclusione = true;
      }

    }
  }

  paddleMove(){
    // paddle
    var paddleTopEdgeY = (this.canvas?.nativeElement as HTMLCanvasElement).height-this.paddleDistance;
    var paddleBottomEdgeY = paddleTopEdgeY+this.paddleThickness;
    var paddleLeftEdgeX = this.paddleX;
    var paddleRightEdgeX = this.paddleX+this.paddleWidth;
    if(this.ballY > paddleTopEdgeY - this.ballRadius && // top of paddle
        this.ballY < paddleBottomEdgeY - this.ballRadius && // bottom of paddle
        this.ballX > paddleLeftEdgeX - this.ballRadius && // left half of paddle
        this.ballX < paddleRightEdgeX + this.ballRadius // right half of paddle
        ){
  
          this.ballSpeedY = -this.ballSpeedY;
  
      var paddleCenterX = this.paddleX + this.paddleWidth/2;
      var ballDistFromCenterX = this.ballX - paddleCenterX;
      if((ballDistFromCenterX * 0.35) > 0) {
        this.ballSpeedX = ((ballDistFromCenterX * 0.35) >= 8)?8:(ballDistFromCenterX * 0.35);
      } else {
        this.ballSpeedX = ((ballDistFromCenterX * 0.35) >= -8)?(ballDistFromCenterX * 0.35):-8;
      }

      if (this.brickCount == 0) {
        this.brickReset();
      }
  
    }
  }

  playArea(){
    
    if(this.context) {

      this.context.clearRect(0, 0, (this.canvas?.nativeElement as HTMLCanvasElement).width, (this.canvas?.nativeElement as HTMLCanvasElement).height);
      this.colorCircle();
      
      var base_image = new Image();
      base_image.src = IMG_BARRA;
      this.context?.drawImage(base_image, this.paddleX, (this.canvas?.nativeElement as HTMLCanvasElement).height - this.paddleDistance, this.paddleWidth, this.paddleThickness);

      //this.context.fillStyle = 'black';
      //this.context.fillRect(this.paddleX, (this.canvas?.nativeElement as HTMLCanvasElement).height - this.paddleDistance, this.paddleWidth, this.paddleThickness);
    }
  
    this.drawbricks();
  }

  ballRest(){
    this.ballSpeedX = 0;
    this.ballSpeedY = 0;
    this.ballX = this.paddleX + this.paddleWidth/2;
    this.ballY = (this.canvas?.nativeElement as HTMLCanvasElement).height - this.paddleDistance - this.paddleThickness/2;
  }

  brickReset(){
    this.brickCount = 0;
    var i;
    for (i = 0; i < 1 * this.columns; i++) {
      this.brickGrid[i] = false;
    }
    
    switch (this.casual) {
      case 1:
        for (; i<this.columns*this.rows; i++) {
          if(![7,13,14,20,21,27,28,34,35,41].includes(i)) {
            this.brickGrid[i] = true;
            this.brickCount++;
          }
        }
        break;
      case 2:
        for (; i<this.columns*this.rows; i++) {
          if(![7,9,10,11,13,17,31,35,37,38,39,41].includes(i)) {
            this.brickGrid[i] = true;
            this.brickCount++;
          }
        }
        break;
      case 3:
        for (; i<this.columns*this.rows; i++) {
          if(![7,9,11,13,15,19,21,27,29,33,35,37,39,41].includes(i)) {
            this.brickGrid[i] = true;
            this.brickCount++;
          }
        }
        break;
    }
  }

  rowColToArrayIndex(col: number, row: number){
    return col + this.columns * row;
  }

  isBrickAtColRow(col: number, row: number){
    if (col >= 0 && col < this.columns &&
        row >= 0 && row < this.rows) {
      var brickIndexUnderCoord = this.rowColToArrayIndex(col, row);
      return this.brickGrid[brickIndexUnderCoord];
    } else{
      return false;
    }
  }

  drawbricks(){
    for (var eachRow=0; eachRow<this.rows; eachRow++) {
      for(var eachCol=0; eachCol<this.columns; eachCol++){
        var arrayIndex = this.rowColToArrayIndex(eachCol, eachRow);
        if(this.brickGrid[arrayIndex]){
          if(this.context) {

            var base_image = new Image();
            base_image.src = "/assets/img/gioco_brick_breaker/frutto_"+(eachRow)+".png";
            this.context?.drawImage(base_image, this.brickW*eachCol, this.brickH*eachRow, this.brickW-this.brickGap, this.brickH-this.brickGap);

          }
        }//   if brick
      }// each brick
    }// each brickrow
  }// drawbricks

  colorCircle() {
    
    if(this.context) {

      var base_image = new Image();
      base_image.src = IMG_PALLA;
      this.context?.drawImage(base_image, this.ballX -  this.ballRadius, this.ballY -  this.ballRadius, this.ballRadius*2, this.ballRadius*2);
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // funzione per popup chiudi il gioco
  async clickClose() {
    // disattivo momentaneamente il gioco
    this.gameActive = false;

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm(
      'Sicuro di voler uscire?',
      'CONFERMO',
      'ANNULLA'
    );

    // se utente conferma
    if (response) {
      // ritorno home page
      this.router.navigate(['/home']);
    } else {
      // riattivo gioco
      this.gameActive = true;
      this.startGame();
    }
  }

  // funzione per inizio gioco
  startGame() {

    // check su collisioni
    this.updateAll();
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {
    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.gamePlayed(this.playId, true)
      );

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupConclusione = true;
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setRewardPlayed();
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  // funzione per chiudere tutorial
  closeTutorial() {
    // nascondo popup
    this.popupTutorial = false;

    this.local.saveData(this.KEY_TUTORIAL_PIOVONO_ORTAGGI, '1');

    this.gameActive = true;
    this.startGame();
  }
}
