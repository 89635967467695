import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aiuto-faq',
  templateUrl: './aiuto-faq.component.html',
  styleUrls: ['./aiuto-faq.component.css']
})
export class AiutoFaqComponent implements OnInit {

  // condizione per aprire e chiudere accordion domande
  showAnswers: Array<boolean> = [];

  constructor() { }

  ngOnInit(): void {
  }

  // funzione per aprire accordion domanda
  openAnswer(index: number) {

    if(this.showAnswers[index]) {
      this.showAnswers[index] = false;
    } else {
      this.showAnswers[index] = true;
    }
  }

}
