import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-nuovo-mondo',
  templateUrl: './intro-nuovo-mondo.component.html',
  styleUrls: ['./intro-nuovo-mondo.component.css']
})
export class IntroNuovoMondoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
