<!-- inizio header -->
<header *ngIf="!popupTutorial">

    <div class="base_brand" *ngIf="playId != '0' && brandLogoUrl != ''">
        <div class="contenitore_brand">
            <img [src]="brandLogoUrl"  alt="">
        </div>
        <svg viewBox="0 0 308 236.1">
            <path d="M0,131.5c0,3.8,0.4,7.6,1.4,11.3c5.1,19.8,26,79.4,95.2,80.8c1.6,0,3.2,0,4.8-0.2c18.8-1.6,80.2-3.9,160.9-6c25.4-0.6,45.7-21.4,45.7-46.8V46.8C308,21,287,0,261.1,0H0V131.5z"/>
        </svg>
    </div>

    <svg viewBox="0 0 1107.3 236.1" class="base_header">
        <path d="M555.4,0c0,0-3.4,0-3.5,0C545,0,0,0,0,0v136.5c0,0,13.8,87.2,99.1,87.2c31.2-3.1,254.6-10.1,454.5-10.2c199.9,0,423.3,7.1,454.5,10.2c85.3,0,99.1-87.2,99.1-87.2V0C1107.3,0,562.2,0,555.4,0z"/>
    </svg>

    <div class="header_menu" [ngClass]="{'header_menu_demo': playId == '0' || brandLogoUrl == ''}">

        <div class="contatori_gioco">
            <div class="contatori_pallini">
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 1}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 2}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 3}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 4}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 5}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 6}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 7}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 8}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 9}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': score >= 10}"></div>
            </div>
            <div class="contatori_gioco_risultato">Prodotti raccolti: <strong>{{score}} / 10</strong></div>
        </div>

        <div class="tasto_apri_menu" role="button" aria-label="Chiudi il gioco">
            <svg viewBox="0 0 24 24" class="close_menu" (click)="clickClose()">
                <path class="st0" d="M13.4,12l4.7-4.7C18.5,6.9,18.4,6.3,18,6s-1-0.4-1.3-0.1L12,10.6L7.3,5.9C6.9,5.5,6.3,5.6,6,6s-0.4,1-0.1,1.3l4.7,4.7l-4.7,4.7C5.5,17.1,5.6,17.7,6,18s1,0.4,1.3,0.1l4.7-4.7l4.7,4.7c0.3,0.3,0.9,0.3,1.3-0.1s0.4-1,0.1-1.3L13.4,12z"/>
            </svg>
        </div>

    </div>

</header>
<!-- fine header -->


<div class="game" id="game">
    <!--
    <div class="counter" id= "counter"></div>
    <div class="score">
      {{score}}
    </div>
    -->
</div>


<div class="bg_game"></div>

<div class="bg_lose" *ngIf="showError"></div>

<!-- inizio pop gioco finito -->
<div class="pop_gioco_finito pop_gioco_finito_visibile" *ngIf="showPopupConclusione">
    
    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Hai superato la prova</div>

            <div *ngIf="playId != '0'" class="pop_default_descrizione">
                Hai ottenuto una Margherita Verde e tiro di dado extra
            </div>

            <div class="pop_fine_gioco_immagine" style="margin-top: 20px;">
                <img [src]="appService.authUser?.avatar?.greatings" aria-label="Fantastico, gioco completato" alt="" >
            </div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <a class="tasto_principale" [routerLink]="'/home'" role="link" aria-label="Continua">CONTINUA</a>
        </div>

    </div>
    
    <div class="pop_gioco_finito_bg"></div>
    
</div>
<!-- fine pop gioco finito -->

<!-- inizio pop gioco tutorial -->
<div class="gioco_tutorial" *ngIf="popupTutorial">

    <div class="pop_default">

        <div class="contenuto_principare_pop">
            
            <!-- inizio pillola -->
            <div class="popup_gioco_pillola" *ngIf="minipillola != null">
                
                <div class="sponsorizzazione">
                    Sponsorizzato da:
                </div>

                <div class="popup_gioco_pillola_brand">
                    <img [src]="brandLogoUrl" aria-hidden="true" alt="">
                </div>

                <ng-container *ngIf="minipillola.testo != ''">
                    <div class="popup_gioco_pillola_titolo">Pillole di sostenibilità</div>
                
                    <div class="popup_gioco_pillola_descrizione" [innerHTML]="minipillola.testo | safeHtml"></div>
                </ng-container>
                
            </div>
            <!-- fine pillola -->

            <div class="pop_default_titolo">Come si gioca</div>
            
            <div class="pop_default_descrizione">Premi le bolle che contengono i prodotti a marchio Conad.</div>
            
            <div class="pop_default_foto_descrizione" style="margin-top: 10px;">
                <img src="/assets/img/gioco_prodotti/tutorial_prodotti_1.jpg" width="240" alt="esempio prodotti">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Se ti sfugge un prodotto a marchio o premi su un altro elemento perdi 1 punto.</div>
            
            <div class="pop_default_foto_descrizione">
                <img src="/assets/img/gioco_prodotti/tutorial_prodotti_2.jpg" width="240px" alt="gli interruttori">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Accumula 10 punti per vincere.<br><br></div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;" *ngIf="playId != '0'">
            <div class="tasto_principale next-slide" role="button" aria-label="Inizia il gioco" (click)="closeTutorial()">GIOCA</div>
        </div>
        
        <div class="azioni_pop azioni_pop_tutorial" style="margin-top: 0;" *ngIf="playId == '0'">
            <div [routerLink]="'/elenco-minigiochi'" role="button" aria-label="Annulla" class="tasto_principale tasto_principale_outline">ANNULLA</div>
            <div (click)="closeTutorial()" role="button" aria-label="Inizia il gioco" class="tasto_principale">GIOCA</div>
        </div>

    </div>
    
</div>
<!-- fine pop gioco tutorial -->