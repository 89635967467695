import { LocationStrategy } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { BRAND_LOGO_BARILLA } from 'src/app/interfaces/brand';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { Bottiglia, KEY_TUTORIAL_SQUALO } from 'src/app/interfaces/squalo';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';

export const IMG_BOA_ACCESA = "https://conad.s3.eu-south-1.amazonaws.com/assets/img/gioco_squalo/singola_boa.png";
export const IMG_BOA_SPENTA = "https://conad.s3.eu-south-1.amazonaws.com/assets/img/gioco_squalo/singola_boa_spenta.png";
export const IMG_LATTINA= "https://conad.s3.eu-south-1.amazonaws.com/assets/img/gioco_squalo/lattina.png";
export const IMG_BOTTIGLIA = "https://conad.s3.eu-south-1.amazonaws.com/assets/img/gioco_squalo/bottiglia.png";
export const VIDEO_MARE = "https://conad.s3.eu-south-1.amazonaws.com/assets/video/mare_flat.mp4";

@Component({
  selector: 'app-gioco-squalo-page',
  templateUrl: './gioco-squalo-page.component.html',
  styleUrls: ['./gioco-squalo-page.component.css'],
  animations: [],
})
export class GiocoSqualoPageComponent implements OnInit, OnDestroy {
  playId: string = '0'; // ID giocata

  brandLogoUrl: string = '';

  // posizione iniziale
  position: number = 2;
  // punteggio iniziale
  score: number = 0;
  // numero di penalità
  penalita: number = 0;
  // punteggio da raggiungere per concludere il gioco
  scoreObiettivo: number = 10;
  // gioco attivo
  gameActive: boolean = true;

  // millisecondi tra gli spawn oggetti
  timerSpawn: number = 1000;

  // velocità oggetti
  speed: number = 3000;

  // squalo colpito da ostacolo
  colpito: boolean = false;

  // oggetti generati
  oggetti: Array<Bottiglia> = [];
  counterOggetti: number = 0;

  // audio squalo mangia oggetto
  suonoCollisioneOggetto: any = null;

  // flag per popup tutorial
  popupTutorial: boolean = true;
  // flag per popup inizio gioco
  popupStart: boolean = false;

  // flag per popup tutorial
  frecceDestra: boolean = true;
  frecceSinistra: boolean = false;
  // flag per blocco funzioni di swipe
  blockSwipe: boolean = false;

  // flag per popup modale fine gioco
  showPopupConclusione: boolean = false;

  // messaggi incoraggianti
  showSuccess: boolean = false;
  successMessage: number = 1;

  minipillola: Minipillola | null = null;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
    private location: LocationStrategy
  ) {}

  ngOnInit(): void {

    this.loadMedia();

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log('playId: ' + this.playId);

    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {
      this.brandLogoUrl = BRAND_LOGO_BARILLA;
    }else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.SQUALO_IN_MARE);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }

    var tutorialAlreadyShown = this.local.getData(KEY_TUTORIAL_SQUALO);

    if (tutorialAlreadyShown == '1' && this.playId != '0') {
      this.popupTutorial = false;
      this.popupStart = true;
      this.frecceSinistra = false;
      this.frecceDestra = false;
    }
  }

  ngOnDestroy(): void {
    // disattivo gioco
    this.gameActive = false;

    // unload del suono per liberare memoria
    this.suonoCollisioneOggetto?.pause();
  }

  // funzione per chiudere tutorial
  closeTutorial() {
    // nascondo popup
    this.popupTutorial = false;

    this.local.saveData(KEY_TUTORIAL_SQUALO, '1');
  }

  // funzione per inizio gioco
  startGame() {
    // nascondo popup start
    this.popupStart = false;

    // creo oggetti
    this.createRandomObject(this.timerSpawn);

    // distruggo oggetti non presi
    this.destroyLastElement();

    // check su collisioni
    this.detectPosition();
  }

  // muovi a sinistra
  async clickSpostaSx() {
    this.blockSwipe = true;

    this.position = this.position != 1 ? this.position - 1 : 1;

    await this.delay(300);
    this.blockSwipe = false;
  }

  // muovi a destra
  async clickSpostaDx() {
    this.blockSwipe = true;

    this.position = this.position != 3 ? this.position + 1 : 3;

    await this.delay(300);
    this.blockSwipe = false;
  }

  // trigger allo swipe
  onSwipe(event: any) {
    // se gioco attivo
    if (this.gameActive && !this.blockSwipe) {
      // se frecce tutorial destra attive o frecce tutorial disattivate
      if (
        (!this.frecceDestra && this.frecceSinistra) ||
        (!this.frecceDestra && !this.frecceSinistra)
      ) {
        // swipe sinistra
        if (event.direction == Hammer.DIRECTION_LEFT) {
          this.clickSpostaSx();

          // se frecce tutorial sinistra attive
          if (this.frecceSinistra) {
            // inizia partita
            this.startGame();
          }
          // disattivo frecce tutorial
          this.frecceSinistra = false;
        }
      }

      // se frecce tutorial sinistra attive o frecce tutorial disattivate
      if (
        (this.frecceDestra && !this.frecceSinistra) ||
        (!this.frecceDestra && !this.frecceSinistra)
      ) {
        // swipe destra
        if (event.direction == Hammer.DIRECTION_RIGHT) {
          this.clickSpostaDx();

          // se frecce tutorial destra attive
          if (this.frecceDestra) {
            // attivo frecce tutorial sinistra
            this.frecceSinistra = true;
          }
          // disattivo frecce tutorial
          this.frecceDestra = false;
        }
      }
    }
  }

  // tap sullo schermo
  onTap(direction: string) {
    // se gioco attivo
    if (this.gameActive && !this.blockSwipe) {
      // se frecce tutorial destra attive o frecce tutorial disattivate
      if (!this.frecceDestra && !this.frecceSinistra) {
        // tap sinistra
        if (direction == 'left') {
          this.clickSpostaSx();
        }
      }

      // se frecce tutorial sinistra attive o frecce tutorial disattivate
      if (!this.frecceDestra && !this.frecceSinistra) {
        // tap destra
        if (direction == 'right') {
          this.clickSpostaDx();
        }
      }
    }
  }

  // funzione per creare una lattina
  async createLattina(position: number) {
    // inizializzo immagine
    const lattinaImg = this.renderer.createElement('img');
    // aggiungo src immagine
    this.renderer.setAttribute(lattinaImg, 'src', IMG_LATTINA);

    // creo div contenitore
    const lattina = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(lattina, 'lattina');
    // aggiungo style al div
    this.renderer.setStyle(lattina, 'animation-duration', this.speed + 'ms');
    this.renderer.setStyle(lattina, 'animation-fill-mode', 'forwards');
    // sposto oggetto in base al parametro
    var animationName = '';
    switch (position) {
      case 1:
        animationName = 'spostamento_elementi_sx';
        break;
      case 2:
        animationName = 'spostamento_elementi_centrale';
        break;
      case 3:
        animationName = 'spostamento_elementi_dx';
        break;

      default:
        break;
    }
    // aggiungo style al div
    this.renderer.setStyle(lattina, 'animation-name', animationName);
    this.renderer.setStyle(lattina, 'animation-iteration-count', '1');
    this.renderer.setStyle(lattina, 'animation-timing-function', 'linear');
    // assegno un id
    this.renderer.setAttribute(lattina, 'id', 'id' + this.counterOggetti);

    // aggiungo oggetto nell'array
    this.oggetti.push({ id: 'id' + this.counterOggetti, punti: true });

    // aggiungo tag img al div appena creato
    if (this.gameActive) {
      this.renderer.appendChild(lattina, lattinaImg);
    }

    // recupero il div nel gioco tramite id
    const parent = document.getElementById('game');

    // inserisco il div nel gioco
    if (this.gameActive) {
      this.renderer.appendChild(parent, lattina);
    }
  }

  // funzione per creare una bottiglia
  async createBottiglia(position: number) {
    // inizializzo immagine
    const bottigliaImg = this.renderer.createElement('img');
    // aggiungo src immagine
    this.renderer.setAttribute(bottigliaImg, 'src', IMG_BOTTIGLIA);

    // creo div contenitore
    const bottiglia = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(bottiglia, 'lattina');
    // aggiungo style al div
    this.renderer.setStyle(bottiglia, 'animation-duration', this.speed + 'ms');
    this.renderer.setStyle(bottiglia, 'animation-fill-mode', 'forwards');
    // sposto oggetto in base al parametro
    var animationName = '';
    switch (position) {
      case 1:
        animationName = 'spostamento_elementi_sx';
        break;
      case 2:
        animationName = 'spostamento_elementi_centrale';
        break;
      case 3:
        animationName = 'spostamento_elementi_dx';
        break;

      default:
        break;
    }
    // aggiungo style al div
    this.renderer.setStyle(bottiglia, 'animation-name', animationName);
    this.renderer.setStyle(bottiglia, 'animation-iteration-count', '1');
    this.renderer.setStyle(bottiglia, 'animation-timing-function', 'linear');
    // assegno un id
    this.renderer.setAttribute(bottiglia, 'id', 'id' + this.counterOggetti);

    // aggiungo oggetto nell'array
    this.oggetti.push({ id: 'id' + this.counterOggetti, punti: true });

    // aggiungo tag img al div appena creato
    if (this.gameActive) {
      this.renderer.appendChild(bottiglia, bottigliaImg);
    }

    // recupero il div nel gioco tramite id
    const parent = document.getElementById('game');

    // inserisco il div nel gioco
    if (this.gameActive) {
      this.renderer.appendChild(parent, bottiglia);
    }
  }

  // funzione per creare una boa
  async createBoa(left: number) {
    // inizializzo prima immagine
    const boaImg = this.renderer.createElement('img');
    // aggiungo src immagine
    this.renderer.setAttribute(boaImg, 'src', IMG_BOA_ACCESA);

    // aggiungo classe
    this.renderer.addClass(boaImg, 'singola_boa');

    // inizializzo seconda immagine
    const boaImg2 = this.renderer.createElement('img');
    // aggiungo src immagine
    this.renderer.setAttribute(boaImg2, 'src', IMG_BOA_SPENTA);

    // aggiungo classe
    this.renderer.addClass(boaImg2, 'singola_boa_spenta');

    // creo div contenitore
    const divBoa = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(divBoa, 'dondola_boa');

    // creo div contenitore
    const boa = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(boa, 'contenitore_boa');
    // aggiungo style
    this.renderer.setStyle(boa, 'animation-duration', this.speed + 'ms');
    this.renderer.setStyle(boa, 'animation-fill-mode', 'forwards');
    // sposto oggetto in base al parametro
    var animationName = '';
    switch (left) {
      case 1:
        animationName = 'spostamento_boa_sx';
        break;
      case 2:
        animationName = 'spostamento_boa_centrale';
        break;
      case 3:
        animationName = 'spostamento_boa_dx';
        break;

      default:
        break;
    }
    // aggiungo style
    this.renderer.setStyle(boa, 'animation-name', animationName);
    this.renderer.setStyle(boa, 'animation-iteration-count', '1');
    this.renderer.setStyle(boa, 'animation-timing-function', 'linear');
    // aggiungo id
    this.renderer.setAttribute(boa, 'id', 'id' + this.counterOggetti);

    // aggiungo oggetto nell'array
    this.oggetti.push({ id: 'id' + this.counterOggetti, punti: false });

    // aggiungo i tag img al div appena creato
    if (this.gameActive) {
      this.renderer.appendChild(divBoa, boaImg);
      this.renderer.appendChild(divBoa, boaImg2);
      this.renderer.appendChild(boa, divBoa);
    }

    // recupero il div nel gioco tramite id
    const parent = document.getElementById('game');

    // inserisco il div nel gioco
    if (this.gameActive) {
      this.renderer.appendChild(parent, boa);
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // creo oggetto randomico
  async createRandomObject(delay: number) {
    // attendo
    await this.delay(delay);

    // aumento numero oggetti generati
    this.counterOggetti++;

    // numero casuale tra 1 e 3
    var min = 1;
    var max = 4;
    var risultato = Math.floor(Math.random() * (max - min) + min);

    // numero casuale tra 1 e 3
    min = 1;
    max = 4;
    var position = Math.floor(Math.random() * (max - min) + min);

    if (risultato == 1) {
      // genero lattina
      this.createLattina(position);
    } else if (risultato == 2) {
      // genero bottiglia
      this.createBottiglia(position);
    } else {
      // genero boa
      this.createBoa(position);
    }

    // se gioco ancora in corso
    if (this.gameActive) {
      // ricorsione funzione
      this.createRandomObject(this.timerSpawn);
    }
  }

  // distruggo elemento non preso
  async destroyLastElement() {
    // sleep
    await this.delay(this.timerSpawn);

    // se trovo aleno un oggetto
    if (this.oggetti.length) {
      // scorro tutti gli oggetti presenti
      this.oggetti.forEach((element) => {
        // recupero i div nel gioco tramite id
        const parent = document.getElementById('game');
        const firstChild = document.getElementById('' + element.id);
        const squaloDiv = document.getElementById('squalo');

        // ricavo posizioni oggetto e squalo
        var pos = firstChild?.getBoundingClientRect();
        var squalo = squaloDiv?.getBoundingClientRect();

        // se è arrivato a fine schermo
        if (
          pos &&
          Math.ceil(pos?.y) >= (squalo?.top ? squalo.bottom : 0) - 40
        ) {
          // rimuovo oggetto
          this.renderer.removeChild(parent, firstChild);

          // cerco indice oggetto da rimuovere
          var idToRemove = this.oggetti.findIndex(
            (search) => search.id == element.id
          );

          // tolgo oggetto da array oggetti
          this.oggetti.splice(idToRemove, 1);
        }
      });
    }

    // se gioco ancora in corso
    if (this.gameActive) {
      // ricorsione funzione
      this.destroyLastElement();
    }
  }

  // check su collisioni con oggetti
  async detectPosition() {
    // sleep
    await this.delay(10);

    // recupero i div nel gioco tramite id
    const parent = document.getElementById('game');
    const squaloDiv = document.getElementById('squalo');
    var squalo = squaloDiv?.getBoundingClientRect();

    // scorro tutti gli oggetti presenti
    this.oggetti.forEach((element) => {
      // recupero il div dell'oggetto
      const firstChild = document.getElementById('' + element.id);
      var pos = firstChild?.getBoundingClientRect();

      // recupero width dispositivo
      var screenWidth = window.innerWidth;

      // recupero dimensioni oggetto
      var dimensioneOggetto = firstChild ? firstChild?.offsetWidth : 0;
      var heightOggetto = firstChild ? firstChild?.offsetHeight : 0;

      // controllo se non sono stato colpito
      if (pos && !this.colpito) {
        // oggetto che da punti
        if (element.punti) {
          // altezza oggetto
          var altezzaOggetto = Math.ceil(pos?.y);
        } else {
          // altezza ostacolo
          var altezzaOggetto = Math.ceil(pos?.y) + heightOggetto / 2;
        }

        // controllo se c'è collisione
        if (
          altezzaOggetto <= (squalo?.top ? squalo.top : 0) + 30 &&
          altezzaOggetto >= (squalo?.top ? squalo.top : 0) + 20
        ) {
          // controllo se squalo è nella corsia giusta
          if (
            (pos.x + dimensioneOggetto / 2 <= screenWidth / 3 &&
              this.position == 1) ||
            (pos.x + dimensioneOggetto / 2 > screenWidth / 3 &&
              pos.x + dimensioneOggetto / 2 < (screenWidth * 2) / 3 &&
              this.position == 2) ||
            (pos.x + dimensioneOggetto / 2 >= (screenWidth * 2) / 3 &&
              this.position == 3)
          ) {
            // rimuovo oggetto
            this.renderer.removeChild(parent, firstChild);

            // cerco indice oggetto da rimuovere
            var idToRemove = this.oggetti.findIndex(
              (search) => search.id == element.id
            );

            // tolgo oggetto da array oggetti
            this.oggetti.splice(idToRemove, 1);

            // se oggetto dà punti
            if (element.punti) {
              // inizializzo suono casella
              this.suonoCollisioneOggetto = new Audio();
              this.suonoCollisioneOggetto.src =
                'assets/audio/bottiglia_plastica.mp3';
              this.suonoCollisioneOggetto.load();

              // riproduco audio
              this.suonoCollisioneOggetto.play();

              // aumento punteggio
              this.increaseScore();

              // mostro messaggio incoraggiante
              this.showSuccessFunction();
            } else {
              // aumento penalità
              this.penalita++;

              // squalo viene colpito
              this.colpisciSqualo();
            }
          }
        }
      }
    });

    // se gioco ancora in corso
    if (this.gameActive) {
      // ricorsione funzione
      this.detectPosition();
    }
  }

  // funzione per squalo colpito
  async colpisciSqualo() {
    // squalo colpito
    this.colpito = true;

    // sleep
    await this.delay(5000);

    // resetto stato squalo
    this.colpito = false;
  }

  // funzione per aumentare punteggio
  async increaseScore() {
    // aumento punteggio
    this.score++;

    // se raggiungo obiettivo
    if (this.score == this.scoreObiettivo) {
      // gioco non più in corso
      this.gameActive = false;

      if (this.playId != null && this.playId != '0') {
        await this.setRewardPlayed();
      } else {
        // mostro subito popup fine gioco
        this.showPopupConclusione = true;
      }
    }
  }

  // funzione per popup chiudi il gioco
  async clickClose() {
    // disattivo momentaneamente il gioco
    this.gameActive = false;

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm(
      'Sicuro di voler uscire?',
      'CONFERMO',
      'ANNULLA'
    );

    // se utente conferma
    if (response) {
      // ritorno home page
      this.router.navigate(['/home']);
    } else {
      // riattivo gioco
      this.gameActive = true;
      this.startGame();
    }
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {
    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.gamePlayed(this.playId, true)
      );

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupConclusione = true;
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setRewardPlayed();
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  /**
   * Mostro messaggio incoraggiante
   */
  async showSuccessFunction() {
    // numero casuale tra 1 e 5
    var min = 1;
    var max = 5;
    var lastMessage = this.successMessage;
    while (this.successMessage == lastMessage) {
      this.successMessage = Math.floor(Math.random() * (max - min) + min);
    }

    this.showSuccess = true;

    await this.delay(500);

    this.showSuccess = false;
  }

  /**
   * Caricamento media
   */
  async loadMedia() {

    this.loading.present();

    const items = [IMG_BOA_ACCESA, IMG_BOA_SPENTA, IMG_BOTTIGLIA, IMG_LATTINA, VIDEO_MARE];

    for(let url of items) {

      try {

        await fetch(url, {mode: "no-cors"});
        
      } catch (error) {
        
        console.error(error);
      }
    }

    this.loading.dismiss();
  }
}
