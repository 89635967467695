<!-- inizio contenitore swiper -->
<swiper [config]="config" #usefulSwiper>
  <div class="swiper-wrapper">
    <!-- inizio tutorial 1 -->
    <div class="swiper-slide contenitore_tutorial">
      <div class="pop_default">
        <div class="contenuto_principare_pop">
          <div class="pop_default_titolo">
            Benvenuto<br />nelle Sfide di Conad
          </div>

          <div class="tutorial_immagine">
            <img src="/assets/img/tutorial/tutorial_1.jpg" alt="" />
          </div>
        </div>

        <div class="azioni_pop" style="margin-top: 0">
          <div
            class="tasto_principale next-slide"
            role="button"
            aria-label="Vai alla prossima slide"
          >
            CONTINUA
          </div>
          <a
            class="tasto_secondario"
            (click)="onClickSkipp(1)"
            role="link"
            aria-label="Salta il tutorial e vai alla home del gioco"
            >Salta tutorial</a
          >
        </div>
      </div>
    </div>
    <!-- fine tutorial 1 -->

    <!-- inizio tutorial 2 -->
    <div class="swiper-slide contenitore_tutorial">
      <div class="pop_default">
        <div class="contenuto_principare_pop">
          <div class="pop_default_titolo">Fai la spesa<br />e partecipa</div>

          <div class="pop_default_descrizione">
            La prima spesa della giornata del valore di 5€ ti consentirà di
            lanciare il dado per avanzare lungo il percorso.
          </div>

          <div class="tutorial_immagine">
            <img
              src="/assets/img/tutorial/tutorial_2.jpg"
              alt=""
              style="object-position: center"
            />
          </div>
        </div>

        <div class="azioni_pop" style="margin-top: 0">
          <div
            class="tasto_principale next-slide"
            role="button"
            aria-label="Vai alla prossima slide"
          >
            CONTINUA
          </div>
          <a
            class="tasto_secondario"
            (click)="onClickSkipp(2)"
            role="link"
            aria-label="Salta il tutorial e vai alla home del gioco"
            >Salta tutorial</a
          >
        </div>
      </div>
    </div>
    <!-- fine tutorial 2 -->

    <!-- inizio tutorial 3 -->
    <div class="swiper-slide contenitore_tutorial">
      <div class="pop_default">
        <div class="contenuto_principare_pop">
          <div class="pop_default_titolo">Risparmia e divertiti</div>

          <div class="pop_default_descrizione">
            Ogni casella ti riserverà una sorpresa come buoni sconto su prodotti
            selezionati di Grandi Marche e a marchio Conad, un quiz, una prova
            di abilità da superare e alcuni consigli su come condurre una vita
            sostenibile.<br />
            Inoltre avrai la possibilità di allenarti al gioco, utilizzando la
            sezione "prova"!
          </div>

          <div class="tutorial_immagine">
            <img
              *ngIf="worldId == 1"
              src="/assets/img/tutorial/tutorial_3.jpg"
              alt=""
            />

            <img
              *ngIf="worldId == 2"
              src="/assets/img/tutorial/tutorial_3b.jpg"
              alt=""
            />

            <img
              *ngIf="worldId == 3"
              src="/assets/img/tutorial/tutorial_3c.jpg"
              alt=""
            />

            <img
              *ngIf="worldId == 4"
              src="/assets/img/tutorial/tutorial_3d.jpg"
              alt=""
            />
              
          </div>
        </div>

        <div class="azioni_pop" style="margin-top: 0">
          <div
            class="tasto_principale next-slide"
            role="button"
            aria-label="Vai alla prossima slide"
          >
            CONTINUA
          </div>
          <a
            class="tasto_secondario"
            (click)="onClickSkipp(3)"
            role="link"
            aria-label="Salta il tutorial e vai alla home del gioco"
            >Salta tutorial</a
          >
        </div>
      </div>
    </div>
    <!-- fine tutorial 3 -->

    <!-- inizio tutorial 4 -->
    <div class="swiper-slide contenitore_tutorial">
      <div class="pop_default">
        <div class="contenuto_principare_pop">
          <div class="pop_default_titolo">Premia te e l'ambiente</div>

          <div class="pop_default_descrizione">
            Accumula "Margherite verdi" superando i quiz e i minigiochi che
            troverai lungo il percorso!<br />
            Ti serviranno per partecipare all'estrazione finale di fantastici
            premi e per contribuire al grande progetto di sostenibilità
            "Forestiamo Insieme l'Italia 2023".
          </div>

          <div class="tutorial_immagine">
            <img
              src="/assets/img/tutorial/tutorial_4.svg"
              aria-label="Il dado che dovrai lanciare durante il gioco"
              alt=""
              style="object-position: center bottom; object-fit: fill"
            />
          </div>
        </div>

        <div class="azioni_pop" style="margin-top: 0">
          <div
            class="tasto_principale next-slide"
            role="button"
            aria-label="Vai alla prossima slide"
          >
            CONTINUA
          </div>
          <a
            class="tasto_secondario"
            (click)="onClickSkipp(4)"
            role="link"
            aria-label="Salta il tutorial e vai alla home del gioco"
            >Salta tutorial</a
          >
        </div>
      </div>
    </div>
    <!-- fine tutorial 4 -->

    <!-- inizio tutorial 5 -->
    <div class="swiper-slide contenitore_tutorial">
      <div class="pop_default">
        <div class="contenuto_principare_pop">
          <div class="pop_default_titolo">Corri a giocare</div>

          <div class="pop_default_descrizione">
            Cosa aspetti? Inizia a giocare e a vincere i coupon per la tua
            spesa! Alla fine del gioco più Margherite verdi avrai accumulato,
            maggiori saranno le possibilità di vincere all'estrazione finale.
          </div>

          <div class="tutorial_immagine" style="margin-top: 20px">
            <img src="/assets/img/tutorial/tutorial_5.jpg" alt="" />
          </div>
        </div>

        <div class="azioni_pop" style="margin-top: 0">
          <a
            class="tasto_principale"
            (click)="onClickSkipp(5)"
            role="link"
            aria-label="Comincia il gioco"
            >PROCEDI</a
          >
        </div>
      </div>
    </div>
    <!-- fine tutorial 5 -->
  </div>

  <!-- Add Pagination -->
  <div
    class="swiper-pagination paginatore_tutorial"
    aria-hidden="true"
    role="none"
  ></div>

  <!-- Add Arrows -->
  <!--
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
    -->
</swiper>
<!-- fine contenitore swiper -->
