<app-header [showHeader]="true" [showBack]="true"></app-header>


<div class="nonvedente">

    <div>Rex Maris, il difensore dei mari</div>
    <div role="button" (click)="onSelectAvatar('rex_maris')">Scegli Rex Maris come avatar</div>

    <div>Gea, protegge la natura</div>
    <div role="button" (click)="onSelectAvatar('madre_natura')">Scegli Gea come avatar</div>

    <div>Dina Din, mantiene puliti i nostri cieli</div>
    <div role="button" (click)="onSelectAvatar('dinadin')">Scegli Dina din come avatar</div>

</div>


<!-- inizio contenitore avatar -->
<swiper *ngIf="config" [config]="config" #usefulSwiper class="contenitore_avatar" aria-hidden="true">
    
    <div class="swiper-wrapper">
        
        <!-- inizio avatar 1 -->
        <div class="swiper-slide" *ngFor="let slide of slides">
            
            <video playsinline autoplay muted loop disablePictureInPicture [poster]="'https://conad.s3.eu-south-1.amazonaws.com/assets/video/avatar/poster_'+slide.name+'.jpg'">
                <source src="{{slide.source}}" type="video/mp4">
            </video>
            
            <div class="contenitore_tasto_scegli">
                <div (click)="onSelectAvatar(slide.name)" class="tasto_principale tasto_principale_ombra" role="button" [attr.aria-label]="slide.aria_label">{{slide.tasto}}</div>
            </div>
            
        </div>
        <!-- fine avatar 1 -->
    
    </div>

    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
</swiper>
<!-- fine contenitore avatar -->

<!-- <app-footer [showFooter]="true" [avatar]="avatar"></app-footer> -->
