import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.css']
})
export class TutorialComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    navigation: {
      nextEl: '.next-slide',
      prevEl: '.swiper-button-prev'
    },
    loop: false
  };

  worldId: number = 0;

  constructor(
    public appService: AppService,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    // recupero ID in base alla data
    this.worldId = this.appService.getWorldIdByDate();
  }

  /**
   * Registra ultima pagina visualizzata
   * @param pageNumber 
   */
  async onClickSkipp(pageNumber: number) {

    this.loading.present();

    try {
      
      await lastValueFrom(this.appService.tutorialSet(pageNumber));

      this.router.navigateByUrl('/home');

    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }

  }
}
