<!-- inizio elenco giocate -->
<div class="elenco_giocate">
    
    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Stai per uscire dal gioco</div>
            
        </div>

    </div>
    
</div>
<!-- fine elenco giocate -->
