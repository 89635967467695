<!-- inizio contenitore poupup per accessibilità -->
<div class="contenitore_pupup" role="none" aria-hidden="true">

    <!-- inizio coupon vinto -->
    <div class="popup_evento popup_coupon_vinto mostra_popup_evento">

        <div *ngIf="coupon" class="popup_evento_contenitore">
            <div class="coupon_vinto_foto">
                <div class="coupon_vinto_foto_etichetta">{{ coupon.labelValue }}</div>
                <img [src]="coupon.imageUrl" alt="" />
            </div>

            <div class="popup_evento_titolo">
                Hai ottenuto un<br />Buono Sconto!
            </div>

            <div class="popup_evento_contenuto">
                <img class="popup_logo_brand" [src]="coupon.imageBrandUrl" alt="" />

                <div class="coupon_vinto_periodo" [innerHTML]="coupon.abstract | safeHtml"></div>

                <div class="coupon_vinto_info" [innerHTML]="coupon.description | safeHtml"></div>

                <div class="coupon_vinto_consiglio">
                    Per utilizzare il coupon vai nella sezione offerte e buoni dell’app
                    Conad.
                </div>
            </div>

            <div class="azioni_pop" style="border: none">
                <div class="tasto_principale next-slide" role="button" aria-label="Continua">
                    CONTINUA
                </div>
            </div>
        </div>

    </div>
    <!-- fine coupon vinto -->
</div>