<div class="pagina_gioco flip-card" [ngClass]="{'mostra_risultato': flip}">
    <div class="flip-card-inner">

        <!-- inizio card front -->
        <div class="flip-card-front">
            <div class="pop_default">

                <div class="contenuto_principare_pop">

                    <div class="intro_quiz_sostenibilita">

                        <ng-container *ngIf="!isFineLivello">

                            <ng-container *ngIf="question?.brandUrl != ''">

                                <!-- <div class="sponsorizzazione">Sponsorizzato da:</div> -->
                                
                                <img [src]="question?.brandUrl" alt="">
                            </ng-container>

                            <ng-container *ngIf="question?.imageUrl != ''">
                                <img [src]="question?.imageUrl" alt="">
                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="isFineLivello">

                            <img src="/assets/img/punto_margherita.svg" alt="">

                            <span class="colore_sostenibilita">
                                Raddoppia il punto Margherita Verde, rispondendo correttamente a questa domanda
                            </span>

                        </ng-container>
                        
                    </div>
                    
                    <!-- inizio tempo -->
                    <div class="contenitore_tempo">
                        
                        <div class="tempo_quiz">
                            <svg viewBox="0 0 420.6 468.5" class="timer">
                                <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -120.9719 224.3401)" cx="210.3" cy="258.2" rx="187.6" ry="187.6"/>
                                <path d="M227,48.5V33.4h11.2c8.8,0,16.1-7.2,16.1-16.1v-1.2c0-8.8-7.2-16.1-16.1-16.1h-55.9c-8.8,0-16.1,7.2-16.1,16.1v1.2c0,8.8,7.2,16.1,16.1,16.1h11.2v15.2C85.4,57.1,0,147.8,0,258.2c0,116,94.3,210.3,210.3,210.3c116,0,210.3-94.3,210.3-210.3C420.6,147.8,335.2,57.1,227,48.5z M210.3,445.8c-103.5,0-187.6-84.2-187.6-187.6S106.9,70.6,210.3,70.6S398,154.7,398,258.2S313.8,445.8,210.3,445.8z"/>
                            </svg>
                            <div class="contenitore_tempo_passa">
                                <svg class="scorrimento_tempo" viewBox="0 0 32 32">
                                    <circle class="circle" r="16" cx="16" cy="16" attr.stroke-dasharray="{{((remainingTime)*100)/time}}, 100" />
                                </svg>
                            </div>
                        </div>
                        
                        <div class="descrizione_tempo">
                            Hai <strong>{{remainingTime}}</strong> secondi<br />
                            per rispondere<br />
                            (tentativo {{tentativo}} di {{limit_tentativi}})
                        </div>
                        
                    </div>
                    <!-- fine tempo -->

                    <div class="titolo_domanda" [innerHTML]="question?.description"></div>
                    
                    <div class="risposte">

                        <div
                            (click)="clickAnswer(1)"
                            class="singola_risposta"
                            [ngClass]="{'risposta_errata': rispostaSbagliata == 1, 'risposta_corretta': rispostaEsatta == 1, 'singola_risposta_scelta': rispostaScelta == 1}"
                        >
                            {{question?.answer_1}}
                        </div>

                        <div
                            (click)="clickAnswer(2)"
                            class="singola_risposta"
                            [ngClass]="{'risposta_errata': rispostaSbagliata == 2, 'risposta_corretta': rispostaEsatta == 2, 'singola_risposta_scelta': rispostaScelta == 2}"
                        >
                            {{question?.answer_2}}
                        </div>

                        <div
                            (click)="clickAnswer(3)"
                            class="singola_risposta"
                            [ngClass]="{'risposta_errata': rispostaSbagliata == 3, 'risposta_corretta': rispostaEsatta == 3, 'singola_risposta_scelta': rispostaScelta == 3}"
                        >
                            {{question?.answer_3}}
                        </div>

                    </div>

                </div>

            </div>
			<div class="distanziatore"></div>
        </div>
        <!-- fine card front -->
        
        <!-- inizio card back -->
        <div class="flip-card-back">
            <div class="pop_default">

                <div class="contenuto_principare_pop">

                    <div *ngIf="rispostaScelta == question?.success" class="pop_default_titolo colore_sostenibilita">Risposta corretta!</div>
                    <div *ngIf="rispostaScelta == question?.success && !isFineLivello" class="pop_default_sottotitolo">Hai ottenuto una Margherita Verde ed un tiro di dado extra.</div>
                    <div *ngIf="rispostaScelta == question?.success && isFineLivello" class="pop_default_sottotitolo">Hai raddoppiato il punto Margherita Verde ed ottenuto un tiro di dado extra.</div>

                    <div *ngIf="rispostaScelta != question?.success" class="pop_default_titolo">Peccato!!!</div>
                    <div *ngIf="rispostaScelta != question?.success && remainingTime != 0" class="pop_default_sottotitolo">Risposta errata!</div>
                    <div *ngIf="rispostaScelta != question?.success && remainingTime == 0" class="pop_default_sottotitolo">Tempo esaurito!</div>

                    <div class="quiz_punti_sostenibilita">
                        <img *ngIf="rispostaScelta == question?.success" src="/assets/img/punto_margherita.svg">
                        
                        <!--
                        <div *ngIf="isFineLivello" class="contatore_punti_sostenibilita">
                            <div class="tuoi_punti_sostenibilita">9</div>
                            <div class="community_punti_sostenibilita">1235496</div>
                        </div>
                        
                        <div *ngIf="isFineLivello" class="contatore_punti_sostenibilita_label">
                            <div class="punti_sostenibilita_label">I tuoi punti</div>
                            <div class="punti_sostenibilita_label">Punti Community</div>
                        </div>
                        -->
                        
                    </div>
                    
                    <div class="pillola_quiz" [innerHTML]="question?.successDescription"></div>
                    
                </div>

                <div class="azioni_pop">
                    <div (click)="concludi()" class="tasto_principale next-slide" role="button" aria-label="Vai alla prossima slide">CONTINUA</div>
                </div>

            </div>
        </div>
        <!-- fine card back -->
		
		
		
    </div>
</div>


