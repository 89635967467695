<footer [ngClass]="{ nascondi_footer: !showFooter }">
  <div class="footer_avatar">
    <img [src]="avatar?.url" aria-hidden="true" role="none" alt="" />
  </div>

  <span
    class="footer_valore"
    [attr.aria-label]="'casella raggiunta ' + casellaCorrente"
    role="text"
  >
    <img
      src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/footer_casella.png"
      aria-hidden="true"
      role="none"
      alt=""
    />
    <span aria-hidden="true" role="none">{{ casellaCorrente }}</span>
  </span>

  <span
    class="footer_valore"
    [attr.aria-label]="tiriDado + ' tiri di dado disponibili'"
    role="text"
  >
    <img
      src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/footer_dado.png"
      aria-hidden="true"
      role="none"
      alt=""
    />
    <span aria-hidden="true" role="none">{{ tiriDado }}</span>
  </span>

  <span
    class="footer_valore"
    [attr.aria-label]="'Punti Margherite verdi ottenuti ' + puntiSostenibilita"
    role="text"
  >
    <img
      src="https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/footer_margherita.png"
      aria-hidden="true"
      role="none"
      alt=""
    />
    <span aria-hidden="true" role="none">{{ puntiSostenibilita }}</span>
  </span>
</footer>
