import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-init-page',
  templateUrl: './init-page.component.html',
  styleUrls: ['./init-page.component.css']
})
export class InitPageComponent implements OnInit {

  constructor(
    public appService: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    const token = this.route.snapshot.paramMap.get('token')!;

    this.appService.removeAuthUser();

    this.decodeApplicationToken(token);
  }

  /**
   * Decodifica lato server del token di accesso per recuperare i dati dell'utente loggato
   * @param token 
   */
  private async decodeApplicationToken(token: string) {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.authsAuth(token));

      // dati utente decodificato
      this.appService.authUser = {
        user: {
          userId: data.user.userId,
          fidelityCard: data.user.fidelityCard,
          userGameId: data.user.userGameId,
          name: data.user.name,
          signature: data.user.signature
        },
        info: {
          accessibility: data.info.accessibility,
          worldId: data.info.worldId
        },
        auth: {
          jwtToken: data.auth.jwtToken,
          refreshToken: data.auth.refreshToken
        }
      }

      this.appService.saveAuthUser();

      this.onAfterInit();
      
    } catch (error) {
      
      console.log(error);

      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }

  /**
   * Redirect da eseguire dopo la funzione di init
   */
  private onAfterInit() {

    if (this.appService.authUser) {

      // 1. controllo se esiste un mondo
      if (this.appService.authUser.info.worldId == 0) {

        // cosa succede quando non c'è un mondo?
        return;
      }

      // 2. controllo se è stato fatto l'onboarding per il mondo corrente
      if (this.appService.authUser.user.signature < this.appService.authUser.info.worldId) {

        this.router.navigateByUrl('/on-boarding');

        return;
      }

      // 3. redirect su home-page
      this.router.navigateByUrl('/home');
    }
  }

}
