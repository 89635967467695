import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-tutorial-external',
  templateUrl: './tutorial-external.component.html',
  styleUrls: ['./tutorial-external.component.css']
})
export class TutorialExternalComponent implements OnInit {

  config: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    autoHeight: true,
    allowTouchMove: true,
    navigation: {
      nextEl: '.next-slide',
      prevEl: '.swiper-button-prev'
    },
    loop: false
  };

  worldId: number = 0;

  constructor(public appService: AppService) { }

  ngOnInit(): void {

    // recupero ID in base alla data
    this.worldId = this.appService.getWorldIdByDate();
  }

  /**
   * Uscita dalla pagina
   */
  onClickExit() {

    window.location.href = environment.gameUrl + '/exit';
  }

}
