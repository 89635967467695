 <!-- inizio header -->
<header *ngIf="!showPopupTutorial && !showPopupStart">

    <div class="base_brand" *ngIf="playId != '0'">
        <div class="contenitore_brand">
            <img [src]="brandLogoUrl" alt="">
        </div>
        <svg viewBox="0 0 308 236.1">
            <path d="M0,131.5c0,3.8,0.4,7.6,1.4,11.3c5.1,19.8,26,79.4,95.2,80.8c1.6,0,3.2,0,4.8-0.2c18.8-1.6,80.2-3.9,160.9-6c25.4-0.6,45.7-21.4,45.7-46.8V46.8C308,21,287,0,261.1,0H0V131.5z"/>
        </svg>
    </div>

    <svg viewBox="0 0 1107.3 236.1" class="base_header">
        <path d="M555.4,0c0,0-3.4,0-3.5,0C545,0,0,0,0,0v136.5c0,0,13.8,87.2,99.1,87.2c31.2-3.1,254.6-10.1,454.5-10.2c199.9,0,423.3,7.1,454.5,10.2c85.3,0,99.1-87.2,99.1-87.2V0C1107.3,0,562.2,0,555.4,0z"/>
    </svg>

    <div class="header_menu" [ngClass]="{'header_menu_demo': playId == '0'}">

        <div class="contatori_gioco">
            <div class="contatori_pallini">
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': countCorrette >= 1}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': countCorrette >= 2}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': countCorrette >= 3}"></div>
                <div class="contatore_singolo_pallino" [ngClass]="{'contatore_singolo_pallino_attivo': countCorrette >= 4}"></div>
            </div>
            <div class="contatori_gioco_risultato">Sequenze: <strong>{{countCorrette}} / 4</strong></div>
        </div>

        <div class="tasto_apri_menu" role="button" aria-label="Chiudi il gioco" (click)="clickClose()">
            <svg viewBox="0 0 24 24" class="close_menu">
                <path class="st0" d="M13.4,12l4.7-4.7C18.5,6.9,18.4,6.3,18,6s-1-0.4-1.3-0.1L12,10.6L7.3,5.9C6.9,5.5,6.3,5.6,6,6s-0.4,1-0.1,1.3l4.7,4.7l-4.7,4.7C5.5,17.1,5.6,17.7,6,18s1,0.4,1.3,0.1l4.7-4.7l4.7,4.7c0.3,0.3,0.9,0.3,1.3-0.1s0.4-1,0.1-1.3L13.4,12z"/>
            </svg>
        </div>

    </div>

</header>
<!-- fine header -->




<!-- inizio contenitore lampadine -->
<!-- NB. quando si compie un errore aggiungere la classe "lampadine_errore" a "contenitore_lampadine" -->
<div class="contenitore_lampadine" *ngIf="!showPopupTutorial && !showPopupStart" [ngClass]="{'lampadine_errore': !esitoSequenza && isErrore}">

    <!-- inizio lampadine da 3 -->
    <div class="lampadine_3" *ngIf="isPrimaSequenza">

        <div class="singola_lampadina singola_lampadina_1" [ngClass]="{'lampadina_accesa': lampadinaUno}">
            <img src="/assets/img/porta_lampada_rosso.png" aria-hidden="true" class="porta_lampada" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_2" [ngClass]="{'lampadina_accesa': lampadinaDue}">
            <img src="/assets/img/porta_lampada_verde.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_3" [ngClass]="{'lampadina_accesa': lampadinaTre}">
            <img src="/assets/img/porta_lampada_blu.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

    </div>
    <!-- fine lampadine da 3 -->


    <!-- inizio lampadine da 6 -->
    <div class="lampadine_6" *ngIf="!isPrimaSequenza">

        <div class="singola_lampadina singola_lampadina_1" [ngClass]="{'lampadina_accesa': lampadinaUno}">
            <img src="/assets/img/porta_lampada_rosso.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_2" [ngClass]="{'lampadina_accesa': lampadinaDue}">
            <img src="/assets/img/porta_lampada_verde.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_3" [ngClass]="{'lampadina_accesa': lampadinaTre}">
            <img src="/assets/img/porta_lampada_blu.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_4" [ngClass]="{'lampadina_accesa': lampadinaQuattro}">
            <img src="/assets/img/porta_lampada_giallo.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_5" [ngClass]="{'lampadina_accesa': lampadinaCinque}">
            <img src="/assets/img/porta_lampada_arancio.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

        <div class="singola_lampadina singola_lampadina_6" [ngClass]="{'lampadina_accesa': lampadinaSei}">
            <img src="/assets/img/porta_lampada_viola.png" class="porta_lampada" aria-hidden="true" alt="">
            <img class="singola_lampadina_accesa" src="/assets/img/lampadina_accesa.png" aria-hidden="true" alt="">
            <img src="/assets/img/lampadina_spenta.png" aria-hidden="true" alt="">
            <img class="singola_lampadina_errore" src="/assets/img/lampadina_errore.png" aria-hidden="true" alt="">
        </div>

    </div>
    <!-- fine lampadine da 3 -->

</div>
<!-- fine contenitore lampadine -->


<!-- inizio contenitore interruttori -->
<div class="contenitore_interruttori" *ngIf="!showPopupTutorial && !showPopupStart">
    
    <!-- inizio interruttori da 3 -->
    <div class="interruttori_3" *ngIf="isPrimaSequenza">

        <div class="singolo_interruttore singolo_interruttore_1" [ngClass]="{'singolo_interruttore_attivo': lampadinaUno}" (click)="clickInterruttore(1)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_2" [ngClass]="{'singolo_interruttore_attivo': lampadinaDue}" (click)="clickInterruttore(2)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_3" [ngClass]="{'singolo_interruttore_attivo': lampadinaTre}" (click)="clickInterruttore(3)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

    </div>
    <!-- fine interruttori da 3 -->


    <!-- inizio interruttori da 6 -->
    <div class="interruttori_6" *ngIf="!isPrimaSequenza">

        <div class="singolo_interruttore singolo_interruttore_1" [ngClass]="{'singolo_interruttore_attivo': lampadinaUno}" (click)="clickInterruttore(1)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_2" [ngClass]="{'singolo_interruttore_attivo': lampadinaDue}" (click)="clickInterruttore(2)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_3" [ngClass]="{'singolo_interruttore_attivo': lampadinaTre}" (click)="clickInterruttore(3)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div style="clear: both;"></div>

        <div class="singolo_interruttore singolo_interruttore_4" [ngClass]="{'singolo_interruttore_attivo': lampadinaQuattro}" (click)="clickInterruttore(4)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_5" [ngClass]="{'singolo_interruttore_attivo': lampadinaCinque}" (click)="clickInterruttore(5)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

        <div class="singolo_interruttore singolo_interruttore_6" [ngClass]="{'singolo_interruttore_attivo': lampadinaSei}" (click)="clickInterruttore(6)">
            <img class="interruttore_spento" src="/assets/img/interruttore_spento.svg" aria-hidden="true" alt="">
            <img src="/assets/img/interruttore_acceso.svg" aria-hidden="true" alt="">
        </div>

    </div>
    <!-- fine interruttori da 6 -->
    
</div>
<!-- fine contenitore interrutori -->

<!-- inizio pop messaggio -->
<!-- NB. quando si vuole mostrare un messaggio aggiungere la classe "pop_messaggio_visibile" a "pop_messaggio" -->
<div class="pop_messaggio" [ngClass]="{'pop_messaggio_visibile': mostraPopup}">
    <div class="pop_messaggio_frase" *ngIf="esitoSequenza">
        Complimenti!<br />Sequenza esatta!
    </div>
    <div class="pop_messaggio_frase" *ngIf="!esitoSequenza">
        Sequenza<br />non corretta!
    </div>
    <div class="pop_messaggio_contenitore_tasto">
        <div class="tasto_principale" *ngIf="esitoSequenza" (click)="continua()">CONTINUA</div>
        <div class="tasto_principale" *ngIf="!esitoSequenza" (click)="continua()">RITENTA</div>
    </div>
</div>
<!-- fine pop messaggio -->

<audio>
    <source src="../../assets/audio/interruttori.mp3">
</audio>


<!-- inizio pop gioco tutorial -->
<div class="gioco_tutorial" *ngIf="showPopupTutorial">

    <div class="pop_default">

        <div class="contenuto_principare_pop">
            
            <!-- inizio pillola -->
            <div class="popup_gioco_pillola" *ngIf="minipillola != null">
                
                <div class="sponsorizzazione">
                    Sponsorizzato da:
                </div>

                <div class="popup_gioco_pillola_brand">
                    <img [src]="brandLogoUrl" aria-hidden="true" alt="">
                </div>

                <ng-container *ngIf="minipillola.testo != ''">
                    <div class="popup_gioco_pillola_titolo">Pillole di sostenibilità</div>
                
                    <div class="popup_gioco_pillola_descrizione" [innerHTML]="minipillola.testo | safeHtml"></div>
                </ng-container>
                
            </div>
            <!-- fine pillola -->

            <div class="pop_default_titolo">Come si gioca</div>
            
            <div class="pop_default_descrizione">Spegni le lampadine rispettando la stessa sequenza che le ha accese</div>
            
            <div class="pop_default_foto_descrizione" style="margin-top: 10px;">
                <img src="/assets/img/gioco_lampadine/sequenza_lampadine.gif" width="160px" alt="esempio sequenza">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Le lampadine e gli interrutori sono nello stesso ordine e accomunati dallo stesso colore.</div>
            
            <div class="pop_default_foto_descrizione">
                <img src="/assets/img/gioco_lampadine/tutorial_lampadine_interruttori.jpg" width="240px" alt="gli interruttori">
            </div>
            
            <div class="pop_default_descrizione" style="margin-top: 15px;">Concludi positivamente 4 sequenze per vincere.<br><br></div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;" *ngIf="playId != '0'">
            <div class="tasto_principale next-slide" role="button" aria-label="Inizia il gioco" (click)="startGame()">GIOCA</div>
        </div>
        
        <div class="azioni_pop azioni_pop_tutorial" style="margin-top: 0;" *ngIf="playId == '0'">
            <div [routerLink]="'/elenco-minigiochi'" role="button" aria-label="Annulla" class="tasto_principale tasto_principale_outline">ANNULLA</div>
            <div (click)="startGame()" role="button" aria-label="Inizia il gioco" class="tasto_principale">GIOCA</div>
        </div>

    </div>
    
</div>
<!-- fine pop gioco tutorial -->

<!-- inizio pop gioco start -->
<div class="gioco_tutorial" *ngIf="showPopupStart" style="display: flex; justify-content: center; align-items: flex-start;">

    <div class="pop_default">
        
        <!-- inizio pillola -->
        <div class="popup_gioco_pillola" *ngIf="minipillola != null">
            
            <div class="sponsorizzazione">
                Sponsorizzato da:
            </div>

            <div class="popup_gioco_pillola_brand">
                <img [src]="brandLogoUrl" aria-hidden="true" alt="">
            </div>

            <ng-container *ngIf="minipillola.testo != ''">
                <div class="popup_gioco_pillola_titolo">Pillole di sostenibilità</div>
            
                <div class="popup_gioco_pillola_descrizione" [innerHTML]="minipillola.testo | safeHtml"></div>
            </ng-container>
            
        </div>
        <!-- fine pillola -->
        
        <div class="pop_default_titolo" *ngIf="playId == '0'">Allenati per la sfida</div>
        
        <div class="pop_default_titolo" *ngIf="playId != '0'">Il gioco sta per iniziare</div>
        
        <div class="contenuto_principare_pop">
            <img src="/assets/img/anteprima_minigioco_spegni_spreco.jpg" aria-hidden="true" alt="">
        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <div class="tasto_principale next-slide" role="button" aria-label="Inizia la partita" (click)="startGame()">INIZIA LA PARTITA</div>
        </div>

    </div>
    
</div>
<!-- fine pop gioco start -->


<!-- inizio pop gioco finito -->
<div class="pop_gioco_finito pop_gioco_finito_visibile" *ngIf="showPopupEnd">
    
    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Hai superato la prova</div>

            <div *ngIf="playId != '0'" class="pop_default_descrizione">
                Hai ottenuto una Margherita Verde e tiro di dado extra
            </div>

            <div class="pop_fine_gioco_immagine" style="margin-top: 20px;">
                <img [src]="appService.authUser?.avatar?.greatings"aria-label="Fantastico, gioco completato" aria-hidden="true" alt="">
            </div>

        </div>

        <div class="azioni_pop" style="margin-top: 0;">
            <a class="tasto_principale" [routerLink]="'/home'" role="link" aria-label="Continua">CONTINUA</a>
        </div>

    </div>
    
    <div class="pop_gioco_finito_bg"></div>
    
</div>
<!-- fine pop gioco finito -->
