import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Le sfide di Conad';

  constructor(public appService: AppService, public router: Router) {
    this.initializeApp();
  }

  initializeApp() {
    this.checkAuthUser();

    this.listnerChangePageSetup();
  }

  /**
   * Init utente già loggato
   */
  private checkAuthUser() {
    this.appService.getAuthUser();
  }

  /**
   * Funzione che tiene traccia di ogni cambio pagina
   */
  private listnerChangePageSetup() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let page = event.urlAfterRedirects;
      }
    });
  }
}
