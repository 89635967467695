import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fine-gioco',
  templateUrl: './fine-gioco.component.html',
  styleUrls: ['./fine-gioco.component.css']
})
export class FineGiocoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
