import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { UserPlayed } from 'src/app/interfaces/game';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-le-tue-giocate',
  templateUrl: './le-tue-giocate.component.html',
  styleUrls: ['./le-tue-giocate.component.css']
})
export class LeTueGiocateComponent implements OnInit {

  items: UserPlayed[] = [];

  constructor(
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {

    this.retrieveUserPlayed();
  }

  async retrieveUserPlayed() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.gameList("played"));

      this.items = data.sort((a,b) => (a.resultTimestamp >= b.resultTimestamp) ? -1 : 1);
      
    } catch (error) {
      
      this.alert.presentAlertError();

    } finally {

      this.loading.dismiss();
    }
  }
}
