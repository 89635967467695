import { Component, OnInit, Renderer2, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Position } from 'src/app/interfaces/position';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from 'src/app/loading/loading.service';
import { LocalService } from 'src/app/services/local.service';
import { Router } from '@angular/router';
import { Avatar, KEY_VIDEO_INTRO_MONDO_3, KEY_VIDEO_INTRO_MONDO_4, ResponseGameRoll, ResultPopupHome, TypeMiniGame, TypeResultPlay } from 'src/app/interfaces/game';
import { AlertService } from 'src/app/alert/alert.service';
import { AppService } from 'src/app/services/app.service';
import { lastValueFrom } from 'rxjs';
import { Coupon } from 'src/app/interfaces/coupon';
import { BRAND_LOGO_BARILLA, BRAND_LOGO_MULINO_BIANCO } from 'src/app/interfaces/brand';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  // animazioni
  animations: [
    trigger('moveAvatar', [
      // posizione iniziale
      state('begin', style({
        transform: "translate3d({{x}}px, {{y}}px, 0)"
      }),{params: {x: 0, y: 0}}),
      // posizione fittizia di inizio
      state('start', style({
        transform: "translate3d({{x}}px, {{y}}px, 0)"
      }),{params: {x: 0, y: 0}}),
      // posizione fittizia di fine
      state('finish', style({
        transform: "translate3d({{x}}px, {{y}}px, 0)"
      }),{params: {x: 0, y: 0}}),
      // animazione vuota per posizionamento iniziale
      transition('* => begin', []),
      // animazione per spostamento tra caselle
      transition('* => *', [
        animate('{{ time }}'),
      ]),
    ])
  ],
})
export class HomePageComponent implements OnInit,OnDestroy {

  @ViewChild('videoDado') videoDado!: ElementRef;

  // flag per mostrate tutto dopo il load
  showAll: boolean = false;
  // flag per mostrare footer
  showFooter: boolean = true;

  // numero mondo
  currentMondo: number = 0;
  // numero livello
  currentLivello: number = 0;
  // numero casella corrente
  currentPosition: number = 0;
  // orientamento avatar
  currentOrientation: number = 0;
  // punti sostenibilità utente
  puntiSostenibilita: number = 0;

  // array caselle
  positions: Array<Position> = [];

  // casella finale
  casellaFinale: number = 1;

  // flag per mostrare pedina
  showPedina: boolean = false;

  // flag per attivazione animazioni
  isAnimated: any = 'begin';

  // flag per pedina in movimento
  isMoving: boolean = false;

  // velocità animazioni
  animationSpeed: string = '600ms linear';
  // posizione x e y pedina
  x: number = 0;
  y: number = 0;

  // mappatura caselle
  jsonMappa: any;

  // array animazioni attive
  animations: Array<any> = [];

  // valori dado
  minDado: number = 3;
  maxDado: number = 6;
  risultatoDado: number = 0;

  // flag per far comparire modale dado
  showPopupDado: boolean = false;
  showProcediDado: boolean = false;

  // audio casella
  suonoCasella: any = null;

  // tiri dado disponibili
  tiriDado: number = 0;

  // avatar scelto
  avatar: Avatar | null = null;

  // tipologia popup casella
  typePopup: number = 0;

  // tipologia minigioco
  typeMinigioco: number = 0;

  // risposta chiamata tiro del dado
  responseGameRoll: ResponseGameRoll | null = null;

  playId: string = ""; // Identificatico giocata

  brandLogoUrl: string = ""; // URL brand associato al mini-gioco

  // eventuale coupon assegnato dopo il tiro del dado
  coupon: Coupon | undefined | null;

  // flag per div video intro mondo 3
  showVIdeoIntroMondo3: boolean =  true;
  showVIdeoIntroMondo4: boolean =  true;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private localService: LocalService,
    private router: Router,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
  ) {

  }

  ngOnDestroy(): void {
    
    // unload del suono per liberare memoria
    this.suonoCasella?.pause();

    // imposto movimento a false
    this.isMoving = false;
  }

  ngOnInit(): void {

    this.currentMondo = (this.appService.authUser) ? this.appService.authUser.info.worldId : 0;

    this.retrieveStatusMondo();

    this.showVIdeoIntroMondo3 = (this.local.getData(KEY_VIDEO_INTRO_MONDO_3) == '1') ? false : true;
    this.showVIdeoIntroMondo4 = (this.local.getData(KEY_VIDEO_INTRO_MONDO_4) == '1') ? false : true;

    if(this.currentMondo == 3 && this.showVIdeoIntroMondo3) {

      this.hideVideo();
    }

    if(this.currentMondo == 4 && this.showVIdeoIntroMondo4) {
      
      this.hideVideo2();
    }
  }

  /**
   * Recupera lo status dal server
   * @returns 
   */
  async retrieveStatusMondo()
  {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.gameStatus(this.currentMondo));

      this.loading.dismiss();

      this.avatar = data.avatar;

      if (this.appService.authUser != null) {

        this.appService.authUser.avatar = this.avatar;

        this.appService.authUser.user_world = {
          pointSustainability: data.user_world.pointSustainability
        }

        this.appService.saveAuthUser();
      }

      this.currentLivello = data.user_world.gameLevel;

      this.currentPosition = data.user_world.positionCurrent;

      this.puntiSostenibilita = data.user_world.pointSustainability;

      this.tiriDado = data.count_pending;

      this.initMappa();

    } catch (error) {

      this.loading.dismiss();
      
      const res = await this.alert.presentConfirm("Controlla la tua connessione Internet", "RIPROVA", "ANNULLA");

      if (res) {

        this.retrieveStatusMondo();
      }
    }
  }

  /**
   * 
   */
  async hideVideo() {

    this.local.saveData(KEY_VIDEO_INTRO_MONDO_3, '1');

    await this.delay(4000);

    this.showVIdeoIntroMondo3 = false;
  }

  /**
   * 
   */
  async hideVideo2() {

    this.local.saveData(KEY_VIDEO_INTRO_MONDO_4, '1');

    await this.delay(4000);

    this.showVIdeoIntroMondo4 = false;
  }

  initMappa() {

    // popolo il percorso corrente
    this.populatePositions();
  }

  // funzione per popolare il percorso
  private populatePositions() {

    // decodifico JSON mappatura caselle
    this.http.get('assets/mappe/mappa'+this.currentMondo+'.json').subscribe((res) => {
      this.jsonMappa = res;

      // scorre le caselle nel JSON
      for (let prop in this.jsonMappa.caselle) {
        // inizializzo nuova casella
        var position: Position = {
          id: this.jsonMappa.caselle[prop].id,
          mondo: this.jsonMappa.caselle[prop].mondo,
          livello: this.jsonMappa.caselle[prop].livello,
          casella: this.jsonMappa.caselle[prop].casella,
          x: -((this.jsonMappa.caselle[prop].x - 3000)/2),
          y: -((this.jsonMappa.caselle[prop].y - 3000)/2) + 100,
          casella_x: -((this.jsonMappa.caselle[prop].casella_x - 3000)/2),
          casella_y: -((this.jsonMappa.caselle[prop].casella_y - 3000)/2) + 100,
          is_big: this.jsonMappa.caselle[prop].is_big,
          orientation: this.jsonMappa.caselle[prop].orientation,
          speed: this.jsonMappa.caselle[prop].speed,
          animations: this.jsonMappa.caselle[prop].animations
        };
        // inserisco nell'array caselle
        this.positions.push(position);
      }

      // recupero caselle livello
      var caselleLivello = this.positions.filter(position => position.mondo == this.currentMondo && position.livello == this.currentLivello);
      // casella finale percorso
      this.casellaFinale = caselleLivello[caselleLivello.length - 1].casella;
      
      // imposto posizione casella iniziale
      this.setCasella(this.currentPosition, this.currentMondo, this.currentLivello);
  
      // mostro pedina
      this.showPedina = true;

      // flag per mostrate tutto dopo il load
      this.showAll = true;

      // creo oggetti sul percorso
      this.createDivMappa();
    });
  }

  // funzione per creare oggetti sul livello
  private createDivMappa() {

    // rimuovo i div delle caselle del livello precedente
    const elements: Element[] = Array.from(document.getElementsByClassName("singola_casella_attiva"));
    elements.forEach(element => {
      this.renderer.removeChild(document.getElementById("caselle_attive"),element);
    });

    // elimino div animazioni livello precedente
    this.animations.forEach(element => {
      const elements: Element[] = Array.from(document.getElementsByClassName(element));
      elements.forEach(element2 => {
        this.renderer.removeChild(document.getElementById("base_percorso"),element2);
      });
    });

    // elimino logo livello precedente
    if(document.getElementById('logo_rotante'+(this.currentLivello-1))) {
      this.renderer.removeChild(document.getElementById("base_percorso"),document.getElementById('logo_rotante'+(this.currentLivello-1)));
    }

    // elimino logo livello successivo
    if(document.getElementById('logo_rotante'+(this.currentLivello+1))) {
      this.renderer.removeChild(document.getElementById("base_percorso"),document.getElementById('logo_rotante'+(this.currentLivello+1)));
    }

    // decodifico JSON mappatura caselle
    this.http.get('assets/mappe/mappa'+this.currentMondo+'.json').subscribe((res) => {
      this.jsonMappa = res;

      // scorre le caselle nel JSON
      for (let prop in this.jsonMappa.caselle) {
        if(this.jsonMappa.caselle[prop].mondo == this.currentMondo && this.jsonMappa.caselle[prop].livello == this.currentLivello) {

          // creazione div casella illuminata
          this.createCasellaDaIlluminare(this.jsonMappa.caselle[prop].id, this.jsonMappa.caselle[prop].casella, this.jsonMappa.caselle[prop].casella_x, this.jsonMappa.caselle[prop].casella_y, this.jsonMappa.caselle[prop].is_big);
          
          // scorro le animazioni presenti nelle singole caselle
          for (let prop2 in this.jsonMappa.caselle[prop].animations) {
            // creo l'oggetto da animare
            this.createObject(prop2, this.jsonMappa.caselle[prop].animations[prop2]);

            // recupero caselle livello
            var currentCasella = this.positions.filter(position => position.casella == this.currentPosition && position.mondo == this.currentMondo && position.livello == this.currentLivello);
            
            // se la casella è già stata superata
            if(typeof currentCasella[0] != 'undefined' && this.jsonMappa.caselle[prop].id <= currentCasella[0].id) {
              var classes = prop2.split(" ");
              for (let prop3 in classes) {
                // recupero il div tramite id
                const parent = document.getElementById(classes[prop3])
                // se esiste
                if(parent) {
                  // inserisco animazione nell'array
                  this.animations.push(classes[prop3]);
                  // inserisco animazione
                  this.renderer.setStyle(parent, 'animation-duration', this.jsonMappa.caselle[prop].animations[prop2].animation_duration+"ms");
                  this.renderer.setStyle(parent, 'animation-fill-mode', this.jsonMappa.caselle[prop].animations[prop2].animation_fill_mode);
                  this.renderer.setStyle(parent, 'animation-name', this.jsonMappa.caselle[prop].animations[prop2].animation_name);
                  this.renderer.setStyle(parent, 'animation-iteration-count', this.jsonMappa.caselle[prop].animations[prop2].animation_iteration_count);
                  this.renderer.setStyle(parent, 'animation-timing-function', this.jsonMappa.caselle[prop].animations[prop2].animation_timing_function);
                }
              }
            }
          }

          // se si tratta della casella finale
          if(this.jsonMappa.caselle[prop].casella == this.casellaFinale) {

            // inizializzo div
            const object = this.renderer.createElement('div');
            // aggiungo classe
            this.renderer.addClass(object, 'logo_rotante');
            // imposto id logo rotante
            this.renderer.setAttribute(object, 'id', 'logo_rotante'+this.currentLivello);
            // sposto div
            if(this.currentMondo == 2 || this.currentMondo == 3) {
              this.renderer.setStyle(object, 'transform', "translate3d("+(this.jsonMappa.caselle[prop].casella_x +2)+"px, "+(this.jsonMappa.caselle[prop].casella_y - 53)+"px, 0)");
            } else {
              this.renderer.setStyle(object, 'transform', "translate3d("+(this.jsonMappa.caselle[prop].casella_x - 13)+"px, "+(this.jsonMappa.caselle[prop].casella_y - 60)+"px, 0)");
            }
            // recupero il div nel gioco tramite id
            const parent = document.getElementById("base_percorso");
            // aggiungo il div appena creato
            this.renderer.appendChild(parent, object);
          }
        }
      }

    });
  }

  // trigger allo swipe
  onSwipe(event: any) {

    // se gioco non attivo e se popup dado nascosta
    if(!this.isMoving && !this.showPopupDado) {

      // swipe sinistra
      if (event.direction == Hammer.DIRECTION_LEFT) {
  
        // lancio evento apertura menu
        this.localService.changeStatusMenu.emit(true);
      }
  
      // swipe destra
      if (event.direction == Hammer.DIRECTION_RIGHT) {
  
        // lancio evento chiusura menu
        this.localService.changeStatusMenu.emit(false);
      }
    }
  }

  // funzione per piazzare pedina su una casella
  async setCasella(casella: number, mondo: number, livello: number) {
    
    // recupero casella
    var position = this.positions.filter(position => position.casella == casella && position.mondo == mondo && position.livello == livello);
    
    if(position.length) {
      // imposto casella corrente
      this.currentPosition = casella;

      // recupero orientamento per casella successiva
      var orientationEnd = this.positions.filter(search => search.id == (position[0].id + 1));
      
      // parametri per animazione
      if(orientationEnd.length) {
        this.currentOrientation = orientationEnd[0].orientation;
      } else{
        this.currentOrientation = position[0].orientation;
      }
      this.animationSpeed = '0ms linear';
      this.x = position[0].x;
      this.y = position[0].y;
      this.currentLivello = position[0].livello;
    }
    
    // sleep
    await this.delay(500);
    
    if (position.length && this.casellaFinale != this.currentPosition) {
      
      // illumina casella corrente
      this.illuminaCasella(position[0].id, casella, 0, true);
    }
  }

  // funzione per spostare pedina
  async move(end: number) {

    // flag per pedina in movimento
    this.isMoving = true;

    // preparo variabile per posizione finale
    var posizioneFinale = this.currentPosition;

    // recupero casella corrente
    var idCorrente = this.positions.filter(position => position.casella == this.currentPosition && position.mondo == this.currentMondo && position.livello == this.currentLivello);
    // recupero casella finale
    var positionEnd = this.positions.filter(position => position.casella == end && position.mondo == this.currentMondo && position.livello == this.currentLivello);

    if(idCorrente.length && positionEnd.length) {
      // ciclo le caselle intermedie
      for(var i = idCorrente[0].id + 1; i <= positionEnd[0].id; i++) {
        
        // cambio status per trigger animazione
        this.isAnimated = !this.isAnimated;
  
        // recupero prossima casella
        var position = this.positions.filter(position => position.id == i && position.mondo == this.currentMondo && position.livello == this.currentLivello);
        
        if(position.length) {
          
          // parametri per animazione
          if(positionEnd[0].id != i) {
            this.animationSpeed = position[0].speed+'ms linear';
          } else {
            this.animationSpeed = position[0].speed+'ms linear';
          }
          this.currentOrientation = position[0].orientation;
          this.x = position[0].x;
          this.y = position[0].y;
          this.currentLivello = position[0].livello;
          // controllo animazioni livello
          for (let prop in position[0].animations) {
            var classes = prop.split(" ");
            for (let prop2 in classes) {
              // recupero il div tramite id
              const parent = document.getElementById(classes[prop2])
              // se esiste
              if(parent) {
                // inserisco animazione
                this.renderer.setStyle(parent, 'animation-duration', position[0].animations[prop].animation_duration+"ms");
                this.renderer.setStyle(parent, 'animation-fill-mode', position[0].animations[prop].animation_fill_mode);
                this.renderer.setStyle(parent, 'animation-name', position[0].animations[prop].animation_name);
                this.renderer.setStyle(parent, 'animation-iteration-count', position[0].animations[prop].animation_iteration_count);
                this.renderer.setStyle(parent, 'animation-timing-function', position[0].animations[prop].animation_timing_function);
              }
            }
          }
            
          // illumino casella
          this.illuminaCasella(position[0].id, position[0].casella, position[0].speed);

          if(position[0].casella) {
            // aggiorno casella corrente
            this.currentPosition = position[0].casella;
          }
          
          // attesa per conclusione animazione
          if(positionEnd[0].id != i) {
            await this.delay(position[0].speed);
          }
    
          // aggiorno casella finale
          posizioneFinale = position[0].casella;
        }
      }
  
      // aggiorno casella corrente
      this.currentPosition = posizioneFinale;

      // recupero orientamento per casella successiva
      var orientationEnd = this.positions.filter(search => search.id == (positionEnd[0].id + 1));
      
      // parametri per orientamento pedina
      if(orientationEnd.length) {
        await this.delay(orientationEnd[0].speed);
        this.currentOrientation = orientationEnd[0].orientation;
      } else{
        await this.delay(positionEnd[0].speed);
        this.currentOrientation = positionEnd[0].orientation;
      }
    }
  }

  // funzione per mostrare la popup premio
  async showPopupPremio(type: number) {

    // se type valorizzato
    if (!type) { 

      return;
    }

    // sleep
    await this.delay(1000);

    // se tipologia editoriale
    if (type == ResultPopupHome.CONTENT) {
      
      // mostro pagina del contenuto editoriale
      this.router.navigateByUrl('/contenuto-editoriale', {
        state: {
          item: this.responseGameRoll?.content
        }
      });
    }
  
    // nascondo il footer
    this.showFooter = false;

    // mostro popup
    this.typePopup = type;
  }

  // funzione per chiudere popup premio
  closePremio(action?: string) {

    // nascondo popup premio
    this.typePopup = 0;

    this.typeMinigioco = 0;

    // flag pedina in movimento
    this.isMoving = false;
    
    // mostro il footer
    this.showFooter = true;

    window.scrollTo(0, 0); // reset scroll

    // se fine livello
    if (action == 'fine') {
      
      // mostro pagina quiz
      this.router.navigate(['gioco-quiz/' + this.playId], {
        queryParams: {
          isFineLivello: 1,
          livello: this.currentLivello,
          mondo: this.currentMondo
        }
      });
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  /**
   * Click su tasto per tirare il dado
   */
  async tiraDado() {

    this.loading.present();

    try {

      this.responseGameRoll = await lastValueFrom(this.appService.gameRoll(this.currentMondo));

      this.tiriDado--;

      // risultato dado casuale
      this.risultatoDado = this.responseGameRoll.user_played.numberResult;

      this.playId = this.responseGameRoll.user_played.playId;

      this.animazioneTiroDado();
      
    } catch (error) {
      
      this.alert.presentAlertError((error as HttpErrorResponse).error['error']);

    } finally {

      this.loading.dismiss();
    }
  }

  /**
   * Animazione tiro dado
   */
  private async animazioneTiroDado() {

    // nacondo il footer
    this.showFooter = false;

    // comando per far comparire modale con video già scelto
    this.showPopupDado = true;

    // faccio play del dado
    this.videoDado.nativeElement.src = "https://conad.s3.eu-south-1.amazonaws.com/assets/video/dado/dado_" + this.risultatoDado + ".mp4";
    
    await this.delay(700);
    
    this.videoDado.nativeElement.play();

    // attendo fine video dado
    await this.delay(2100);

    // comando per far comparire tasto pocedi sulla modale
    this.showProcediDado = true;
  }

  /**
   * Click tasto PROCEDI dopo il tiro del dado.
   * Questa funzione server per far avanzare la pedina.
   */
  async closeDado() {

    // comando per far scomparire modale con video già scelto
    this.showPopupDado = false;

    // comando per far scomparire tasto pocedi sulla modale
    this.showProcediDado = false;

    // mostro il footer
    this.showFooter = true;

    // calcolo numero casella dove andare
    var casellaFinale = this.currentPosition + this.risultatoDado;

    // flag per percorso concluso
    var percorsoConcluso = false;

    // se la casella supera l'ultima
    if(casellaFinale >= this.casellaFinale) {
      casellaFinale = this.casellaFinale;
      percorsoConcluso = true;
    }

    // muovo la pedina
    await this.move(casellaFinale);
    
    // se concludo il percorso
    if (percorsoConcluso) {

      // mostro la popup fine livello
      this.showPopupPremio(ResultPopupHome.FINE_LIVELLO);
      
    } else {

      // resetto il logo del brand
      this.brandLogoUrl = "";

      // resetto i dati del coupon
      this.coupon = null;

      // controllo presenza premio su casella di arrivo
      let type = 0;

      switch (this.responseGameRoll?.user_played.resultType) {

        case TypeResultPlay.RESULT_GIOCO:
          type = ResultPopupHome.MINI_GIOCO;

          // tipologia minigioco
          this.typeMinigioco = this.responseGameRoll.user_played.gameId;

          // Mondo 1°
          if (this.currentMondo == 1) {

            if (
              this.typeMinigioco == TypeMiniGame.RACCOLTA_DIFFERENZIATA ||
              this.typeMinigioco == TypeMiniGame.SPEGNI_LO_SPRECO
            ) {

              this.brandLogoUrl = BRAND_LOGO_MULINO_BIANCO;
              
            } else if (this.typeMinigioco == TypeMiniGame.SQUALO_IN_MARE) {

              this.brandLogoUrl = BRAND_LOGO_BARILLA;
            }
          }

          break;

        case TypeResultPlay.RESULT_COUPON:
          type = ResultPopupHome.COUPON;

          // dettaglio coupon
          this.coupon = this.responseGameRoll.coupon;
          break;

        case TypeResultPlay.RESULT_CONTENUTO_EDITORIALE:
          type = ResultPopupHome.CONTENT;
          break;

        default:
          this.isMoving = false;
          break;
      }

      this.showPopupPremio(type);
    }
  }

  // funzione per creare div casella da illuminare
  private createCasellaDaIlluminare(id:number, casella: number, casella_x: number, casella_y: number, is_big: boolean) {

    // controllo presenza parametro
    if(casella && (casella_x != 0 || casella_y != 0)) {

      // inizializzo immagine
      const casellaDiv = this.renderer.createElement('img');
      // aggiungo src immagine
      this.renderer.setAttribute(casellaDiv, 'src', 'https://conad.s3.eu-south-1.amazonaws.com/assets/img/home/casella_attiva.png');
      // aggiungo attributi accessibilità
      this.renderer.setAttribute(casellaDiv, 'aria-hidden', 'true');
      this.renderer.setAttribute(casellaDiv, 'role', 'none');
      this.renderer.setAttribute(casellaDiv, 'alt', '');
      // aggiungo style immagine
      this.renderer.setStyle(casellaDiv, 'transform', "translate3d("+casella_x+"px, "+casella_y+"px, 0)");
      // aggiungo classi immagine
      this.renderer.addClass(casellaDiv, 'singola_casella_attiva');
      this.renderer.addClass(casellaDiv, 'singola_casella_attiva_'+casella);
      if(this.currentMondo == 2 || this.currentMondo == 3) {
        this.renderer.addClass(casellaDiv, 'singola_casella_attiva_mondo_2');
      }
      if(is_big) {
        if(this.currentMondo == 2 || this.currentMondo == 3) {
          this.renderer.addClass(casellaDiv, 'singola_casella_attiva_mondo_2_big');
        } else {
          this.renderer.addClass(casellaDiv, 'singola_casella_attiva_big');
        }
      }
      // imposto id immagine
      this.renderer.setAttribute(casellaDiv, 'id', 'id'+(id));
  
      // recupero il div delle caselle tramite id
      const parent = document.getElementById("caselle_attive");
      
      // aggiungo tag img al div appena creato
      this.renderer.appendChild(parent, casellaDiv);
    }
  }

  // funzione per creare div oggetti da animare sucessivamente
  private createObject(id: string, params: any) {
    
    // controllo presenza parametro
    if(params) {

      // inizializzo div
      const object = this.renderer.createElement('div');
      // splito le classi
      var classes = id.split(" ");
      // scorro classi
      for (let prop in classes) {
        // aggiungo classe
        this.renderer.addClass(object, classes[prop]);
        // aggiungo id
        this.renderer.setAttribute(object, 'id', classes[prop]);
      }
      // sposto div
      this.renderer.setStyle(object, 'transform', "translate3d("+params.x+"px, "+params.y+"px, 0)");

      // recupero il div nel gioco tramite id
      const parent = document.getElementById("base_percorso");
      
      // aggiungo src al div appena creato
      this.renderer.appendChild(parent, object);
    }
  }

  // illumino una casella del percorso e spengo la precedente
  private async illuminaCasella(id:number, casella: number, speed: number, posizionamento?: boolean) {

    if (!this.isMoving && !posizionamento) {
      return;
    }
    
    // sleep
    await this.delay(speed/2);

    // recupero il div casella precedente tramite id
    const casellaDivPrecedente = document.getElementById('id'+(id-1))
    
    // se esiste casella precedente
    if(casellaDivPrecedente) {
    
      // rimuove classe che illumina casella
      this.renderer.removeClass(casellaDivPrecedente, 'singola_casella_attiva_visibile');
    }
    
    // controllo presenza parametro
    if(casella) {
      
      // recupero il div della casella tramite id
      const casellaDiv = document.getElementById('id'+(id))
      
      if(casellaDiv) {

        // aggiungo classe per illuminare casella
        this.renderer.addClass(casellaDiv, 'singola_casella_attiva_visibile');
            
        // se c'è movimento
        if(speed) {
  
          // inizializzo suono casella
          this.suonoCasella = new Audio();
          this.suonoCasella.src = "assets/audio/tasto_illuminato.mp3";
          this.suonoCasella.load();
          
          // riproduco suono
          this.suonoCasella.play();
        }
      }
    }
  }
}
