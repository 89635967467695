import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { BRAND_LOGO_MULINO_BIANCO } from 'src/app/interfaces/brand';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { KEY_TUTORIAL_RACCOLTA, Rifiuto, RifiutoType, RifiutoTypes } from 'src/app/interfaces/rifiuto';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-gioco-raccolta-differenziata',
  templateUrl: './gioco-raccolta-differenziata.component.html',
  styleUrls: ['./gioco-raccolta-differenziata.component.css']
})
export class GiocoRaccoltaDifferenziataComponent implements OnInit {

  playId: string = "0"; // ID giocata

  brandLogoUrl: string = "";

  // tipologie rifiuti
  rifiutiTypes: Array<RifiutoType> = []

  // rifiuti da buttare
  rifiuti: Array<Rifiuto> = [];
  // prossimo rifiuto
  nextRifiuti: Array<Rifiuto> = [];
  // counter rifiuti generati
  counterOggetti: number = 0;
  // velocità rifiuti di percorrenza a schermo
  speedRifiuti: number = 5000;
  // punteggio
  score: number = 0;
  // obiettivo da raggiungere
  scoreObiettivo: number = 10;
  // flag per gioco in corso
  gameActive: boolean = true;
  // flag per blocco funzioni di swipe
  blockSwipe: boolean = false;
  // posizione rifiuto corrente
  currentRifiutoPosition: number = 0;

  // flag per mostrare popup tutorial
  showPopupTutorial: boolean = true;
  // flag per mostrare popup start
  showPopupStart: boolean = false;
  // flag per mostrare popup fine gioco
  showPopupEnd: boolean = false;
  // flag per mostrare successo/errore
  showSuccess: boolean = false;
  showError: boolean = false;
  successMessage: number = 1;

  minipillola: Minipillola | null = null;

  constructor(
    public renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
  ) { }

  ngOnInit(): void {

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log("playId: " + this.playId);

    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {

      this.brandLogoUrl = BRAND_LOGO_MULINO_BIANCO;
    
    } else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.RACCOLTA_DIFFERENZIATA);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }

    // tipologie rifiuti
    this.rifiutiTypes = RifiutoTypes;

    var tutorialAlreadyShown = this.local.getData(KEY_TUTORIAL_RACCOLTA);

    if(tutorialAlreadyShown == "1" && this.playId != "0") {
      this.showPopupTutorial = false;
      this.showPopupStart = true;
    }
  }

  startGame() {

    this.local.saveData(KEY_TUTORIAL_RACCOLTA, "1");

    // nasconde popup tutorial
    this.showPopupTutorial = false;
    this.showPopupStart = false;

    // genera rifiuto successivo
    this.createNextRifiuto();
    // genera rifiuto a schermo
    this.createRifiuto();
    // check delle collisioni
    this.detectPosition();
    // funzione garbage collector oggetti
    this.destroyLastElement();
  }

  // trigger allo swipe
  onSwipe(event: any) {

    // se il gioco è attivo e lo swipe non è bloccato
    if(this.gameActive && !this.blockSwipe) {

      // swipe sinistra
      if (event.direction == Hammer.DIRECTION_LEFT) {
  
        this.spostaSinistra();
      }
      
      // swipe destra
      if (event.direction == Hammer.DIRECTION_RIGHT) {
        
        this.spostaDestra();
      }
  
      // swipe giù
      if (event.direction == Hammer.DIRECTION_DOWN) {
  
        // accelera il pezzo per la discesa
        this.mandaGiu();
      }
    }
  }

  // tap sullo schermo
  onTap(direction: string) {

    // se il gioco è attivo e lo spostamento non è bloccato
    if(this.gameActive && !this.blockSwipe) {

      // tap sinistra
      if (direction == 'left') {
    
        this.spostaSinistra();
      }
  
      // tap destra
      if (direction == 'right') {
    
        this.spostaDestra();
      }
    }
  }

  // crea il successivo rifiuto
  createNextRifiuto() {
    
    // aumento numero oggetti generati
    this.counterOggetti++;

    // shuffle delle tipologie
    let types = this.rifiutiTypes.sort(() => Math.random() - 0.5);

    // aggiungo oggetto nell'array
    this.nextRifiuti.push({id: this.counterOggetti, type: types[0].id, image: types[0].image, bidone: types[0].bidone, position: 1});
  }

  // genera rifiuto a schermo
  async createRifiuto() {

    // numero casuale tra 1 e 3
    var min = 1;
    var max = 4;
    var position = Math.floor(Math.random() * (max - min) + min);

    // creo div contenitore
    const contenitoreRifiuto = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(contenitoreRifiuto, 'contenitore_spazzatura_movimento');
    // assegno un id
    this.renderer.setAttribute(contenitoreRifiuto, 'id', 'id'+(this.nextRifiuti[0].id));

    // creo div contenitore
    const rifiuto = this.renderer.createElement('div');
    // aggiungo classe al div
    this.renderer.addClass(rifiuto, 'spazzatura');
    // assegno un id
    this.renderer.setAttribute(rifiuto, 'id', 'child'+(this.nextRifiuti[0].id));
    // inizializzo immagine
    const immagineRifiuto = this.renderer.createElement('img');
    // aggiungo src immagine
    this.renderer.setAttribute(immagineRifiuto, 'src', this.nextRifiuti[0].image);
    // aggiungo tag img al div appena creato
    this.renderer.appendChild(rifiuto, immagineRifiuto);
    
    // sposto oggetto in base al parametro
    var animationName = "";
    switch (position) {
      case 1:
        animationName = "spostamento_elementi_sx";
        break;
      case 2:
        animationName = "spostamento_elementi_centrale";
        break;
      case 3:
        animationName = "spostamento_elementi_dx";
        break;
    
      default:
        break;
    }
    // aggiungo style al div
    this.renderer.setStyle(rifiuto, 'animation-name', animationName);
    this.renderer.setStyle(rifiuto, 'animation-iteration-count', "1");
    this.renderer.setStyle(rifiuto, 'animation-timing-function', "linear");
    this.renderer.setStyle(rifiuto, 'animation-duration', this.speedRifiuti+"ms");
    this.renderer.setStyle(rifiuto, 'animation-fill-mode', "forwards");

    // aggiungo oggetto nell'array
    this.rifiuti.push({id: this.nextRifiuti[0].id, type: this.nextRifiuti[0].type, image: this.nextRifiuti[0].image, bidone: this.nextRifiuti[0].bidone, position: position});

    // tolgo elemento da array successivi pezzi
    this.nextRifiuti.splice(0, 1);

    // recupero il div nel gioco tramite id
    const parent = document.getElementById("contenitore_gioco")

    // posizione rifiuto corrente
    this.currentRifiutoPosition = position;

    // inserisco il div nel gioco
    this.renderer.appendChild(contenitoreRifiuto, rifiuto);
    this.renderer.appendChild(parent, contenitoreRifiuto);

    // preparo nuovo rifiuto
    this.createNextRifiuto();

    // sleep
    await this.delay(500);
    // scblocco funzioni swipe
    this.blockSwipe = false;
  }

  // funzione per spostare rifiuto corrente a sinistra
  async spostaSinistra() {

    this.blockSwipe = true;

    // recupero il div nel gioco tramite id
    const rifiutoDiv = document.getElementById("id"+this.rifiuti[0].id);

    // rimuovo la classe se presente
    if(rifiutoDiv?.classList.contains("spostamento_neutro")) {
      this.renderer.removeClass(rifiutoDiv, "spostamento_neutro");
    }
    
    // sposto oggetto in base al parametro
    var animationName = "";
    switch (this.rifiuti[0].position) {
      case 1:
        break;
      case 2:
        if(rifiutoDiv?.classList.contains("spostamento_dx")) {
          this.renderer.removeClass(rifiutoDiv, "spostamento_dx");
          animationName = 'spostamento_neutro';
        } else {
          if(rifiutoDiv?.classList.contains("spostamento_sx")) {
            animationName = "spostamento_sx_2";
          } else {
            animationName = "spostamento_sx";
          }
        }
        break;
      case 3:
        if(rifiutoDiv?.classList.contains("spostamento_dx_2")) {
          this.renderer.removeClass(rifiutoDiv, "spostamento_dx_2");
        } else if(rifiutoDiv?.classList.contains("spostamento_dx")){
          this.renderer.removeClass(rifiutoDiv, "spostamento_dx");
          animationName = 'spostamento_neutro';
        } else {
          animationName = "spostamento_sx";
        }
        break;
    }
    // calcolo nuova posizione rifiuto corrente
    this.rifiuti[0].position = (this.rifiuti[0].position != 1)?(this.rifiuti[0].position - 1):1;

    // posizione rifiuto corrente
    this.currentRifiutoPosition = this.rifiuti[0].position;

    // aggiungo classe
    if(animationName) {
      this.renderer.addClass(rifiutoDiv, animationName);
    }

    await this.delay(300);
    
    this.blockSwipe = false;
  }

  // funzione per spostare a destra rifiuto corrente
  async spostaDestra() {

    this.blockSwipe = true;

    // recupero il div nel gioco tramite id
    const rifiutoDiv = document.getElementById("id"+this.rifiuti[0].id);

    // rimuovo classe se presente
    if(rifiutoDiv?.classList.contains("spostamento_neutro")) {
      this.renderer.removeClass(rifiutoDiv, "spostamento_neutro");
    }
    
    // sposto oggetto in base al parametro
    var animationName = "";
    switch (this.rifiuti[0].position) {
      case 1:
        if(rifiutoDiv?.classList.contains("spostamento_sx_2")) {
          this.renderer.removeClass(rifiutoDiv, "spostamento_sx_2");
        } else if(rifiutoDiv?.classList.contains("spostamento_sx")){
          this.renderer.removeClass(rifiutoDiv, "spostamento_sx");
          animationName = 'spostamento_neutro';
        } else {
          animationName = "spostamento_dx";
        }
        break;
      case 2:
        if(rifiutoDiv?.classList.contains("spostamento_sx")) {
          this.renderer.removeClass(rifiutoDiv, "spostamento_sx");
          animationName = 'spostamento_neutro';
        } else {
          if(rifiutoDiv?.classList.contains("spostamento_dx")) {
            animationName = "spostamento_dx_2";
          } else {
            animationName = "spostamento_dx";
          }
        }
        break;
      case 3:
        break;
    }
    // aggiorno posizione rifiuto corrente
    this.rifiuti[0].position = (this.rifiuti[0].position != 3)?(this.rifiuti[0].position + 1):3;

    // posizione rifiuto corrente
    this.currentRifiutoPosition = this.rifiuti[0].position;

    // aggiungo animazione
    if(animationName) {
      this.renderer.addClass(rifiutoDiv, animationName);
    }

    await this.delay(300);
    
    this.blockSwipe = false;
  }

  // funzione per spingere verso il basso il rifiuto
  mandaGiu() {

    // blocco lo swipe
    this.blockSwipe = true;

    // recupero il div nel gioco tramite id
    const rifiutoDiv = document.getElementById("id"+this.rifiuti[0].id);
    
    var classVelocita = 'spostamento_giu';
    if(rifiutoDiv?.classList.contains("spostamento_sx_2")) {
      classVelocita = 'spostamento_giu_sx_2';
    } else if(rifiutoDiv?.classList.contains("spostamento_dx_2")) {
      classVelocita = 'spostamento_giu_dx_2';
    } else if(rifiutoDiv?.classList.contains("spostamento_sx")) {
      classVelocita = 'spostamento_giu_sx';
    } else if(rifiutoDiv?.classList.contains("spostamento_dx")) {
      classVelocita = 'spostamento_giu_dx';
    } 
    // aggiungo classe
    if(classVelocita) {
      // aumento velocità
      this.renderer.addClass(rifiutoDiv, classVelocita);
    }
  }

  // check su collisioni con oggetti
  async detectPosition() {

    // sleep
    await this.delay(10);

    // recupero i div nel gioco tramite id
    const parent = document.getElementById("contenitore_gioco");
    const bidoneDiv = document.getElementById("bidone_1");
    var bidone = bidoneDiv?.getBoundingClientRect();
    
    // recupero il div dell'oggetto
    const firstChild = document.getElementById("child"+this.rifiuti[0].id);
    var pos = firstChild?.getBoundingClientRect();

    if(!firstChild && this.gameActive) {
      // ricorsione funzione
      this.detectPosition();
    }

    const parentToRemove = document.getElementById("id"+this.rifiuti[0].id);

    // recupero width dispositivo
    var screenWidth = window.innerWidth;

    // recupero dimensioni oggetto
    var dimensioneOggetto = firstChild?firstChild?.offsetWidth:0;
    var heightBidone = bidoneDiv?bidoneDiv?.offsetHeight:0;
    
    // controllo se non sono stato colpito
    if(pos) {
      
      // altezza oggetto
      var altezzaOggetto = Math.ceil(pos?.y);

      if(altezzaOggetto <= ((bidone?.top?bidone.top:0) - heightBidone + 80) && altezzaOggetto >= ((bidone?.top?bidone.top:0)) - heightBidone + 0) {
        
        this.blockSwipe = true;
      }
      
      // controllo se c'è collisione
      if(altezzaOggetto <= ((bidone?.top?bidone.top:0) - heightBidone + 80) && altezzaOggetto >= ((bidone?.top?bidone.top:0)) - heightBidone + 20) {
        
        // controllo se rifiuto è nella corsia giusta
        if(((pos.x + dimensioneOggetto/2) <= screenWidth/3 && this.rifiuti[0].bidone == 1) || ((pos.x + dimensioneOggetto/2) > screenWidth/3 && (pos.x + dimensioneOggetto/2) < (screenWidth*2)/3 && this.rifiuti[0].bidone == 2) || ((pos.x + dimensioneOggetto/2) >= (screenWidth*2)/3 && this.rifiuti[0].bidone == 3)) {
          
          // aumento punteggio
          this.increaseScore();
        } else {
          
          // errore
          this.showErrorFunction();
        }
        
        // rimuovo oggetto
        this.renderer.removeChild(parent, parentToRemove);

        // cerco indice oggetto da rimuovere
        var idToRemove = this.rifiuti.findIndex(search => search.id == (this.rifiuti[0].id));
        
        // tolgo oggetto da array oggetti
        this.rifiuti.splice(idToRemove,1);

        // se gioco ancora in corso
        if(this.gameActive) {

          // inserisco nuovo rifiuto
          this.createRifiuto();
        }
      }
    }
    
    // se gioco ancora in corso
    if(this.gameActive) {

      // ricorsione funzione
      this.detectPosition();
    }
  }

  // distruggo elemento non preso
  async destroyLastElement() {
    
    // sleep
    await this.delay(1000);
    
    // se trovo aleno un oggetto
    if(this.rifiuti.length) {
      
      // scorro tutti gli oggetti presenti
      this.rifiuti.forEach(element => {

        // recupero i div nel gioco tramite id
        const parent = document.getElementById("contenitore_gioco");
        const bidoneDiv = document.getElementById("bidone_1");
        var bidone = bidoneDiv?.getBoundingClientRect();
        
        // recupero il div dell'oggetto
        const firstChild = document.getElementById("child"+this.rifiuti[0].id);
        
        // ricavo posizioni oggetto e squalo
        var pos = firstChild?.getBoundingClientRect();

        // se è arrivato a fine schermo
        if(pos && Math.ceil(pos?.y) >= ((bidone?.top?bidone.bottom:0) - 40)) {
          
          // rimuovo oggetto
          this.renderer.removeChild(parent, firstChild);

          // cerco indice oggetto da rimuovere
          var idToRemove = this.rifiuti.findIndex(search => search.id == (element.id));
          
          // tolgo oggetto da array oggetti
          this.rifiuti.splice(idToRemove,1);

          // se gioco ancora in corso
          if(this.gameActive) {

            // inserisco nuovo rifiuto
            this.createRifiuto();
          }
        }
      });
    }

    // se gioco ancora in corso
    if(this.gameActive) {
      // ricorsione funzione
      this.destroyLastElement();
    }
  }
  
  // funzione per aumentare punteggio
  async increaseScore() {
    
    // aumento punteggio
    this.score ++;
    
    // se raggiungo obiettivo
    if (this.score == this.scoreObiettivo) {

      // gioco non più in corso
      this.gameActive = false;

      // chiudo tutti i bidoni
      this.currentRifiutoPosition = 0;

      if (this.playId != null && this.playId != "0") {

        await this.setRewardPlayed();

      } else {

        // mostro subito popup fine gioco
        this.showPopupEnd = true;
      }

    } else {
          
      // success
      this.showSuccessFunction();
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // funzione per popup chiudi il gioco
  async clickClose() {

    this.gameActive = false;

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm("Sicuro di voler uscire?", "CONFERMO", "ANNULLA");

    // se utente conferma
    if(response) {
      // ritorno home page
      this.router.navigate(['/home']);
    } else {
      // riattivo gioco
      this.gameActive = true;
      // check delle collisioni
      this.detectPosition();
      // funzione garbage collector oggetti
      this.destroyLastElement();
    }
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {

    this.loading.present();

    try {

      const data = await lastValueFrom(this.appService.gamePlayed(this.playId, true));

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupEnd = true;
      
    } catch (error) {

      this.loading.dismiss();
      
      const res = await this.alert.presentConfirm("Controlla la tua connessione Internet", "RIPROVA", "ANNULLA");

      if (res) {

        this.setRewardPlayed();

      } else {

        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  async showErrorFunction() {

    this.showError = true;

    await this.delay(800);

    this.showError = false;
  }

  async showSuccessFunction() {

    // numero casuale tra 1 e 4
    var min = 1;
    var max = 5;
    var lastMessage = this.successMessage;
    while (this.successMessage == lastMessage) {
      this.successMessage = Math.floor(Math.random() * (max - min) + min);
    }

    this.showSuccess = true;

    await this.delay(800);

    this.showSuccess = false;
  }
}
