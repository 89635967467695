import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { InitPageComponent } from './pages/init-page/init-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LoadingModule } from "./loading/loading.module";
import { HeaderModule } from "./header/header.module";
import { FooterModule } from './footer/footer.module';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { GiocoSqualoPageComponent } from './pages/mini-giochi/gioco-squalo-page/gioco-squalo-page.component';
import { VideoAutoplayDirective } from './directives/video-autoplay.directive';
import { OnBoardingComponent } from './pages/on-boarding/on-boarding.component';
import { AccessibilitaComponent } from './pages/accessibilita/accessibilita.component';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { ScegliAvatarComponent } from './pages/scegli-avatar/scegli-avatar.component';
import { ElencoMinigiochiComponent } from './pages/elenco-minigiochi/elenco-minigiochi.component';
import { AiutoFaqComponent } from './pages/aiuto-faq/aiuto-faq.component';
import { LeTueGiocateComponent } from './pages/le-tue-giocate/le-tue-giocate.component';
import { FineGiocoComponent } from './pages/fine-gioco/fine-gioco.component';
import { IntroNuovoMondoComponent } from './pages/intro-nuovo-mondo/intro-nuovo-mondo.component';
import { GiocoQuizComponent } from './pages/mini-giochi/gioco-quiz/gioco-quiz.component';
import { GiocoSpegniLoSprecoComponent } from './pages/mini-giochi/gioco-spegni-lo-spreco/gioco-spegni-lo-spreco.component';
import { GiocoRaccoltaDifferenziataComponent } from './pages/mini-giochi/gioco-raccolta-differenziata/gioco-raccolta-differenziata.component';
import { AngularGestureConfig } from './angular-gesture-config';
import { TokenInterceptor } from './services/token-interceptor';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { RouterModule } from '@angular/router';
import { AlertModule } from './alert/alert.module';
import { ContenutoEditorialeComponent } from './pages/contenuto-editoriale/contenuto-editoriale.component';
import { VideoFineLivelloComponent } from './pages/video-fine-livello/video-fine-livello.component';
import { ElencoEditorialiComponent } from './pages/elenco-editoriali/elenco-editoriali.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TutorialExternalComponent } from './pages/tutorial-external/tutorial-external.component';
import { PuntiSostenibilitaComponent } from './pages/punti-sostenibilita/punti-sostenibilita.component';
import { ExitPageComponent } from './pages/exit-page/exit-page.component';
import { CouponPreviewComponent } from './pages/coupon-preview/coupon-preview.component';
import { GiocoMemoryComponent } from './pages/mini-giochi/gioco-memory/gioco-memory.component';
import { GiocoCliccaProdottiComponent } from './pages/mini-giochi/gioco-clicca-prodotti/gioco-clicca-prodotti.component';
import { GiocoBrickBreakerComponent } from './pages/mini-giochi/gioco-brick-breaker/gioco-brick-breaker.component';

@NgModule({
    declarations: [
        AppComponent,
        InitPageComponent,
        NotFoundPageComponent,
        HomePageComponent,
        GiocoSqualoPageComponent,
        VideoAutoplayDirective,
        OnBoardingComponent,
        AccessibilitaComponent,
        TutorialComponent,
        TutorialExternalComponent,
        ScegliAvatarComponent,
        ElencoMinigiochiComponent,
        AiutoFaqComponent,
        LeTueGiocateComponent,
        FineGiocoComponent,
        IntroNuovoMondoComponent,
        GiocoQuizComponent,
        GiocoSpegniLoSprecoComponent,
        GiocoRaccoltaDifferenziataComponent,
        ContenutoEditorialeComponent,
        VideoFineLivelloComponent,
        ElencoEditorialiComponent,
        SafeHtmlPipe,
        PuntiSostenibilitaComponent,
        ExitPageComponent,
        CouponPreviewComponent,
        GiocoMemoryComponent,
        GiocoCliccaProdottiComponent,
        GiocoBrickBreakerComponent
    ],
    providers: [
        { provide: HAMMER_GESTURE_CONFIG, useClass: AngularGestureConfig },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        LoadingModule,
        AlertModule,
        HeaderModule,
        FooterModule,
        HammerModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule,
        NgxUsefulSwiperModule
    ]
})
export class AppModule { }
