<app-header [showHeader]="true" [showBack]="true"></app-header>

<!-- inizio faq -->
<div class="gioco_faq">

    <div class="pop_default">

        <div class="contenuto_principare_pop">

            <div class="pop_default_titolo">Hai bisogno di aiuto?</div>
            
            <div class="pop_default_descrizione">Trova le risposte tra le nostre FAQ</div>
            
            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(0)" [ngClass]="{'singola_faq_aperta': showAnswers[0]}">
                
                <div class="singola_faq_titolo">
                    Come si gioca? Dove posso trovare più informazioni sulla modalità di gioco?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    Per partecipare al gioco è necessario avere l'App Conad e avere associato la propria Carta Insieme all'account digitale.<br>
                    Dopodichè è necessario iscriversi tramite apposita voce “Iscriviti al gioco” presente nella sezione “Il gioco” della pagina "Le Sfide di Conad", dove sarà necessario prendere visione dell'Informativa Privacy e dei Termini e Condizioni del gioco.<br>
                    A questo punto, tutte le spese effettuate (da quel momento in poi) verranno convertite in tiri di dado disponibili.<br><br>
                    Ti informiamo inoltre che in App, nella sezione le "Le regole" della pagina "le Sfide di Conad", è possibile consultare il regolamento dettagliato dell'iniziativa, cliccando sul link "Termini e condizioni", posizionato in fondo alla pagina.<br>
                    Inoltre, tutte le schermate della sezione dedicata al gioco in App è presente in alto a destra un punto interrogativo: cliccando, accedi a un tutorial che ti spiegherà passo dopo passo il funzionamento del gioco.
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->
            
            
            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(1)" [ngClass]="{'singola_faq_aperta': showAnswers[1]}">
                
                <div class="singola_faq_titolo">
                    Perché ho effettuato una spesa maggiore di 5€ ma non visualizzo il tiro di dado disponibile in App?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    Potresti non visualizzare ancora il tiro di dado (ovvero la tua giocata) disponibile poiché ci sono dei tempi tecnici per l’accredito.<br />
                    Se hai effettuato la spesa in negozio dovresti visualizzare le giocate entro 30 minuti, salvo problemi tecnici. Trascorse 24 ore dalla spesa, se non vedi ancora nessun tiro di dado disponibile, puoi aprire una segnalazione attraverso il modulo di assistenza "Segnalazioni Sito e servizi in App" raggiungibile dalla sezione “Segnalazioni e suggerimenti” della pagina “Contattaci” dell’App, allegando la foto dello scontrino.<br />
                    Se invece hai effettuato la spesa online, dovresti visualizzare le giocate dopo la consegna della spesa a domicilio (con emissione del relativo scontrino), salvo problemi tecnici. In caso di difficoltà, puoi contattare l’Assistenza come sopra indicato.<br /><br />
                    <strong>Attenzione che le spese effettuate precedentemente il primo accesso al gioco, in cui hai accettato i termini e condizioni, non saranno valide. </strong><br /><br />
                    Hai solo una giocata al giorno, a prescindere del numero di spese.<br />
                    Ricordati che sono escluse dal conteggio dei 5€ alcune categorie merceologiche, come previsto dalla normativa vigente (ad esempio carburanti, carte prepagate Conad, libri, libri di testo, carte regalo prepagate, etc.). Per dettagli consultare Termini e Condizioni del gioco.
                    <br /><br />
                </div>
                
            </div>
            <!-- fine singola faq -->
            
            
            
            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(2)" [ngClass]="{'singola_faq_aperta': showAnswers[2]}">
                
                <div class="singola_faq_titolo">
                    I tiri di dado hanno una scadenza?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    I tiri di dado (giocate disponibili) si azzerano al termine di ogni edizione. All’inizio dell’edizione successiva avrai la possibilità di ottenere nuove giocate facendo la spesa, ma perderai quelle guadagnate precedentemente se non le hai utilizzate.<br><br>
                    Al contrario, le Margherite verdi non si azzerano ma si accumulano nel corso delle tre edizioni del gioco per permetterti di partecipare all’estrazione finale prevista entro il <strong>15 Dicembre 2023</strong>.
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->
            
            
            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(3)" [ngClass]="{'singola_faq_aperta': showAnswers[3]}">
                
                <div class="singola_faq_titolo">
                    A cosa servono le Margherite verdi e come le ottengo?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    Le Margherite verdi rappresentano i titoli di partecipazione al concorso finale <strong>Premia Te e l'Ambiente 2023</strong>: ogni Margherita rappresenta una probabilità di estrazione.<br>
                    Più Margherite accumuli, più probabilità avrai di vincere all'estrazione prevista entro il <strong>15 Dicembre</strong>. Puoi ottenere le Margherite verdi in quattro modi distinti:<br>
                    - rispondendo ai quiz con esito positivo<br>
                    - superando i minigiochi<br>
                    - portando a termine un livello dell’edizione di gioco<br>
                    - rispondendo positivamente al quiz previsto al termine di ogni livello
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->


            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(4)" [ngClass]="{'singola_faq_aperta': showAnswers[4]}">
                
                <div class="singola_faq_titolo">
                    Dove ritrovo i buoni vinti nel gioco in App?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    I buoni ottenuti attraverso il gioco li troverai all'interno della sezione Offerte e Buoni dell'app.<br>
                    Potresti non visualizzare immediatamente il tuo buono perché ci sono dei tempi tecnici per l’accredito del coupon: il coupon ottenuto sarà visibile entro 24 ore dal conseguimento dello stesso!
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->


            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(5)" [ngClass]="{'singola_faq_aperta': showAnswers[5]}">
                
                <div class="singola_faq_titolo">
                    Per quanto sono validi i buoni che ho vinto giocando?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    La validità dei coupon è specificata all'interno della card e della pagina di dettaglio dei coupon stessi.
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->


            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(6)" [ngClass]="{'singola_faq_aperta': showAnswers[6]}">
                
                <div class="singola_faq_titolo">
                    Come posso utilizzare i buoni che ho vinto?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    I buoni vinti partecipando al gioco saranno visualizzabili in formato digitale in App e sul Sito e saranno spendibili nei punti vendita fisici.<br>
                    Non saranno spendibili sul portale https://spesaonline.conad.it/home
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->


            <!-- inizio singola faq -->
            <div class="singola_faq" (click)="openAnswer(7)" [ngClass]="{'singola_faq_aperta': showAnswers[7]}">
                
                <div class="singola_faq_titolo">
                    Non hai trovato quello che stai cercando?
                    <svg viewBox="0 0 72 72">
                        <path d="M36,56c-1.2,0-2.5-0.5-3.4-1.4L1.5,24.4c-1.9-1.9-2-5-0.1-6.9c1.9-1.9,5-2,6.9-0.1L36,44.3l27.7-26.9c1.9-1.9,5-1.8,6.9,0.1c1.9,1.9,1.8,5-0.1,6.9L39.4,54.6C38.5,55.6,37.2,56,36,56z"/>
                    </svg>
                </div>
                
                <div class="singola_faq_descrizione">
                    Puoi aprire una segnalazione attraverso la sezione "Contattaci" dell'App Conad: clicca sulla voce "Segnalazione e suggerimenti" e seleziona l'opzione  "Segnalazioni Sito e servizi in App" per chiedere assistenza.
                    <br><br>
                </div>
                
            </div>
            <!-- fine singola faq -->
            
            
            <!-- inizio numero verde -->
            <!--
            <div class="numero_verde">
                <h3>Chiamaci</h3>
                <p>Se non hai trovato risposta consultando le nostre FAQ chiamaci al numero verde:</p>
                <a href="">800-80-80-80</a>
            </div>
            -->
            <!-- fine numero verde -->
            
        </div>

    </div>
    
</div>
<!-- fine faq -->