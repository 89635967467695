import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/alert/alert.service';
import { BRAND_LOGO_BARILLA } from 'src/app/interfaces/brand';
import { TypeMiniGame } from 'src/app/interfaces/game';
import { ElementMemory, KEY_TUTORIAL_MEMORY } from 'src/app/interfaces/memory';
import { Minipillola, getRandomMinipillola } from 'src/app/interfaces/minipillole';
import { LoadingService } from 'src/app/loading/loading.service';
import { AppService } from 'src/app/services/app.service';
import { LocalService } from 'src/app/services/local.service';

@Component({
  selector: 'app-gioco-memory',
  templateUrl: './gioco-memory.component.html',
  styleUrls: ['./gioco-memory.component.css']
})
export class GiocoMemoryComponent implements OnInit {

  playId: string = '0'; // ID giocata
  brandLogoUrl: string = '';
  arrayAnimali: Array<ElementMemory> = []
  compare: Array<ElementMemory> = [];
  gameActive: boolean = true;
  score: number = 0;
  scoreObiettivo: number = 8;
  minipillola: Minipillola | null = null;
  showPopupConclusione: boolean = false;

  // flag per popup tutorial
  popupTutorial: boolean = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public appService: AppService,
    private alert: AlertService,
    private loading: LoadingService,
    private local: LocalService,
  ) { }

  ngOnInit(): void {

    this.playId = this.route.snapshot.paramMap.get('playId')!;

    console.log('playId: ' + this.playId);
    
    // logo brand
    if (this.appService.authUser?.info.worldId == 1) {
      this.brandLogoUrl = BRAND_LOGO_BARILLA;
    }else {

      // minipillola
      if (this.appService.authUser?.info.worldId && this.playId != "0") {

        this.minipillola = getRandomMinipillola(this.appService.authUser.info.worldId, TypeMiniGame.MEMORY);

        if (this.minipillola) {

          this.brandLogoUrl = this.minipillola.logo;
        }
      }
    }

    var tutorialAlreadyShown = this.local.getData(KEY_TUTORIAL_MEMORY);

    if(tutorialAlreadyShown == '1' && this.playId != "0") {
      this.popupTutorial = false;

      this.startGame();
    }
  }

  shuffle(array: Array<any>) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  async startGame() {

    this.arrayAnimali = [
      {
        "image": "/assets/img/gioco_memory/prodotto_1.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_2.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_3.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_4.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_5.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_6.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_7.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_8.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_1.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_2.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_3.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_4.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_5.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_6.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_7.jpg",
        "clicked": false,
        "completed": false,
      },
      {
        "image": "/assets/img/gioco_memory/prodotto_8.jpg",
        "clicked": false,
        "completed": false,
      },
    ];

    this.score = 0;
    
    this.arrayAnimali = this.shuffle(this.arrayAnimali);

    /*this.gameActive = false;

    this.arrayAnimali.forEach(element => {
      element.clicked = true;
    });

    await this.delay(2000);

    this.arrayAnimali.forEach(element => {
      element.clicked = false;
    });

    this.gameActive = true;*/
  }

  async clickOnBox(index: number) {
    
    if(this.arrayAnimali[index].clicked || !this.gameActive) {
      return;
    }

    this.compare.push(this.arrayAnimali[index]);
    this.arrayAnimali[index].clicked = true;
    
    if(this.compare.length == 2) {
      if(this.compare[0].image == this.compare[1].image) {
        var completed = this.arrayAnimali.filter(element => element.image == this.compare[0].image && element.clicked == true);

        completed[0].completed = true;
        completed[1].completed = true;

        this.increaseScore();
      } else {
        var toReset1 = this.arrayAnimali.filter(element => element.image == this.compare[0].image);
        var toReset2 = this.arrayAnimali.filter(element => element.image == this.compare[1].image);

        await this.delay(1000);

        toReset1[0].clicked = false;
        toReset1[1].clicked = false;
        toReset2[0].clicked = false;
        toReset2[1].clicked = false;
      }

      this.compare = [];
    }
  }

  // funzione di sleep
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  // funzione per aumentare punteggio
  async increaseScore() {
    // aumento punteggio
    this.score++;
    
    // se raggiungo obiettivo
    if (this.score == this.scoreObiettivo) {
      // gioco non più in corso
      this.gameActive = false;

      if (this.playId != null && this.playId != '0') {
        await this.setRewardPlayed();
      } else {
        // mostro subito popup fine gioco
        this.showPopupConclusione = true;
      }
    }
  }

  /**
   * Setta la fine delle giocata e restituisce il prmeio vinto
   */
  async setRewardPlayed() {
    this.loading.present();

    try {
      const data = await lastValueFrom(
        this.appService.gamePlayed(this.playId, true)
      );

      this.loading.dismiss();

      // mostro popup fine gioco
      this.showPopupConclusione = true;
    } catch (error) {
      this.loading.dismiss();

      const res = await this.alert.presentConfirm(
        'Controlla la tua connessione Internet',
        'RIPROVA',
        'ANNULLA'
      );

      if (res) {
        this.setRewardPlayed();
      } else {
        // ritorno home page
        this.router.navigate(['/home']);
      }
    }
  }

  // funzione per popup chiudi il gioco
  async clickClose() {
    // disattivo momentaneamente il gioco
    this.gameActive = false;

    // visualizzo popup per uscita dal gioco
    const response = await this.alert.presentConfirm(
      'Sicuro di voler uscire?',
      'CONFERMO',
      'ANNULLA'
    );

    // se utente conferma
    if (response) {
      // ritorno home page
      this.router.navigate(['/home']);
    } else {
      // riattivo gioco
      this.gameActive = true;
    }
  }

  // funzione per chiudere tutorial
  closeTutorial() {
    // nascondo popup
    this.popupTutorial = false;

    this.local.saveData(KEY_TUTORIAL_MEMORY, '1');

    this.startGame();
  }
}
